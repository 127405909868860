export const en = {
  "page.map.button.mapStyle.map": "Map",
  "page.map.button.mapStyle.satellite": "Satellite",
  "page.map.notification.message.karlDoesNotWorkInThisAreaYet": "Automatic field boundaries are not supported in this area yet. Please draw it manually.",
  "page.map.notification.title.fieldShouldAppearInAMoment": "Field has been added.",
  "page.map.notification.message.fieldShouldAppearInAMoment": "It should appear on the map in a few seconds.",
  "page.map.fieldLoadingStatus.text": "Processing field data...",
  "page.map.overlay.button.clear": "Clear",
  "page.map.overlay.button.undo": "Undo",
  "page.map.overlay.button.continue": "Continue",
  "page.map.overlay.button.automatic.text": "Automatic",
  "page.map.overlay.button.automatic.tooltip": "In some areas, KARL can automatically outline field boundaries. Simply click on a field and see the outline. If the outline is incorrect, or if the area is not supported, you can draw it manually.",
  "page.map.overlay.button.manual.text": "Manual",
  "page.map.overlay.button.manual.tooltip": "Draw your field boundaries by clicking on the map until the field is correctly highlighted, then select the continue button at the bottom of the screen.",

  "page.users.heading": "Users",
  "page.users.iconButton.addUser.ariaLabel": "Add user",
  "page.users.userId": "User ID",
  "page.users.phone": "Phone",
  "page.users.email": "Email",
  "page.users.emailVerified": "Email verified",
  "page.users.emailVerified.yes": "Yes",
  "page.users.emailVerified.no": "No",
  "page.users.employment": "Employment",
  "page.users.employment.external": "External",
  "page.users.employment.internal": "Internal",
  "page.users.addUserDrawer.heading": "Add user",
  "page.users.addUserDrawer.input.firstName": "First name",
  "page.users.addUserDrawer.input.lastName": "Last name",
  "page.users.addUserDrawer.input.email": "Email",
  "page.users.addUserDrawer.checkbox.userCategory.label": "Employment",
  "page.users.addUserDrawer.checkbox.userCategory.text": "External",
  "page.users.addUserDrawer.input.phone": "Phone",
  "page.users.addUserDrawer.button.submit": "Add user",

  "page.machines.heading": "Machines & Equipment",
  "page.machines.iconButton.addMachine.ariaLabel": "Add machine or equipment",
  "page.machines.machineId": "Machine ID",
  "page.machines.brand": "Brand",
  "page.machines.model": "Model",
  "page.machines.yearProduced": "Year produced",
  "page.machines.description": "Description",
  "page.machines.category": "Category",
  "page.machines.type": "Type",
  "page.machines.type.machine": "Machine",
  "page.machines.type.equipment": "Equipment",
  "page.machines.addMachineDrawer.heading": "Add machine or equipment",
  "page.machines.addMachineDrawer.input.brand": "Brand",
  "page.machines.addMachineDrawer.input.model": "Model",
  "page.machines.addMachineDrawer.input.yearProduced": "Year produced",
  "page.machines.addMachineDrawer.input.description": "Description",
  "page.machines.addMachineDrawer.select.type": "Type",
  "page.machines.addMachineDrawer.select.type.machine": "Machine",
  "page.machines.addMachineDrawer.select.type.equipment": "Equipment",
  "page.machines.addMachineDrawer.input.category": "Category",
  "page.machines.addMachineDrawer.button.submit": "Add",

  "page.profile.heading.generalInformation": "General information",
  "page.profile.heading.settings": "Settings",
  "page.profile.heading.legal": "Legal",
  "page.profile.heading.feedback": "Feedback",

  "page.profile.legal.termsAndConditions": "Terms and conditions",
  "page.profile.legal.privacyPolicy": "Privacy policy",

  "page.profile.feedback.description": "We are always trying to improve, and we would love to hear your feedback.",
  "page.profile.feedback.button.submit": "Give feedback",

  "page.profile.enterpriseId": "Enterprise ID",
  "page.profile.userId": "User ID",
  "page.profile.username": "Username",
  "page.profile.userEmail": "User email",
  "page.profile.language": "Language",
  "page.profile.label.systemOfMeasurement": "System of measurement",
  "page.profile.systemOfMeasurement.metric": "Metric",
  "page.profile.systemOfMeasurement.imperial": "Imperial",
  "page.profile.label.fieldColoring": "Field coloring",
  "page.profile.fieldColoring.simple": "Simple",
  "page.profile.fieldColoring.detailed": "Detailed",
  "page.profile.logOut": "Log out",

  "page.calendar.week": "Week",
  "page.calendar.today": "Today",
  "page.calendar.days.1": "1 day",
  "page.calendar.days.3": "3 days",
  "page.calendar.days.7": "1 week",
  "page.calendar.iconButton.addTask.ariaLabel": "Add task",
  "page.calendar.button.filters": "Filters",
  "page.calendar.filters.label.fieldGroups": "Field groups",
  "page.calendar.filters.placeholder.fieldGroups": "Filter by field groups",
  "page.calendar.filters.label.fields": "Fields",
  "page.calendar.filters.label.tasks": "Tasks",
  "page.calendar.filters.label.users": "Users",
  "page.calendar.filters.label.machines": "Machines and equipment",
  "page.calendar.filters.button.clearFilters": "Clear filters",
  "page.calendar.showFieldConditions": "Show field conditions",
  "page.calendar.colorTasksPerAssociatedUsers.text": "Color tasks according to associated users",
  "page.calendar.startCalendarOnSelectedDate.text": "Start calendar on selected date",

  "page.index.heading": "Welcome to KARL!",
  "page.index.mobileWarning.Heading": "Oops, KARL is not yet supported on mobile!",
  "page.index.mobileWarning.Paragraph": "Until then, you can enjoy KARL on your computer or tablet. Sign up to our newsletter to get notified when our mobile app is ready 🚀",
  "page.index.login": "Get started",

  "page.createEnterprise.heading": "Create enterprise",
  "page.createEnterprise.description": "Please provide the following details to get started.",
  "page.createEnterprise.personalFirstNameInput.label": "Your first name",
  "page.createEnterprise.personalLastNameInput.label": "Your last name",
  "page.createEnterprise.companyNameInput.label": "Company name",
  "page.createEnterprise.cvrInput.label": "CVR",
  "page.createEnterprise.buttonText": "Go!",

  "component.AddTaskDrawer.heading": "Add task",
  "component.AddTaskDrawer.buttons.cancel": "Cancel",
  "component.AddTaskDrawer.buttons.add": "Add",

  "component.ConditionBadge.conditionLevel.optimal": "Optimal",
  "component.ConditionBadge.conditionLevel.good": "Good",
  "component.ConditionBadge.conditionLevel.moderate": "Moderate",
  "component.ConditionBadge.conditionLevel.poor": "Poor",
  "component.ConditionBadge.conditionLevel.critical": "Critical",

  "component.ConditionBadge.typeLabel.precipitation": "Precipitation",
  "component.ConditionBadge.typeLabel.temperature": "Temperature",
  "component.ConditionBadge.typeLabel.soilTemperature": "Soil temperature",
  "component.ConditionBadge.typeLabel.soilMoisture": "Soil moisture",
  "component.ConditionBadge.typeLabel.clouds": "Clouds",
  "component.ConditionBadge.typeLabel.wind": "Wind",
  "component.ConditionBadge.typeLabel.gusts": "Gusts",

  "component.EditTaskDrawer.heading": "Details",
  "component.EditTaskDrawer.tabEditHeading": "Edit",
  "component.EditTaskDrawer.conditionsNotSupportedForThisTaskType": "Conditions are not supported for this task type.",
  "component.EditTaskDrawer.tabConditionsHeading": "Field conditions",
  "component.EditTaskDrawer.buttons.delete": "Delete",
  "component.EditTaskDrawer.buttons.cancel": "Cancel",
  "component.EditTaskDrawer.buttons.apply": "Apply",

  "component.AddFieldDrawerContent.heading.addField": "Add field",
  "component.AddFieldDrawerContent.inputLabel.fieldName": "Field name",
  "component.AddFieldDrawerContent.inputLabel.secondaryName": "Secondary name",
  "component.AddFieldDrawerContent.inputLabel.fieldGroup": "Group",
  "component.AddFieldDrawerContent.label.boundary": "Boundary",
  "component.AddFieldDrawerContent.label.noBoundaryDrawn": "No boundary drawn",
  "component.AddFieldDrawerContent.button.drawFieldManually": "Draw field manually",
  "component.AddFieldDrawerContent.button.finish": "Finish",
  "component.AddFieldDrawerContent.heading.automaticallyDrawnFieldBoundaries": "Automatically drawn field boundaries",
  "component.AddFieldDrawerContent.content.automaticallyDrawnFieldBoyndaries": "You can simply click on your field on the map to the right to have its boundary automatically drawn.",
  "component.AddFieldDrawerContent.heading.manuallyDrawnFieldBoundaries": "Manually drawn field boundaries",
  "component.AddFieldDrawerContent.content.manuallyDrawnFieldBoundaries": "If you find the automatically drawn boundaries inaccurate, or not available in your area, you can draw the boundary of your field manually. To do so, click on the button above and then click on the map to draw the boundary of your field. You can add as many points as you want. To finish drawing, click on the button again.",
  "component.AddFieldDrawerContent.button.cancel": "Cancel",
  "component.AddFieldDrawerContent.button.add": "Add",

  "component.FieldsDrawerContent.heading": "Fields",
  "component.FieldsDrawerContent.filterByFieldGroupPlaceholder": "Filter by group",
  "component.FieldsDrawerContent.areaUnit": "ha",
  "component.FieldsDrawerContent.areaUnit__imperial": "acres",
  "component.FieldsDrawerContent.button.addField": "Add field",
  "component.FieldsDrawerContent.button.cancel": "Cancel",
  "component.FieldsDrawerContent.toggleIrrigationColoring": "Toggle irrigation coloring",
  "component.FieldsDrawerContent.toggleFieldColoring": "Toggle field coloring",

  "component.FieldDrawerContent.heading": "Field",
  "component.FieldDrawerContent.tab.weather": "Weather",
  "component.FieldDrawerContent.tab.tasks": "Tasks",
  "component.FieldDrawerContent.tab.info": "Info",
  "component.FieldDrawerContent.tab.edit": "Edit",

  "component.FieldDrawerWeatherTabContent.chartName.precipitation": "Precipitation",
  "component.FieldDrawerWeatherTabContent.chartName.airTemperature": "Air Temperature",
  "component.FieldDrawerWeatherTabContent.chartName.soilMoisture": "Soil Moisture",
  "component.FieldDrawerWeatherTabContent.chartName.soilTemperature": "Soil Temperature",
  "component.FieldDrawerWeatherTabContent.chartName.windGusts": "Wind & Gusts",
  "component.FieldDrawerWeatherTabContent.chartName.cloudCoverage": "Cloud Coverage",
  "component.FieldDrawerWeatherTabContent.chartName.humidity": "Humidity",
  "component.FieldDrawerWeatherTabContent.series.p1h": "Precipitation (mm/hour)",
  "component.FieldDrawerWeatherTabContent.series.at": "Air Temperature (°C)",
  "component.FieldDrawerWeatherTabContent.series.sm0_5": "Soil Moisture 0-5cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm5_15": "Soil Moisture 5-15cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm15_30": "Soil Moisture 15-30cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm30_60": "Soil Moisture 30-60cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm60_100": "Soil Moisture 60-100cm (%)",
  "component.FieldDrawerWeatherTabContent.series.st0": "Soil Temperature 0cm (°C)",
  "component.FieldDrawerWeatherTabContent.series.st10": "Soil Temperature 10cm (°C)",
  "component.FieldDrawerWeatherTabContent.series.gs": "Gusts (m/s)",
  "component.FieldDrawerWeatherTabContent.series.ws": "Wind (m/s)",
  "component.FieldDrawerWeatherTabContent.series.p1h__imperial": "Precipitation (in/hour)",
  "component.FieldDrawerWeatherTabContent.series.at__imperial": "Air Temperature (°F)",
  "component.FieldDrawerWeatherTabContent.series.sm0_5__imperial": 'Soil Moisture 0-1.97" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm5_15__imperial": 'Soil Moisture 1.97-5.91" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm15_30__imperial": 'Soil Moisture 5.91-11.81" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm30_60__imperial": 'Soil Moisture 11.81-23.62" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm60_100__imperial": 'Soil Moisture 23.62-39.37" (%)',
  "component.FieldDrawerWeatherTabContent.series.st0__imperial": 'Soil Temperature 0" (°F)',
  "component.FieldDrawerWeatherTabContent.series.st10__imperial": 'Soil Temperature 3.94" (°F)',
  "component.FieldDrawerWeatherTabContent.series.gs__imperial": "Gusts (mph)",
  "component.FieldDrawerWeatherTabContent.series.ws__imperial": "Wind (mph)",
  "component.FieldDrawerWeatherTabContent.series.c": "Cloud Coverage (%)",
  "component.FieldDrawerWeatherTabContent.series.h": "Humidity (%)",

  "component.FieldDrawerTasksTabContent.hideCompletedTasks": "Hide completed tasks",
  "component.FieldDrawerTasksTabContent.noTasksFound": "No tasks found for this field.",

  "component.FieldDrawerInfoTabContent.label.cropType": "Crop type",
  "component.FieldDrawerInfoTabContent.label.expectedSowingDate": "Expected sowing date",
  "component.FieldDrawerInfoTabContent.label.expectedHarvestDate": "Expected harvest date",
  "component.FieldDrawerInfoTabContent.label.areaHa": "Area (ha)",
  "component.FieldDrawerInfoTabContent.label.areaHa__imperial": "Area (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.Marknr": "Field number",
  "component.FieldDrawerInfoTabContent.customProperty.Afgroede": "Crop",
  "component.FieldDrawerInfoTabContent.customProperty.Markblok": "Field block",
  "component.FieldDrawerInfoTabContent.customProperty.GBanmeldt": "GB",
  "component.FieldDrawerInfoTabContent.customProperty.Afgkode": "Crop code",
  "component.FieldDrawerInfoTabContent.customProperty.GB": "GB",
  "component.FieldDrawerInfoTabContent.customProperty.Journalnr": "Journal number",
  "component.FieldDrawerInfoTabContent.customProperty.IMK_areal": "IMK area",
  "component.FieldDrawerInfoTabContent.customProperty.CVR": "CVR",
  "component.FieldDrawerInfoTabContent.customProperty.area": "Area (m2)",
  "component.FieldDrawerInfoTabContent.customProperty.area_acres": "Area (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.area_ha": "Area (ha)",
  "component.FieldDrawerInfoTabContent.customProperty.area_ha__imperial": "Area (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.id": "ID",
  "component.FieldDrawerInfoTabContent.accordion.soil.heading": "Soil Info",
  "component.FieldDrawerInfoTabContent.accordion.soil.disclaimer.heading": "Soil Info accuracy",
  "component.FieldDrawerInfoTabContent.accordion.soil.disclaimer.label": "Soil Info is an experimental feature and might show inaccurate metrics. The values are estimated averages of the top 30cm soil layer.",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.soilType": "Soil type",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.ph": "pH",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.organicCarbonContent": "Organic Carbon Content",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.nitrogenContent": "Nitrogen Content",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.sand": "Sand",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.silt": "Silt",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.clay": "Clay",
  "component.FieldDrawerInfoTabContent.accordion.soil.heading.ph": "pH",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.heading": "Irrigation",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.button.disableAdvancedIrrigationAnalysis": "Disable advanced irrigation analysis",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.disclaimer.heading": "Advanced Irrigation Analysis",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.disclaimer.label": "Advanced Irrigation Analysis is an experimental feature and might show inaccurate metrics.",
  "component.FieldDrawerInfoTabContent.buttons.delete": "Delete",

  "component.EnableIrrigationAnalysisButton.button.enableAdvancedIrrigationAnalysis": "Enable advanced irrigation analysis",
  "component.EnableIrrigationAnalysisButton.modal.heading": "Are you sure?",
  "component.EnableIrrigationAnalysisButton.modal.description": "Please confirm that you want to enable advanced irrigation analysis.",
  "component.EnableIrrigationAnalysisButton.modal.button.cancel": "Cancel",
  "component.EnableIrrigationAnalysisButton.modal.button.confirm": "Confirm",

  "component.FieldIrrigationDrawerContent.text.irrigationMetricsNotEnabled": "Irrigation metrics are not enabled for this field.",
  "component.FieldIrrigationDrawerContent.text.noDataAvailableForTheChosenTimePeriod": "No data available for the chosen time period.",
  "component.FieldIrrigationDrawerContent.chartName.waterDeficit": "Water Deficit",
  "component.FieldIrrigationDrawerContent.chartName.evapotranspiration": "Evapotranspiration",
  "component.FieldIrrigationDrawerContent.chartName.soilMoisture": "Soil Moisture",
  "component.FieldIrrigationDrawerContent.series.waterDeficit": "Water deficit",
  "component.FieldIrrigationDrawerContent.series.evapotranspiration": "Evapotranspiration",
  "component.FieldIrrigationDrawerContent.series.soilMoisture": "Soil moisture",
  "component.FieldIrrigationDrawerContent.series.saturationPoint": "Saturation point",
  "component.FieldIrrigationDrawerContent.series.fieldCapacity": "Field capacity",
  "component.FieldIrrigationDrawerContent.series.refillPoint": "Refill point",
  "component.FieldIrrigationDrawerContent.series.wiltingPoint": "Wilting point",
  "component.FieldIrrigationDrawerContent.select.options.day": "Day",
  "component.FieldIrrigationDrawerContent.select.options.week": "Week",
  "component.FieldIrrigationDrawerContent.select.options.month": "Month",
  "component.FieldIrrigationDrawerContent.select.options.year": "Year",
  "component.FieldIrrigationDrawerContent.select.options.custom": "Custom",

  "component.Map.evapotranspiration": "Evapotranspiration",
  "component.Map.soilMoisture": "Soil moisture",

  "component.FieldDrawerEditTabContent.label.fieldName": "Field name",
  "component.FieldDrawerEditTabContent.label.secondaryName": "Secondary name",
  "component.FieldDrawerEditTabContent.label.fieldGroup": "Group",
  "component.FieldDrawerEditTabContent.label.cropType": "Crop type",
  "component.FieldDrawerEditTabContent.label.expectedSowingDate": "Expected sowing date",
  "component.FieldDrawerEditTabContent.label.expectedHarvestDate": "Expected harvest date",
  "component.FieldDrawerEditTabContent.buttons.apply": "Apply",
  "component.FieldDrawerEditTabContent.buttons.cancel": "Cancel",

  "component.TaskForm.formLabels.title": "Title",
  "component.TaskForm.formLabels.task": "Task",
  "component.TaskForm.formLabels.crop": "Crop",
  "component.TaskForm.formLabels.tillingDepth": "Tilling depth",
  "component.TaskForm.formLabels.dropSize": "Droplet size",
  "component.TaskForm.formLabels.start": "Start",
  "component.TaskForm.formLabels.duration": "Duration",
  "component.TaskForm.formLabels.fields": "Fields",
  "component.TaskForm.formLabels.users": "Users",
  "component.TaskForm.formLabels.machinesAndEquipment": "Machines and equipment",
  "component.TaskForm.formLabels.notes": "Notes",

  "data.task.harvesting": "Harvesting",
  "data.task.seeding": "Seeding",
  "data.task.tilling": "Tilling",
  "data.task.spraying": "Spraying",
  "data.task.spreading": "Spreading",
  "data.task.mowing": "Mowing",
  "data.task.pressing": "Pressing",
  "data.task.other": "Other",
  "data.tillingDepth.shallow": "Shallow",
  "data.tillingDepth.medium": "Medium",
  "data.tillingDepth.deep": "Deep",
  "data.dropSize.fine": "Fine",
  "data.dropSize.medium": "Medium",
  "data.dropSize.coarse": "Coarse",
  "data.crop.barley": "Barley",
  "data.crop.wheat": "Wheat",
  "data.crop.maize": "Maize",
  "data.crop.rye": "Rye",
  "data.crop.oat": "Oat",
  "data.crop.sorghum": "Sorghum",
  "data.crop.triticale": "Triticale",
  "data.crop.rapeseed": "Rapeseed",
  "data.crop.grass_and_clover": "Grass and clover",
  "data.crop.sunflower": "Sunflower",
  "data.crop.potato": "Potato",
  "data.crop.beetroot": "Beetroot",
  "data.crop.carrot": "Carrot",
  "data.crop.broad_bean": "Broad bean",
  "data.crop.pea": "Pea",
  "data.crop.common_bean": "Common bean",
  "data.crop.soybean": "Soybean",
  "data.crop.cotton": "Cotton",
  "data.crop.rice": "Rice",
  "data.crop.sugarcane": "Sugarcane",

  "data.field.soilType.Sand": "Sand",
  "data.field.soilType.Loamy sand": "Loamy sand",
  "data.field.soilType.Sandy loam": "Sandy loam",
  "data.field.soilType.Loam": "Loam",
  "data.field.soilType.Silty loam": "Silty loam",
  "data.field.soilType.Silt": "Silt",
  "data.field.soilType.Clay loam": "Clay loam",
  "data.field.soilType.Sandy clay loam": "Sandy clay loam",
  "data.field.soilType.Silty clay loam": "Silty clay loam",
  "data.field.soilType.Sandy clay": "Sandy clay",
  "data.field.soilType.Silty clay": "Silty clay",
  "data.field.soilType.Clay": "Clay",

  "conditions.descriptions.harvesting.temperature.high.optimal": "",
  "conditions.descriptions.harvesting.temperature.high.good": "",
  "conditions.descriptions.harvesting.temperature.high.moderate": "",
  "conditions.descriptions.harvesting.temperature.high.poor": "",
  "conditions.descriptions.harvesting.temperature.high.critical": "",
  "conditions.descriptions.harvesting.temperature.low.optimal": "",
  "conditions.descriptions.harvesting.temperature.low.good": "",
  "conditions.descriptions.harvesting.temperature.low.moderate": "",
  "conditions.descriptions.harvesting.temperature.low.poor": "",
  "conditions.descriptions.harvesting.temperature.low.critical": "",
  "conditions.descriptions.harvesting.precipitation.high.optimal": "(Minimal to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.harvesting.precipitation.high.good": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.harvesting.precipitation.high.moderate": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.harvesting.precipitation.high.poor": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.harvesting.precipitation.high.critical": "(Light rain above threshold) There is hindrance to the tractor and equipment aswell as risk of soil smearing and loss of traction",
  "conditions.descriptions.harvesting.precipitation.low.optimal": "(Minimal to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.harvesting.precipitation.low.good": "",
  "conditions.descriptions.harvesting.precipitation.low.moderate": "",
  "conditions.descriptions.harvesting.precipitation.low.poor": "",
  "conditions.descriptions.harvesting.precipitation.low.critical": "",
  "conditions.descriptions.harvesting.soil_temperature.high.optimal": "",
  "conditions.descriptions.harvesting.soil_temperature.high.good": "",
  "conditions.descriptions.harvesting.soil_temperature.high.moderate": "",
  "conditions.descriptions.harvesting.soil_temperature.high.poor": "",
  "conditions.descriptions.harvesting.soil_temperature.high.critical": "",
  "conditions.descriptions.harvesting.soil_temperature.low.optimal": "",
  "conditions.descriptions.harvesting.soil_temperature.low.good": "",
  "conditions.descriptions.harvesting.soil_temperature.low.moderate": "",
  "conditions.descriptions.harvesting.soil_temperature.low.poor": "",
  "conditions.descriptions.harvesting.soil_temperature.low.critical": "",
  "conditions.descriptions.harvesting.soil_moisture.high.optimal": "(Very dry soil at wilting point) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.harvesting.soil_moisture.high.good": "(Drier soil) There is low risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.harvesting.soil_moisture.high.moderate": "(Dry soil) There is some risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.harvesting.soil_moisture.high.poor": "(Moist soil) There is high risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.harvesting.soil_moisture.high.critical": "(Very moist soil at field capacity) There is the highest risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.harvesting.soil_moisture.low.optimal": "(Very dry soil at wilting point) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.harvesting.soil_moisture.low.good": "",
  "conditions.descriptions.harvesting.soil_moisture.low.moderate": "",
  "conditions.descriptions.harvesting.soil_moisture.low.poor": "",
  "conditions.descriptions.harvesting.soil_moisture.low.critical": "",
  "conditions.descriptions.harvesting.wind.high.optimal": "(Calm) There is no risk of grain drift and yield loss",
  "conditions.descriptions.harvesting.wind.high.good": "(Light breeze) There is minimal risk of grain drift and yield loss",
  "conditions.descriptions.harvesting.wind.high.moderate": "(Gentle breeze)  There is minimal risk of grain drift and yield loss",
  "conditions.descriptions.harvesting.wind.high.poor": "(Moderate breeze) There is minimal risk of grain drift and yield loss",
  "conditions.descriptions.harvesting.wind.high.critical": "(Wind strong enough to lift a wheat kernel) There is a significant risk of grain drift and yield loss",
  "conditions.descriptions.harvesting.wind.low.optimal": "",
  "conditions.descriptions.harvesting.wind.low.good": "",
  "conditions.descriptions.harvesting.wind.low.moderate": "",
  "conditions.descriptions.harvesting.wind.low.poor": "",
  "conditions.descriptions.harvesting.wind.low.critical": "",
  "conditions.descriptions.sowing.temperature.high.optimal": "(Optimal temperatures) The plant growth and development will be the fastest",
  "conditions.descriptions.sowing.temperature.high.good": "(Close to optimal temperatures) The plant growth and development will be adequate",
  "conditions.descriptions.sowing.temperature.high.moderate": "(Moderately high temperatures) The plant growth and development will be slow",
  "conditions.descriptions.sowing.temperature.high.poor": "(Very high temperatures) The plant growth and development will be stunted",
  "conditions.descriptions.sowing.temperature.high.critical": "(Lethal temperatures for the crop) The seedlings will wilt and die",
  "conditions.descriptions.sowing.temperature.low.optimal": "(Optimal temperatures) The plant growth and development will be the fastest",
  "conditions.descriptions.sowing.temperature.low.good": "(Close to optimal temperatures) The plant growth and development will be adequate",
  "conditions.descriptions.sowing.temperature.low.moderate": "(Moderately low temperatures) The plant growth and development will be slow",
  "conditions.descriptions.sowing.temperature.low.poor": "(Very low temperatures) The plant growth and development will be very slow to null and there is a significant risk of frost damage",
  "conditions.descriptions.sowing.temperature.low.critical": "(Lethal temperatures for the crop) The seedlings will wilt and die",
  "conditions.descriptions.sowing.precipitation.high.optimal": "(Minimal to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.sowing.precipitation.high.good": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.sowing.precipitation.high.moderate": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.sowing.precipitation.high.poor": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.sowing.precipitation.high.critical": "(Light rain above threshold) There is hindrance to the tractor and equipment aswell as risk of soil smearing and loss of traction",
  "conditions.descriptions.sowing.precipitation.low.optimal": "(Minimal to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.sowing.precipitation.low.good": "",
  "conditions.descriptions.sowing.precipitation.low.moderate": "",
  "conditions.descriptions.sowing.precipitation.low.poor": "",
  "conditions.descriptions.sowing.precipitation.low.critical": "",
  "conditions.descriptions.sowing.soil_temperature.high.optimal": "(Optimal temperatures) The seed germination and development will be the fastest",
  "conditions.descriptions.sowing.soil_temperature.high.good": "(Close to optimal temperatures) The seed germination and development will be adequate",
  "conditions.descriptions.sowing.soil_temperature.high.moderate": "(Moderately high temperatures) The seed germination and development will be slow",
  "conditions.descriptions.sowing.soil_temperature.high.poor": "(Very high temperatures) The seed germination and development will be very slow to null and there is a chance of seed death",
  "conditions.descriptions.sowing.soil_temperature.high.critical": "(Extremely high temperatures) There is no seed germination and development and the seeds are likely to die",
  "conditions.descriptions.sowing.soil_temperature.low.optimal": "(Optimal temperatures) The seed germination and development will be the fastest",
  "conditions.descriptions.sowing.soil_temperature.low.good": "(Close to optimal temperatures) The seed germination and development will be adequate",
  "conditions.descriptions.sowing.soil_temperature.low.moderate": "(Moderately low temperatures) The seed germination and development will be slow",
  "conditions.descriptions.sowing.soil_temperature.low.poor": "(Very low temperatures) The seed germination and development will be very slow to null",
  "conditions.descriptions.sowing.soil_temperature.low.critical": "(Extremely low temperatures) There is no seed germination and development",
  "conditions.descriptions.sowing.soil_moisture.high.optimal": "(Soil at half of capacity) - The soil is moist enough for optimal seed germination and dry enough to avoid tractor compaction",
  "conditions.descriptions.sowing.soil_moisture.high.good": "(Soil almost at half of capacity) - The seed germination potential is optimal, but there is an increasing chance of tractor soil compaction",
  "conditions.descriptions.sowing.soil_moisture.high.moderate": "(Moist soil) The seed germination potential is adequate, but there is also a significant chance of tractor soil compaction",
  "conditions.descriptions.sowing.soil_moisture.high.poor": "(Moist soil very close to field capacity) The seed germination potential is reduced in some crops and there is a high chance of tractor soil compaction",
  "conditions.descriptions.sowing.soil_moisture.high.critical": "(Moist soil at field capacity) The seed germination potential is reduced in some crops and there is a very high chance of tractor soil compaction",
  "conditions.descriptions.sowing.soil_moisture.low.optimal": "(Soil at half of capacity) - The soil is moist enough for optimal seed germination and dry enough to avoid tractor compaction",
  "conditions.descriptions.sowing.soil_moisture.low.good": "(Soil almost at half of capacity) - The seed germination potential is adequate",
  "conditions.descriptions.sowing.soil_moisture.low.moderate": "(Dry soil) The seed germination potential is low",
  "conditions.descriptions.sowing.soil_moisture.low.poor": "(Very dry soil, close to the wilting point) The seed germination potential is very low",
  "conditions.descriptions.sowing.soil_moisture.low.critical": "(Very dry soil at wilting point) The seed germination potential is lowest",
  "conditions.descriptions.sowing.wind.high.optimal": "(Calm) There is no risk of seed displacement and sowing inconistency",
  "conditions.descriptions.sowing.wind.high.good": "(Light breeze) There is minimal risk of seed displacement and sowing inconistency",
  "conditions.descriptions.sowing.wind.high.moderate": "(Gentle breeze) There is minimal risk of seed displacement and sowing inconistency",
  "conditions.descriptions.sowing.wind.high.poor": "(Moderate breeze) There is minimal risk of seed displacement and sowing inconistency",
  "conditions.descriptions.sowing.wind.high.critical": "(Wind strong enough to lift a wheat kernel) There is a significant risk of seed displacement and sowing inconistency",
  "conditions.descriptions.sowing.wind.low.optimal": "(Calm) There is no risk of seed displacement and sowing inconistency",
  "conditions.descriptions.sowing.wind.low.good": "",
  "conditions.descriptions.sowing.wind.low.moderate": "",
  "conditions.descriptions.sowing.wind.low.poor": "",
  "conditions.descriptions.sowing.wind.low.critical": "",
  "conditions.descriptions.mowing.temperature.high.optimal": "",
  "conditions.descriptions.mowing.temperature.high.good": "",
  "conditions.descriptions.mowing.temperature.high.moderate": "",
  "conditions.descriptions.mowing.temperature.high.poor": "",
  "conditions.descriptions.mowing.temperature.high.critical": "",
  "conditions.descriptions.mowing.temperature.low.optimal": "",
  "conditions.descriptions.mowing.temperature.low.good": "",
  "conditions.descriptions.mowing.temperature.low.moderate": "",
  "conditions.descriptions.mowing.temperature.low.poor": "",
  "conditions.descriptions.mowing.temperature.low.critical": "",
  "conditions.descriptions.mowing.precipitation.high.optimal": "(Little to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.mowing.precipitation.high.good": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.mowing.precipitation.high.moderate": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.mowing.precipitation.high.poor": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.mowing.precipitation.high.critical": "(Light rain above threshold) There is hindrance to the tractor and equipment aswell as risk of soil smearing and loss of traction",
  "conditions.descriptions.mowing.precipitation.low.optimal": "(Little to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.mowing.precipitation.low.good": "",
  "conditions.descriptions.mowing.precipitation.low.moderate": "",
  "conditions.descriptions.mowing.precipitation.low.poor": "",
  "conditions.descriptions.mowing.precipitation.low.critical": "",
  "conditions.descriptions.mowing.soil_temperature.high.optimal": "",
  "conditions.descriptions.mowing.soil_temperature.high.good": "",
  "conditions.descriptions.mowing.soil_temperature.high.moderate": "",
  "conditions.descriptions.mowing.soil_temperature.high.poor": "",
  "conditions.descriptions.mowing.soil_temperature.high.critical": "",
  "conditions.descriptions.mowing.soil_temperature.low.optimal": "",
  "conditions.descriptions.mowing.soil_temperature.low.good": "",
  "conditions.descriptions.mowing.soil_temperature.low.moderate": "",
  "conditions.descriptions.mowing.soil_temperature.low.poor": "",
  "conditions.descriptions.mowing.soil_temperature.low.critical": "",
  "conditions.descriptions.mowing.soil_moisture.high.optimal": "(Very dry soil at wilting point) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.mowing.soil_moisture.high.good": "(Drier soil) There is low risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.mowing.soil_moisture.high.moderate": "(Dry soil) There is some risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.mowing.soil_moisture.high.poor": "(Moist soil) There is high risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.mowing.soil_moisture.high.critical": "(Very moist soil at field capacity) There is the highest risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.mowing.soil_moisture.low.optimal": "(Very dry soil at wilting point) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.mowing.soil_moisture.low.good": "",
  "conditions.descriptions.mowing.soil_moisture.low.moderate": "",
  "conditions.descriptions.mowing.soil_moisture.low.poor": "",
  "conditions.descriptions.mowing.soil_moisture.low.critical": "",
  "conditions.descriptions.mowing.wind.high.optimal": "(Calm) There is no risk of straw and residue drifting",
  "conditions.descriptions.mowing.wind.high.good": "(Light air) There is minimal risk of straw and residue drifting",
  "conditions.descriptions.mowing.wind.high.moderate": "(Gentle breeze) There is minimal risk of straw and residue drifting",
  "conditions.descriptions.mowing.wind.high.poor": "(Moderate breeze) -There is some risk of straw and residue drifting",
  "conditions.descriptions.mowing.wind.high.critical": "(Fresh breeze) The leaves begin to sway and there is a high risk of straw and residue drifting",
  "conditions.descriptions.mowing.wind.low.optimal": "(Calm) There is no risk of straw and residue drifting",
  "conditions.descriptions.mowing.wind.low.good": "",
  "conditions.descriptions.mowing.wind.low.moderate": "",
  "conditions.descriptions.mowing.wind.low.poor": "",
  "conditions.descriptions.mowing.wind.low.critical": "",
  "conditions.descriptions.spraying.fine.temperature.high.optimal": "",
  "conditions.descriptions.spraying.fine.temperature.high.good": "",
  "conditions.descriptions.spraying.fine.temperature.high.moderate": "",
  "conditions.descriptions.spraying.fine.temperature.high.poor": "",
  "conditions.descriptions.spraying.fine.temperature.high.critical": "",
  "conditions.descriptions.spraying.fine.temperature.low.optimal": "",
  "conditions.descriptions.spraying.fine.temperature.low.good": "",
  "conditions.descriptions.spraying.fine.temperature.low.moderate": "",
  "conditions.descriptions.spraying.fine.temperature.low.poor": "",
  "conditions.descriptions.spraying.fine.temperature.low.critical": "",
  "conditions.descriptions.spraying.fine.precipitation.high.optimal": "(No rain) There is no hindrance to tractor and equipment and no risk of product runoff",
  "conditions.descriptions.spraying.fine.precipitation.high.good": "(Little rain) There is minimal hindrance to tractor and equipment and minimal risk of product runoff",
  "conditions.descriptions.spraying.fine.precipitation.high.moderate": "(Little rain) There is minimal hindrance to tractor and equipment and minimal risk of product runoff",
  "conditions.descriptions.spraying.fine.precipitation.high.poor": "(Little rain) There is minimal hindrance to tractor and equipment and minimal risk of product runoff",
  "conditions.descriptions.spraying.fine.precipitation.high.critical": "(Light rain above threshold) There is hindrance to tractor and equipment with a risk of soil compaction and smearing and risk of product runoff",
  "conditions.descriptions.spraying.fine.precipitation.low.optimal": "(No rain) There is no hindrance to tractor and equipment and no risk of product runoff",
  "conditions.descriptions.spraying.fine.precipitation.low.good": "",
  "conditions.descriptions.spraying.fine.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.fine.precipitation.low.poor": "",
  "conditions.descriptions.spraying.fine.precipitation.low.critical": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.fine.soil_moisture.high.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.fine.soil_moisture.high.good": "(Drier soil) There is low risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.fine.soil_moisture.high.moderate": "(Dry soil) There is some risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.fine.soil_moisture.high.poor": "(Moist soil) There is high risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.fine.soil_moisture.high.critical": "(Very moist soil at field capacity) There is the highest risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.fine.soil_moisture.low.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.fine.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.fine.wind.high.optimal": "(Light air above threshold) There is a very low risk of temperature inversion and very low risk of spray drift",
  "conditions.descriptions.spraying.fine.wind.high.good": "(Light air above threshold) Very low risk of temperature inversion and low risk of spray drift",
  "conditions.descriptions.spraying.fine.wind.high.moderate": "(Light breeze) There is a very low risk of temperature inversion and some risk of spray drift",
  "conditions.descriptions.spraying.fine.wind.high.poor": "(Gentle breeze) There is a high spray drift potential",
  "conditions.descriptions.spraying.fine.wind.high.critical": "(Gentle breeze and above) There is a very high spray drift potential",
  "conditions.descriptions.spraying.fine.wind.low.optimal": "(Light air above threshold) There is a very low risk of temperature inversion and very low risk of spray drift",
  "conditions.descriptions.spraying.fine.wind.low.good": "(Light air) There is some risk of temperature inversion and risk of spray drift",
  "conditions.descriptions.spraying.fine.wind.low.moderate": "(Light air) There is some risk of temperature inversion and risk of spray drift",
  "conditions.descriptions.spraying.fine.wind.low.poor": "(Light air) There is some risk of temperature inversion and risk of spray drift",
  "conditions.descriptions.spraying.fine.wind.low.critical": "(Calm) There is a high risk of temperature inversion and high risk of spray drift",
  "conditions.descriptions.spraying.medium.temperature.high.optimal": "",
  "conditions.descriptions.spraying.medium.temperature.high.good": "",
  "conditions.descriptions.spraying.medium.temperature.high.moderate": "",
  "conditions.descriptions.spraying.medium.temperature.high.poor": "",
  "conditions.descriptions.spraying.medium.temperature.high.critical": "",
  "conditions.descriptions.spraying.medium.temperature.low.optimal": "",
  "conditions.descriptions.spraying.medium.temperature.low.good": "",
  "conditions.descriptions.spraying.medium.temperature.low.moderate": "",
  "conditions.descriptions.spraying.medium.temperature.low.poor": "",
  "conditions.descriptions.spraying.medium.temperature.low.critical": "",
  "conditions.descriptions.spraying.medium.precipitation.high.optimal": "(No rain) There is no hindrance to tractor and equipment and no risk of product runoff",
  "conditions.descriptions.spraying.medium.precipitation.high.good": "(Little rain) There is minimal hindrance to tractor and equipment and minimal risk of product runoff",
  "conditions.descriptions.spraying.medium.precipitation.high.moderate": "(Little rain) There is minimal hindrance to tractor and equipment and minimal risk of product runoff",
  "conditions.descriptions.spraying.medium.precipitation.high.poor": "(Little rain) There is minimal hindrance to tractor and equipment and minimal risk of product runoff",
  "conditions.descriptions.spraying.medium.precipitation.high.critical": "(Light rain above threshold) There is hindrance to tractor and equipment with a risk of soil compaction and smearing and risk of product runoff",
  "conditions.descriptions.spraying.medium.precipitation.low.optimal": "(No rain) There is no hindrance to tractor and equipment and no risk of product runoff",
  "conditions.descriptions.spraying.medium.precipitation.low.good": "",
  "conditions.descriptions.spraying.medium.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.medium.precipitation.low.poor": "",
  "conditions.descriptions.spraying.medium.precipitation.low.critical": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.medium.soil_moisture.high.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.medium.soil_moisture.high.good": "(Drier soil) There is low risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.medium.soil_moisture.high.moderate": "(Dry soil) There is some risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.medium.soil_moisture.high.poor": "(Moist soil) There is high risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.medium.soil_moisture.high.critical": "(Very moist soil at field capacity) There is the highest risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.medium.soil_moisture.low.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.medium.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.medium.wind.high.optimal": "(Light air) There is a very low risk of temperature inversion and low risk of spray drift",
  "conditions.descriptions.spraying.medium.wind.high.good": "(Light air) There is a very low risk of temperature inversion and low risk of spray drift",
  "conditions.descriptions.spraying.medium.wind.high.moderate": "(Light breeze) There is a very low risk of temperature inversion and some risk of spray drift",
  "conditions.descriptions.spraying.medium.wind.high.poor": "(Gentle breeze) There is a high spray drift potential",
  "conditions.descriptions.spraying.medium.wind.high.critical": "(Gentle breeze) There is a very high spray drift potential",
  "conditions.descriptions.spraying.medium.wind.low.optimal": "(Light air) There is a very low risk of temperature inversion and low risk of spray drift",
  "conditions.descriptions.spraying.medium.wind.low.good": "(Calm to light air) There is a risk of temperature inversion but a low risk of spray drift",
  "conditions.descriptions.spraying.medium.wind.low.moderate": "",
  "conditions.descriptions.spraying.medium.wind.low.poor": "",
  "conditions.descriptions.spraying.medium.wind.low.critical": "",
  "conditions.descriptions.spraying.coarse.temperature.high.optimal": "",
  "conditions.descriptions.spraying.coarse.temperature.high.good": "",
  "conditions.descriptions.spraying.coarse.temperature.high.moderate": "",
  "conditions.descriptions.spraying.coarse.temperature.high.poor": "",
  "conditions.descriptions.spraying.coarse.temperature.high.critical": "",
  "conditions.descriptions.spraying.coarse.temperature.low.optimal": "",
  "conditions.descriptions.spraying.coarse.temperature.low.good": "",
  "conditions.descriptions.spraying.coarse.temperature.low.moderate": "",
  "conditions.descriptions.spraying.coarse.temperature.low.poor": "",
  "conditions.descriptions.spraying.coarse.temperature.low.critical": "",
  "conditions.descriptions.spraying.coarse.precipitation.high.optimal": "(No rain) There is no hindrance to tractor and equipment and no risk of product runoff",
  "conditions.descriptions.spraying.coarse.precipitation.high.good": "(Little rain) There is minimal hindrance to tractor and equipment and minimal risk of product runoff",
  "conditions.descriptions.spraying.coarse.precipitation.high.moderate": "(Little rain) There is minimal hindrance to tractor and equipment and minimal risk of product runoff",
  "conditions.descriptions.spraying.coarse.precipitation.high.poor": "(Little rain) There is minimal hindrance to tractor and equipment and minimal risk of product runoff",
  "conditions.descriptions.spraying.coarse.precipitation.high.critical": "(Light rain above threshold) There is hindrance to tractor and equipment with a risk of soil compaction and smearing and risk of product runoff",
  "conditions.descriptions.spraying.coarse.precipitation.low.optimal": "(No rain) There is no hindrance to tractor and equipment and no risk of product runoff",
  "conditions.descriptions.spraying.coarse.precipitation.low.good": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.poor": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.critical": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.good": "(Drier soil) There is low risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.moderate": "(Dry soil) There is some risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.poor": "(Moist soil) There is high risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.critical": "(Very moist soil at field capacity) There is the highest risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.coarse.wind.high.optimal": "(Light air) There is a very low risk of temperature inversion and a very low or null risk of spray drift",
  "conditions.descriptions.spraying.coarse.wind.high.good": "(Light air) There is a very low risk of temperature inversion and low risk of spray drift",
  "conditions.descriptions.spraying.coarse.wind.high.moderate": "(Light breeze) There is a very low risk of temperature inversion and a small risk of spray drift",
  "conditions.descriptions.spraying.coarse.wind.high.poor": "(Gentle breeze) There is some risk of spray drift",
  "conditions.descriptions.spraying.coarse.wind.high.critical": "(Moderate breeze) There is a high spray drift potential",
  "conditions.descriptions.spraying.coarse.wind.low.optimal": "(Light air) There is a very low risk of temperature inversion and a very low or null risk of spray drift",
  "conditions.descriptions.spraying.coarse.wind.low.good": "(Calm to light air) There is a risk of temperature inversion but a very low risk of spray drift",
  "conditions.descriptions.spraying.coarse.wind.low.moderate": "",
  "conditions.descriptions.spraying.coarse.wind.low.poor": "",
  "conditions.descriptions.spraying.coarse.wind.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.optimal": "(Minimal to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.good": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.moderate": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.poor": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.critical": "(Light rain above threshold) There is hindrance to the tractor and equipment aswell as risk of soil smearing and loss of traction",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.optimal": "(Minimal to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.good": "(Drier soil) There is low risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.moderate": "(Dry soil) There is some risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.poor": "(Moist soil) There is high risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.critical": "(Very moist soil at field capacity) There is the highest risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.optimal": "(Calm) There is no risk of product drift and application efficiency is maximized",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.good": "(Light air) There is minimal risk of product drift and application efficiency is high",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.moderate": "(Light breeze) There is risk of product drift and apllication efficiency might be reduced",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.poor": "(Moderate breeze) There is a high risk of product drift and application efficiency will be reduced",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.critical": "(Fresh breeze) There is a very high risk of product drift and application efficiency will be greatly reduced",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.optimal": "(Calm) There is no risk of product drift and application efficiency is maximized",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.optimal": "(Little to no rain) There is minimal to no hindrance to tractor and equipment and minimal chance of product leaching",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.good": "(Light rain) There is a small hindrance to tractor and equipment and a small chance of product leaching",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.moderate": "(Light rain) There is a small hindrance to tractor and equipment and a small chance of product leaching",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.poor": "(Light rain) There is a small hindrance to tractor and equipment and a small chance of product leaching",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.critical": "(Light rain above threshold) There is hindrance to tractor and equipment, a risk of soil smearing and loss of traction and high chance of product leaching",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.optimal": "(Little to no rain) There is minimal to no hindrance to tractor and equipment and minimal chance of product leaching",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.good": "(Drier soil) There is low risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.moderate": "(Dry soil) There is some risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.poor": "(Moist soil) There is high risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.critical": "(Very moist soil at field capacity) There is the highest risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.optimal": "(Calm) There is no risk of product drift and application efficiency is maximized",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.good": "(Light air) There is minimal risk of product drift and application efficiency is high",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.moderate": "(Light breeze) There is a risk of product drift and apllication efficiency might be reduced",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.poor": "(Gentle breeze) There is a high risk of product drift and reduced application efficiency",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.critical": "(Moderate breeze or above) There is a very high risk of product drift and application efficiency will be greatly reduced",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.optimal": "(Calm) There is no risk of product drift and application efficiency is maximized",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.optimal": "(Little to no rain) There is minimal to no hindrance to tractor and equipment and minimal chance of product leaching",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.good": "(Light rain) There is a small hindrance to tractor and equipment and a small chance of product leaching",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.moderate": "(Light rain) There is a small hindrance to tractor and equipment and a small chance of product leaching",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.poor": "(Light rain) There is a small hindrance to tractor and equipment and a small chance of product leaching",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.critical": "(Light rain above threshold) There is hindrance to tractor and equipment, a risk of soil smearing and loss of traction and high chance of product leaching",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.optimal": "(Little to no rain) There is minimal to no hindrance to tractor and equipment and minimal chance of product leaching",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.good": "(Drier soil) There is low risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.moderate": "(Dry soil) There is some risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.poor": "(Moist soil) There is high risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.critical": "(Very moist soil at field capacity) There is the highest risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.optimal": "(Very dry soil) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.critical": "",
  "conditions.descriptions.tilling.temperature.high.optimal": "",
  "conditions.descriptions.tilling.temperature.high.good": "",
  "conditions.descriptions.tilling.temperature.high.moderate": "",
  "conditions.descriptions.tilling.temperature.high.poor": "",
  "conditions.descriptions.tilling.temperature.high.critical": "",
  "conditions.descriptions.tilling.temperature.low.optimal": "",
  "conditions.descriptions.tilling.temperature.low.good": "",
  "conditions.descriptions.tilling.temperature.low.moderate": "",
  "conditions.descriptions.tilling.temperature.low.poor": "",
  "conditions.descriptions.tilling.temperature.low.critical": "",
  "conditions.descriptions.tilling.precipitation.high.optimal": "(Minimal to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.tilling.precipitation.high.good": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.tilling.precipitation.high.moderate": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.tilling.precipitation.high.poor": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.tilling.precipitation.high.critical": "(Light rain above threshold) There is hindrance to the tractor and equipment aswell as risk of soil smearing and loss of traction",
  "conditions.descriptions.tilling.precipitation.low.optimal": "(Minimal to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.tilling.precipitation.low.good": "",
  "conditions.descriptions.tilling.precipitation.low.moderate": "",
  "conditions.descriptions.tilling.precipitation.low.poor": "",
  "conditions.descriptions.tilling.precipitation.low.critical": "",
  "conditions.descriptions.tilling.soil_temperature.high.optimal": "",
  "conditions.descriptions.tilling.soil_temperature.high.good": "",
  "conditions.descriptions.tilling.soil_temperature.high.moderate": "",
  "conditions.descriptions.tilling.soil_temperature.high.poor": "",
  "conditions.descriptions.tilling.soil_temperature.high.critical": "",
  "conditions.descriptions.tilling.soil_temperature.low.optimal": "",
  "conditions.descriptions.tilling.soil_temperature.low.good": "",
  "conditions.descriptions.tilling.soil_temperature.low.moderate": "",
  "conditions.descriptions.tilling.soil_temperature.low.poor": "",
  "conditions.descriptions.tilling.soil_temperature.low.critical": "",
  "conditions.descriptions.tilling.soil_moisture.high.optimal": "(Soil moisture at half of capacity) Tilling is safe in majority of soil textures and will result in a proper clod size, reduced fuel consumption and low risk of soil smearing and compaction",
  "conditions.descriptions.tilling.soil_moisture.high.good": "(Soil moisture almost at half of capacity) Tilling is easy with low fuel consumption and very low risk of soil smearing and compaction",
  "conditions.descriptions.tilling.soil_moisture.high.moderate": "(Moist soil) Tilling is acceptable, although there is some risk of soil smearing and compaction",
  "conditions.descriptions.tilling.soil_moisture.high.poor": "(Moist soil close to field capacity) Tilling will be inefficient with a high risk of soil smearing and compaction",
  "conditions.descriptions.tilling.soil_moisture.high.critical": "(Moist Soil at or above field capacity) Tilling is unadvised, with the highest risk of soil smearing and compaction",
  "conditions.descriptions.tilling.soil_moisture.low.optimal": "(Soil moisture at half of capacity) Tilling is safe in majority of soil textures and will result in a proper clod size, reduced fuel consumption and low risk of soil smearing and compaction",
  "conditions.descriptions.tilling.soil_moisture.low.good": "(Soil moisture almost at half of capacity) Tilling is easy with low fuel consumption",
  "conditions.descriptions.tilling.soil_moisture.low.moderate": "(Dry soil) Tilling is acceptable with average fuel consumption",
  "conditions.descriptions.tilling.soil_moisture.low.poor": "(Dry soil close to wilting point) Tilling will be hard with high fuel consumption and pulverization into fine particulars or large clods is likely",
  "conditions.descriptions.tilling.soil_moisture.low.critical": "(Dry soil at wilting point) Tilling will be very hard with the highest fuel consumption and pulverization into fine particules or big clods is very likely",
  "conditions.descriptions.tilling.wind.high.optimal": "(Calm) There is no risk of wind erosion of soil",
  "conditions.descriptions.tilling.wind.high.good": "(Light or gentle breeze) There is minimal risk of wind erosion of soil",
  "conditions.descriptions.tilling.wind.high.moderate": "(Moderate breeze) There is minimal risk of wind erosion of soil",
  "conditions.descriptions.tilling.wind.high.poor": "(Strong breeze) There is considerable risk of wind erosion of soil",
  "conditions.descriptions.tilling.wind.high.critical": "(Above moderate gale) The Wind erosion of soil is very likely",
  "conditions.descriptions.tilling.wind.low.optimal": "(Calm) There is no risk of wind erosion of soil",
  "conditions.descriptions.tilling.wind.low.good": "",
  "conditions.descriptions.tilling.wind.low.moderate": "",
  "conditions.descriptions.tilling.wind.low.poor": "",
  "conditions.descriptions.tilling.wind.low.critical": "",
  "conditions.descriptions.pressing.temperature.high.optimal": "",
  "conditions.descriptions.pressing.temperature.high.good": "",
  "conditions.descriptions.pressing.temperature.high.moderate": "",
  "conditions.descriptions.pressing.temperature.high.poor": "",
  "conditions.descriptions.pressing.temperature.high.critical": "",
  "conditions.descriptions.pressing.temperature.low.optimal": "",
  "conditions.descriptions.pressing.temperature.low.good": "",
  "conditions.descriptions.pressing.temperature.low.moderate": "",
  "conditions.descriptions.pressing.temperature.low.poor": "",
  "conditions.descriptions.pressing.temperature.low.critical": "",
  "conditions.descriptions.pressing.precipitation.high.optimal": "(Little to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.pressing.precipitation.high.good": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.pressing.precipitation.high.moderate": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.pressing.precipitation.high.poor": "(Light rain) There is a small hindrance to the tractor and equipment",
  "conditions.descriptions.pressing.precipitation.high.critical": "(Light rain above threshold) There is hindrance to the tractor and equipment aswell as risk of soil smearing and loss of traction",
  "conditions.descriptions.pressing.precipitation.low.optimal": "(Little to no rain)  There is minimal to no hindrance to the tractor and equipment",
  "conditions.descriptions.pressing.precipitation.low.good": "",
  "conditions.descriptions.pressing.precipitation.low.moderate": "",
  "conditions.descriptions.pressing.precipitation.low.poor": "",
  "conditions.descriptions.pressing.precipitation.low.critical": "",
  "conditions.descriptions.pressing.soil_temperature.high.optimal": "",
  "conditions.descriptions.pressing.soil_temperature.high.good": "",
  "conditions.descriptions.pressing.soil_temperature.high.moderate": "",
  "conditions.descriptions.pressing.soil_temperature.high.poor": "",
  "conditions.descriptions.pressing.soil_temperature.high.critical": "",
  "conditions.descriptions.pressing.soil_temperature.low.optimal": "",
  "conditions.descriptions.pressing.soil_temperature.low.good": "",
  "conditions.descriptions.pressing.soil_temperature.low.moderate": "",
  "conditions.descriptions.pressing.soil_temperature.low.poor": "",
  "conditions.descriptions.pressing.soil_temperature.low.critical": "",
  "conditions.descriptions.pressing.soil_moisture.high.optimal": "(Very dry soil at wilting point) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.pressing.soil_moisture.high.good": "(Drier soil) There is low risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.pressing.soil_moisture.high.moderate": "(Dry soil) There is some risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.pressing.soil_moisture.high.poor": "(Moist soil) There is high risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.pressing.soil_moisture.high.critical": "(Very moist soil at field capacity) There is the highest risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.pressing.soil_moisture.low.optimal": "(Very dry soil at wilting point) There is minimal risk of soil compaction, soil damage and of the tractor getting stuck",
  "conditions.descriptions.pressing.soil_moisture.low.good": "",
  "conditions.descriptions.pressing.soil_moisture.low.moderate": "",
  "conditions.descriptions.pressing.soil_moisture.low.poor": "",
  "conditions.descriptions.pressing.soil_moisture.low.critical": "",
  "conditions.descriptions.pressing.wind.high.optimal": "(Calm) There is no risk of straw and residue drifting",
  "conditions.descriptions.pressing.wind.high.good": "(Light air) There is minimal risk of straw and residue drifting",
  "conditions.descriptions.pressing.wind.high.moderate": "(Gentle breeze) There is minimal risk of straw and residue drifting",
  "conditions.descriptions.pressing.wind.high.poor": "(Moderate breeze) -There is some risk of straw and residue drifting",
  "conditions.descriptions.pressing.wind.high.critical": "(Fresh breeze) The leaves begin to sway and there is a high risk of straw and residue drifting",
  "conditions.descriptions.pressing.wind.low.optimal": "(Calm) There is no risk of straw and residue drifting",
  "conditions.descriptions.pressing.wind.low.good": "",
  "conditions.descriptions.pressing.wind.low.moderate": "",
  "conditions.descriptions.pressing.wind.low.poor": "",
  "conditions.descriptions.pressing.wind.low.critical": "",
};
