import { Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { Button, Input, Popover } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { useSessionStorageValue } from "@react-hookz/web";
import { GrUserAdmin } from "react-icons/gr";
import { sessionStorageKeys } from "../../utils/storageKeys";
import { IconToggle } from "../IconToggle";

export const EnterpriseOverride = () => {
  const {
    value: adminOverrideEnterpriseId,
    set: setAdminOverrideEnterpriseId,
    remove: removeAdminOverrideEnterpriseId,
  } = useSessionStorageValue<string>(
    sessionStorageKeys.adminOverrideEnterpriseId
  );
  const [overrideEnterpriseIdInputValue, setOverrideEnterpriseIdInputValue] =
    useInputState(adminOverrideEnterpriseId);
  const onSaveClick = () => {
    if (
      typeof overrideEnterpriseIdInputValue === "string" &&
      overrideEnterpriseIdInputValue !== ""
    ) {
      setAdminOverrideEnterpriseId(overrideEnterpriseIdInputValue);
    } else {
      removeAdminOverrideEnterpriseId();
    }
  };
  const onClearClick = () => {
    setOverrideEnterpriseIdInputValue("");
    removeAdminOverrideEnterpriseId();
  };

  const isEnterpriseIdOverridden =
    typeof adminOverrideEnterpriseId === "string" &&
    adminOverrideEnterpriseId !== "";

  return (
    <Popover position="right" withArrow>
      <Popover.Target>
        <IconToggle boxShadow="none" background="none">
          <GrUserAdmin
            size={24}
            css={
              isEnterpriseIdOverridden
                ? css`
                    > path {
                      stroke: white;
                    }
                  `
                : undefined
            }
          />
        </IconToggle>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex direction="column" gap="16px" width={350} color="black">
          <FormControl>
            <FormLabel>Enterprise ID:</FormLabel>
            <Input
              value={overrideEnterpriseIdInputValue}
              onChange={setOverrideEnterpriseIdInputValue}
            />
          </FormControl>
          <Flex gap="10px" justifyContent="flex-end">
            <Button onClick={onClearClick} variant="outline">
              Clear
            </Button>
            <Button onClick={onSaveClick}>Save</Button>
          </Flex>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};
