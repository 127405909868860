import { Avatar, AvatarProps, Tooltip } from "@mantine/core";
import { FC } from "react";
import { User } from "../hooks/api/karl-farm/useUsers";
import { getFullName, getInitials } from "../utils/getFullName";
import { getUserColor } from "../utils/getUserColor";

type UserAvatarProps = AvatarProps & {
  user?: User;
  hideTooltip?: boolean;
};
export const UserAvatar: FC<UserAvatarProps> = ({
  user,
  hideTooltip,
  ...restProps
}) => {
  const firstName = user?.Attributes.name;
  const lastName = user?.Attributes.family_name;
  const fullName = getFullName(firstName, lastName);
  const initials = getInitials(firstName, lastName);
  const color = getUserColor(user);
  const email = user?.Attributes.email;

  const tooltipLabel = fullName || email;
  const isTooltipEmpty = tooltipLabel === "" || tooltipLabel == null;

  return (
    <Tooltip
      disabled={hideTooltip || isTooltipEmpty}
      label={tooltipLabel}
      withArrow
    >
      <Avatar color={color} radius="xl" {...restProps}>
        {initials}
      </Avatar>
    </Tooltip>
  );
};
