import { isTruthy } from "./typeGuards";

export const getFullName = (...strings: Array<string | undefined>): string => {
  return strings
    .filter(isTruthy) // exclude undefined and empty string
    .join(" ");
};

export const getInitials = (...names: Array<string | undefined>): string => {
  return names
    .map((name) => name?.[0])
    .filter(isTruthy) // exclude undefined and empty string
    .join("")
    .toUpperCase();
};
