import { useQuery } from "@tanstack/react-query";
import { karlFarmGet } from "./queryClient";

// Route: GET /data/users
// API Docs: n/a

export const getUseUsersQueryKey = () => {
  return ["/data/users"];
};

export type User = NonNullable<
  NonNullable<ReturnType<typeof useUsers>["data"]>["data"]["users"]
>[number];

export const useUsers = () => {
  return useQuery(getUseUsersQueryKey(), {
    queryFn: async () => {
      const response = await karlFarmGet<UsersResponse>(
        getUseUsersQueryKey()[0]
      ).catch((error) => {
        if (error?.response?.status === 404) {
          // Patch 404 response to return empty array
          const emptyResponse: UsersResponse = {
            messages: { status: "OK" },
            data: { users: [], info: { returned_count: 0 } },
          };
          return emptyResponse;
        }
        throw error;
      });

      // Patch user Attributes objects
      const users = response?.data.users.map((user) => {
        return {
          ...user,
          Attributes: user.Attributes.reduce((acc, attribute) => {
            acc[attribute.Name] = attribute.Value;
            return acc;
          }, {} as Partial<Record<Attribute["Name"], Attribute["Value"]>>),
        };
      });

      return {
        ...response,
        data: {
          ...response?.data,
          users,
        },
      };
    },
  });
};

export type UsersResponse = {
  messages: Messages;
  data: Data;
};

export type Data = {
  users: UserDto[];
  info: Info;
};

export type Info = {
  returned_count: number;
};

export type UserDto = {
  Username: string;
  Attributes: Attribute[];
  Enabled: boolean;
  UserStatus: string;
};

export type Attribute = {
  Name:
    | "custom:ent_api_key2"
    | "sub"
    | "custom:ent_id2"
    | "name"
    | "phone_number"
    | "preferred_username"
    | "family_name"
    | "custom:user_type"
    | "email"
    | "custom:user_category"
    | "identities"
    | "email_verified";
  Value: string;
};

export type Messages = {
  status: string;
};
