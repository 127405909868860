import { MantineColor } from "@mantine/core";
import { User } from "../hooks/api/karl-farm/useUsers";
import { getFullName } from "./getFullName";
import { getPsuedoRandomMantineColor } from "./getPseudoRandomMantineColor";

export const getUserColor = (user?: User): MantineColor => {
  const fullName = getFullName(
    user?.Attributes.name,
    user?.Attributes.family_name
  );
  return getPsuedoRandomMantineColor(fullName);
};
