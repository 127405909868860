import type { en } from "./en";

export const fr: typeof en = {
  "page.map.button.mapStyle.map": "Carte",
  "page.map.button.mapStyle.satellite": "Satellite",
  "page.map.notification.message.karlDoesNotWorkInThisAreaYet": "Les limites automatiques des champs ne sont pas encore prises en charge dans cette région. Veuillez le dessiner manuellement.",
  "page.map.notification.title.fieldShouldAppearInAMoment": "Le champ a été ajouté.",
  "page.map.notification.message.fieldShouldAppearInAMoment": "Il devrait apparaître sur la carte dans quelques secondes.",
  "page.map.fieldLoadingStatus.text": "Traitement des données du champ...",
  "page.map.overlay.button.clear": "Effacer",
  "page.map.overlay.button.undo": "Annuler",
  "page.map.overlay.button.continue": "Continuer",
  "page.map.overlay.button.automatic.text": "Automatique",
  "page.map.overlay.button.automatic.tooltip": "Dans certaines régions, KARL peut automatiquement délimiter les limites des champs. Cliquez simplement sur un champ et voyez le contour. Si le contour est incorrect, ou si la zone n'est pas prise en charge, vous pouvez le dessiner manuellement.",
  "page.map.overlay.button.manual.text": "Manuel",
  "page.map.overlay.button.manual.tooltip": "Dessinez les limites de votre champ en cliquant sur la carte jusqu'à ce que le champ soit correctement délimité, puis sélectionnez le bouton Continuer en bas de l'écran.",

  "page.users.heading": "Utilisateurs",
  "page.users.iconButton.addUser.ariaLabel": "Ajouter un utilisateur",
  "page.users.userId": "ID de l'utilisateur",
  "page.users.phone": "Numéro de téléphone",
  "page.users.email": "Email",
  "page.users.emailVerified": "Email vérifié",
  "page.users.emailVerified.yes": "Oui, oui, oui",
  "page.users.emailVerified.no": "Non",
  "page.users.employment": "Lien avec l'emploi",
  "page.users.employment.external": "Externe",
  "page.users.employment.internal": "Interne",
  "page.users.addUserDrawer.heading": "Ajouter un utilisateur",
  "page.users.addUserDrawer.input.firstName": "Prénom",
  "page.users.addUserDrawer.input.lastName": "Nom de famille",
  "page.users.addUserDrawer.input.email": "Adresse e-mail",
  "page.users.addUserDrawer.checkbox.userCategory.label": "Lien avec l'emploi",
  "page.users.addUserDrawer.checkbox.userCategory.text": "Externe",
  "page.users.addUserDrawer.input.phone": "Numéro de téléphone",
  "page.users.addUserDrawer.button.submit": "Ajouter votre nom",

  "page.machines.heading": "Machines et outils",
  "page.machines.iconButton.addMachine.ariaLabel": "Ajouter une machine ou un outil",
  "page.machines.machineId": "ID de la machine",
  "page.machines.brand": "Marque",
  "page.machines.model": "Modèle de la machine",
  "page.machines.yearProduced": "Année de fabrication",
  "page.machines.description": "Description de la machine",
  "page.machines.category": "Catégorie",
  "page.machines.type": "Type de",
  "page.machines.type.machine": "machine",
  "page.machines.type.equipment": "Outil",
  "page.machines.addMachineDrawer.heading": "Ajouter la machine ou l'outil",
  "page.machines.addMachineDrawer.input.brand": "Marque",
  "page.machines.addMachineDrawer.input.model": "Modèle de la machine",
  "page.machines.addMachineDrawer.input.yearProduced": "Année de fabrication",
  "page.machines.addMachineDrawer.input.description": "Description de la machine",
  "page.machines.addMachineDrawer.select.type": "Type de machine",
  "page.machines.addMachineDrawer.select.type.machine": "Machine",
  "page.machines.addMachineDrawer.select.type.equipment": "Outil",
  "page.machines.addMachineDrawer.input.category": "Catégorie",
  "page.machines.addMachineDrawer.button.submit": "Ajouter à",

  "page.profile.heading.generalInformation": "Informations générales",
  "page.profile.heading.settings": "Paramètres et préférences",
  "page.profile.heading.legal": "Mentions légales",
  "page.profile.heading.feedback": "Retour d'information",

  "page.profile.legal.privacyPolicy": "Politique de confidentialité",
  "page.profile.legal.termsAndConditions": "Termes et conditions",

  "page.profile.feedback.description": "Nous essayons toujours de nous améliorer et nous aimerions avoir vos commentaires.",
  "page.profile.feedback.button.submit": "Donner des commentaires",

  "page.profile.enterpriseId": "Identifiant de l'entreprise",
  "page.profile.userId": "Identifiant de l'utilisateur",
  "page.profile.username": "Nom de l'utilisateur",
  "page.profile.userEmail": "Courriel de l'utilisateur",
  "page.profile.language": "Langue",
  "page.profile.label.systemOfMeasurement": "Système de mesure",
  "page.profile.systemOfMeasurement.metric": "Métrique",
  "page.profile.systemOfMeasurement.imperial": "Impérial",
  "page.profile.label.fieldColoring": "Coloration des champs",
  "page.profile.fieldColoring.simple": "Simple",
  "page.profile.fieldColoring.detailed": "Détaillé",
  "page.profile.logOut": "Déconnexion",

  "page.calendar.week": "Semaine de la semaine",
  "page.calendar.today": "Aujourd'hui est",
  "page.calendar.days.1": "1 jour",
  "page.calendar.days.3": "3 jours",
  "page.calendar.days.7": "1 semaine",
  "page.calendar.iconButton.addTask.ariaLabel": "Ajouter une tâche",
  "page.calendar.button.filters": "Filtres",
  "page.calendar.filters.label.fieldGroups": "Groupes de champs",
  "page.calendar.filters.placeholder.fieldGroups": "Filtrer par groupes de champs",
  "page.calendar.filters.label.fields": "Champs",
  "page.calendar.filters.label.tasks": "Tâches",
  "page.calendar.filters.label.users": "Utilisateurs",
  "page.calendar.filters.label.machines": "Machines et équipements",
  "page.calendar.filters.button.clearFilters": "Effacer les filtres",
  "page.calendar.showFieldConditions": "Afficher les conditions du terrain",
  "page.calendar.colorTasksPerAssociatedUsers.text": "Colorer les tâches des employés affectés",
  "page.calendar.startCalendarOnSelectedDate.text": "Démarrer le calendrier à la date choisie",

  "page.index.heading": "Bienvenue chez KARL !",
  "page.index.mobileWarning.Heading": "Oops, KARL n'est pas encore pris en charge sur mobile !",
  "page.index.mobileWarning.Paragraph": "D'ici là, vous pouvez profiter de KARL sur votre ordinateur ou votre tablette. Inscrivez-vous à notre newsletter pour être informé lorsque notre application mobile est prête 🚀",
  "page.index.login": "Commencer avec nous",

  "page.createEnterprise.heading": "Créer une entreprise",
  "page.createEnterprise.description": "Veuillez saisir les informations suivantes pour commencer.",
  "page.createEnterprise.personalFirstNameInput.label": "Votre prénom",
  "page.createEnterprise.personalLastNameInput.label": "Votre nom de famille",
  "page.createEnterprise.companyNameInput.label": "Nom de l'entreprise",
  "page.createEnterprise.cvrInput.label": "NUMÉRO D'ENREGISTREMENT DE L'ENTREPRISE",
  "page.createEnterprise.buttonText": "Allez-y !",

  "component.AddTaskDrawer.heading": "Ajouter une tâche",
  "component.AddTaskDrawer.buttons.cancel": "Annuler",
  "component.AddTaskDrawer.buttons.add": "Ajouter une tâche",

  "component.ConditionBadge.conditionLevel.optimal": "Optimal",
  "component.ConditionBadge.conditionLevel.good": "Bon",
  "component.ConditionBadge.conditionLevel.moderate": "Moyen",
  "component.ConditionBadge.conditionLevel.poor": "Médiocre",
  "component.ConditionBadge.conditionLevel.critical": "Critique",

  "component.ConditionBadge.typeLabel.precipitation": "Précipitations",
  "component.ConditionBadge.typeLabel.temperature": "Température du sol",
  "component.ConditionBadge.typeLabel.soilTemperature": "Température du sol",
  "component.ConditionBadge.typeLabel.soilMoisture": "Humidité du sol",
  "component.ConditionBadge.typeLabel.clouds": "Nuages",
  "component.ConditionBadge.typeLabel.wind": "Vent",
  "component.ConditionBadge.typeLabel.gusts": "Bourrasques de vent",

  "component.EditTaskDrawer.heading": "Détails",
  "component.EditTaskDrawer.tabEditHeading": "Editer",
  "component.EditTaskDrawer.conditionsNotSupportedForThisTaskType": "Les conditions ne sont pas prises en charge pour ce type de tâche.",
  "component.EditTaskDrawer.tabConditionsHeading": "Conditions de terrain",
  "component.EditTaskDrawer.buttons.delete": "Supprimer",
  "component.EditTaskDrawer.buttons.cancel": "Annuler",
  "component.EditTaskDrawer.buttons.apply": "Appliquer",

  "component.AddFieldDrawerContent.heading.addField": "Ajouter un champ",
  "component.AddFieldDrawerContent.inputLabel.fieldName": "Nom du champ",
  "component.AddFieldDrawerContent.inputLabel.secondaryName": "Nom secondaire",
  "component.AddFieldDrawerContent.inputLabel.fieldGroup": "Groupe",
  "component.AddFieldDrawerContent.label.boundary": "Limite",
  "component.AddFieldDrawerContent.label.noBoundaryDrawn": "Aucune limite dessinée",
  "component.AddFieldDrawerContent.button.drawFieldManually": "Dessiner le champ manuellement",
  "component.AddFieldDrawerContent.button.finish": "Terminer",
  "component.AddFieldDrawerContent.heading.automaticallyDrawnFieldBoundaries": "Limites des champs dessinées automatiquement",
  "component.AddFieldDrawerContent.content.automaticallyDrawnFieldBoyndaries": "Vous pouvez simplement cliquer sur votre champ sur la carte à droite pour que sa limite soit automatiquement dessinée.",
  "component.AddFieldDrawerContent.heading.manuallyDrawnFieldBoundaries": "Limites des champs dessinées manuellement",
  "component.AddFieldDrawerContent.content.manuallyDrawnFieldBoundaries": "Si vous trouvez que les limites automatiquement dessinées sont inexactes, ou non disponibles dans votre région, vous pouvez dessiner la limite de votre champ manuellement. Pour ce faire, cliquez sur le bouton ci-dessus, puis cliquez sur la carte pour dessiner la limite de votre champ. Vous pouvez ajouter autant de points que vous le souhaitez. Pour terminer le dessin, cliquez à nouveau sur le bouton.",
  "component.AddFieldDrawerContent.button.cancel": "Annuler",
  "component.AddFieldDrawerContent.button.add": "Ajouter",

  "component.FieldsDrawerContent.heading": "Champs",
  "component.FieldsDrawerContent.filterByFieldGroupPlaceholder": "Filtrer par groupe",
  "component.FieldsDrawerContent.areaUnit": "ha",
  "component.FieldsDrawerContent.areaUnit__imperial": "acres",
  "component.FieldsDrawerContent.button.addField": "Ajouter un champ",
  "component.FieldsDrawerContent.button.cancel": "Annuler",
  "component.FieldsDrawerContent.toggleIrrigationColoring": "Activer la coloration de l'irrigation",
  "component.FieldsDrawerContent.toggleFieldColoring": "Activer la coloration des champs",

  "component.FieldDrawerContent.heading": "Champ",
  "component.FieldDrawerContent.tab.weather": "Conditions météorologiques",
  "component.FieldDrawerContent.tab.tasks": "Tâches",
  "component.FieldDrawerContent.tab.info": "Info",
  "component.FieldDrawerContent.tab.edit": "Editer",

  "component.FieldDrawerWeatherTabContent.chartName.precipitation": "Précipitations",
  "component.FieldDrawerWeatherTabContent.chartName.airTemperature": "Température de l'air",
  "component.FieldDrawerWeatherTabContent.chartName.soilMoisture": "Humidité du sol",
  "component.FieldDrawerWeatherTabContent.chartName.soilTemperature": "Température du sol",
  "component.FieldDrawerWeatherTabContent.chartName.windGusts": "Vent et rafales",
  "component.FieldDrawerWeatherTabContent.chartName.cloudCoverage": "Couverture nuageuse",
  "component.FieldDrawerWeatherTabContent.chartName.humidity": "Humidité de l'air",
  "component.FieldDrawerWeatherTabContent.series.p1h": "Précipitations (mm/heure)",
  "component.FieldDrawerWeatherTabContent.series.at": "Température de l'air (°C)",
  "component.FieldDrawerWeatherTabContent.series.sm0_5": "Humidité du sol 0-5cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm5_15": "Humidité du sol 5-15cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm15_30": "Humidité du sol 15-30cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm30_60": "Humidité du sol 30-60cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm60_100": "Humidité du sol 60-100cm (%)",
  "component.FieldDrawerWeatherTabContent.series.st0": "Température du sol 0cm (°C)",
  "component.FieldDrawerWeatherTabContent.series.st10": "Température du sol 10cm (°C)",
  "component.FieldDrawerWeatherTabContent.series.gs": "Rafale de vent (m/s)",
  "component.FieldDrawerWeatherTabContent.series.ws": "Vent (m/s)",
  "component.FieldDrawerWeatherTabContent.series.p1h__imperial": "Précipitations (in/hour)",
  "component.FieldDrawerWeatherTabContent.series.at__imperial": "Température de l'air (°F)",
  "component.FieldDrawerWeatherTabContent.series.sm0_5__imperial": 'Humidité du sol 0-1.97" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm5_15__imperial": 'Humidité du sol 1.97-5.91" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm15_30__imperial": 'Humidité du sol 5.91-11.81" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm30_60__imperial": 'Humidité du sol 11.81-23.62" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm60_100__imperial": 'Humidité du sol 23.62-39.37" (%)',
  "component.FieldDrawerWeatherTabContent.series.st0__imperial": 'Température du sol 0" (°F)',
  "component.FieldDrawerWeatherTabContent.series.st10__imperial": 'Température du sol 3.94" (°F)',
  "component.FieldDrawerWeatherTabContent.series.gs__imperial": "Rafales (mph)",
  "component.FieldDrawerWeatherTabContent.series.ws__imperial": "Vent (mph)",
  "component.FieldDrawerWeatherTabContent.series.c": "Couverture nuageuse (%)",
  "component.FieldDrawerWeatherTabContent.series.h": "Humidité de l'air (%)",

  "component.FieldDrawerTasksTabContent.hideCompletedTasks": "Cacher les tâches achevées",
  "component.FieldDrawerTasksTabContent.noTasksFound": "Aucune donnée n'a été trouvée pour ce champ.",

  "component.FieldDrawerInfoTabContent.label.cropType": "Culture",
  "component.FieldDrawerInfoTabContent.label.expectedSowingDate": "Date de semis prévue",
  "component.FieldDrawerInfoTabContent.label.expectedHarvestDate": "Date de récolte prévue",
  "component.FieldDrawerInfoTabContent.label.areaHa": "Superficie (ha)",
  "component.FieldDrawerInfoTabContent.label.areaHa__imperial": "Superficie (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.Marknr": "Numéro du champ",
  "component.FieldDrawerInfoTabContent.customProperty.Afgroede": "Culture",
  "component.FieldDrawerInfoTabContent.customProperty.Markblok": "Bloc de champ",
  "component.FieldDrawerInfoTabContent.customProperty.GBanmeldt": "GB déclarée",
  "component.FieldDrawerInfoTabContent.customProperty.Afgkode": "Code de la culture",
  "component.FieldDrawerInfoTabContent.customProperty.GB": "GB",
  "component.FieldDrawerInfoTabContent.customProperty.Journalnr": "Numéro de journal",
  "component.FieldDrawerInfoTabContent.customProperty.IMK_areal": "Zone IMK",
  "component.FieldDrawerInfoTabContent.customProperty.CVR": "CVR",
  "component.FieldDrawerInfoTabContent.customProperty.area": "Superficie (m2)",
  "component.FieldDrawerInfoTabContent.customProperty.area_acres": "Superficie (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.area_ha": "Superficie (ha)",
  "component.FieldDrawerInfoTabContent.customProperty.area_ha__imperial": "Superficie (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.id": "ID",
  "component.FieldDrawerInfoTabContent.accordion.soil.heading": "Info sur le sol",
  "component.FieldDrawerInfoTabContent.accordion.soil.disclaimer.heading": "Précision des informations sur le sol",
  "component.FieldDrawerInfoTabContent.accordion.soil.disclaimer.label": "Les informations sur le sol sont une fonctionnalité expérimentale et peuvent afficher des mesures inexactes. Les valeurs sont des moyennes estimées de la couche de sol supérieure de 30 cm.",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.soilType": "Type de sol",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.ph": "pH",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.organicCarbonContent": "Teneur en carbone organique",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.nitrogenContent": "Teneur en azote",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.sand": "Sable",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.silt": "Silt",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.clay": "Argile",
  "component.FieldDrawerInfoTabContent.accordion.soil.heading.ph": "pH",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.heading": "Irrigation",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.button.disableAdvancedIrrigationAnalysis": "Désactiver l'analyse avancée de l'irrigation",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.disclaimer.heading": "Analyse avancée de l'irrigation",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.disclaimer.label": "L'analyse avancée de l'irrigation est une fonctionnalité expérimentale et peut afficher des mesures inexactes.",
  "component.FieldDrawerInfoTabContent.buttons.delete": "Supprimer",

  "component.EnableIrrigationAnalysisButton.button.enableAdvancedIrrigationAnalysis": "Activer l'analyse avancée de l'irrigation",
  "component.EnableIrrigationAnalysisButton.modal.heading": "Êtes-vous sûr ?",
  "component.EnableIrrigationAnalysisButton.modal.description": "Veuillez confirmer que vous souhaitez activer l'analyse avancée de l'irrigation.",
  "component.EnableIrrigationAnalysisButton.modal.button.cancel": "Annuler",
  "component.EnableIrrigationAnalysisButton.modal.button.confirm": "Confirmer",

  "component.FieldIrrigationDrawerContent.text.irrigationMetricsNotEnabled": "Les métriques d'irrigation ne sont pas activées pour ce champ.",
  "component.FieldIrrigationDrawerContent.text.noDataAvailableForTheChosenTimePeriod": "Aucune donnée n'est disponible pour la période de temps choisie.",
  "component.FieldIrrigationDrawerContent.chartName.waterDeficit": "Déficit hydrique",
  "component.FieldIrrigationDrawerContent.chartName.evapotranspiration": "Evapotranspiration",
  "component.FieldIrrigationDrawerContent.chartName.soilMoisture": "Humidité du sol",
  "component.FieldIrrigationDrawerContent.series.waterDeficit": "Déficit hydrique",
  "component.FieldIrrigationDrawerContent.series.evapotranspiration": "Evapotranspiration",
  "component.FieldIrrigationDrawerContent.series.soilMoisture": "Humidité du sol",
  "component.FieldIrrigationDrawerContent.series.saturationPoint": "Point de saturation",
  "component.FieldIrrigationDrawerContent.series.fieldCapacity": "Capacité du champ",
  "component.FieldIrrigationDrawerContent.series.refillPoint": "Point de remplissage",
  "component.FieldIrrigationDrawerContent.series.wiltingPoint": "Point de flétrissement",
  "component.FieldIrrigationDrawerContent.select.options.day": "Jour",
  "component.FieldIrrigationDrawerContent.select.options.week": "Semaine",
  "component.FieldIrrigationDrawerContent.select.options.month": "Mois",
  "component.FieldIrrigationDrawerContent.select.options.year": "Année",
  "component.FieldIrrigationDrawerContent.select.options.custom": "Personnalisé",

  "component.Map.evapotranspiration": "Evapotranspiration",
  "component.Map.soilMoisture": "Humidité du sol",

  "component.FieldDrawerEditTabContent.label.fieldName": "Nom du champ",
  "component.FieldDrawerEditTabContent.label.secondaryName": "Nom secondaire",
  "component.FieldDrawerEditTabContent.label.fieldGroup": "Groupe",
  "component.FieldDrawerEditTabContent.label.cropType": "Culture",
  "component.FieldDrawerEditTabContent.label.expectedSowingDate": "Date de semis prévue",
  "component.FieldDrawerEditTabContent.label.expectedHarvestDate": "Date de récolte prévue",
  "component.FieldDrawerEditTabContent.buttons.apply": "Appliquer",
  "component.FieldDrawerEditTabContent.buttons.cancel": "Annuler",

  "component.TaskForm.formLabels.title": "Titre",
  "component.TaskForm.formLabels.task": "Tâche",
  "component.TaskForm.formLabels.crop": "Culture",
  "component.TaskForm.formLabels.tillingDepth": "Profondeur de travail",
  "component.TaskForm.formLabels.dropSize": "Diamètre de chute",
  "component.TaskForm.formLabels.start": "Heure de début",
  "component.TaskForm.formLabels.duration": "Durée du travail",
  "component.TaskForm.formLabels.fields": "Champs",
  "component.TaskForm.formLabels.users": "Utilisateurs",
  "component.TaskForm.formLabels.machinesAndEquipment": "Machines et outils",
  "component.TaskForm.formLabels.notes": "Notes",

  "data.task.harvesting": "Récolte",
  "data.task.seeding": "Semis",
  "data.task.tilling": "Travail du sol",
  "data.task.spraying": "Pulvérisation",
  "data.task.spreading": "Epandage",
  "data.task.mowing": "Fauche",
  "data.task.pressing": "Pressage",
  "data.task.other": "Autre",
  "data.tillingDepth.shallow": "Faible",
  "data.tillingDepth.medium": "moyen",
  "data.tillingDepth.deep": "Profond",
  "data.dropSize.fine": "Fine",
  "data.dropSize.medium": "Moyen",
  "data.dropSize.coarse": "Grossière",
  "data.crop.barley": "Orge",
  "data.crop.wheat": "Blé",
  "data.crop.maize": "Maïs",
  "data.crop.rye": "Seigle",
  "data.crop.oat": "Avoine",
  "data.crop.sorghum": "Sorgho",
  "data.crop.triticale": "Triticale",
  "data.crop.rapeseed": "Colza",
  "data.crop.grass_and_clover": "Graminées et trèfle",
  "data.crop.sunflower": "Tournesol",
  "data.crop.potato": "Pomme de terre",
  "data.crop.beetroot": "Betterave",
  "data.crop.carrot": "Carotte",
  "data.crop.broad_bean": "Fève",
  "data.crop.pea": "Pois",
  "data.crop.common_bean": "Fève",
  "data.crop.soybean": "Fève de soja",
  "data.crop.cotton": "Coton",
  "data.crop.rice": "Riz",
  "data.crop.sugarcane": "Canne à sucre",

  "data.field.soilType.Sand": "Sable",
  "data.field.soilType.Loamy sand": "Sable limoneux",
  "data.field.soilType.Sandy loam": "Limons sableux",
  "data.field.soilType.Loam": "Limons",
  "data.field.soilType.Silty loam": "Limons argileux",
  "data.field.soilType.Silt": "Silt",
  "data.field.soilType.Clay loam": "Argiles limoneuses",
  "data.field.soilType.Sandy clay loam": "Argiles limoneuses sableuses",
  "data.field.soilType.Silty clay loam": "Argiles limoneuses argileuses",
  "data.field.soilType.Sandy clay": "Argiles sableuses",
  "data.field.soilType.Silty clay": "Argiles argileuses",
  "data.field.soilType.Clay": "Argiles",

  "conditions.descriptions.harvesting.temperature.high.optimal": "",
  "conditions.descriptions.harvesting.temperature.high.good": "",
  "conditions.descriptions.harvesting.temperature.high.moderate": "",
  "conditions.descriptions.harvesting.temperature.high.poor": "",
  "conditions.descriptions.harvesting.temperature.high.critical": "",
  "conditions.descriptions.harvesting.temperature.low.optimal": "",
  "conditions.descriptions.harvesting.temperature.low.good": "",
  "conditions.descriptions.harvesting.temperature.low.moderate": "",
  "conditions.descriptions.harvesting.temperature.low.poor": "",
  "conditions.descriptions.harvesting.temperature.low.critical": "",
  "conditions.descriptions.harvesting.precipitation.high.optimal": "(Pluie minime à nulle) Le tracteur et l'équipement sont peu ou pas gênés.",
  "conditions.descriptions.harvesting.precipitation.high.good": "(Légère pluie) Le tracteur et l'équipement sont peu gênés.",
  "conditions.descriptions.harvesting.precipitation.high.moderate": "(Légère pluie) Il y a un petit obstacle pour le tracteur et l'équipement",
  "conditions.descriptions.harvesting.precipitation.high.poor": "(Pluie légère) Il y a un léger obstacle pour le tracteur et l'équipement.",
  "conditions.descriptions.harvesting.precipitation.high.critical": "(Pluie légère au-dessus du seuil) Le tracteur et l'équipement sont gênés et il y a un risque de maculage du sol et de perte de traction.",
  "conditions.descriptions.harvesting.precipitation.low.optimal": "(Pluie minime à nulle) Entrave minime à nulle pour le tracteur et l'équipement.",
  "conditions.descriptions.harvesting.precipitation.low.good": "",
  "conditions.descriptions.harvesting.precipitation.low.moderate": "",
  "conditions.descriptions.harvesting.precipitation.low.poor": "",
  "conditions.descriptions.harvesting.precipitation.low.critical": "",
  "conditions.descriptions.harvesting.soil_temperature.high.optimal": "",
  "conditions.descriptions.harvesting.soil_temperature.high.good": "",
  "conditions.descriptions.harvesting.soil_temperature.high.moderate": "",
  "conditions.descriptions.harvesting.soil_temperature.high.poor": "",
  "conditions.descriptions.harvesting.soil_temperature.high.critical": "",
  "conditions.descriptions.harvesting.soil_temperature.low.optimal": "",
  "conditions.descriptions.harvesting.soil_temperature.low.good": "",
  "conditions.descriptions.harvesting.soil_temperature.low.moderate": "",
  "conditions.descriptions.harvesting.soil_temperature.low.poor": "",
  "conditions.descriptions.harvesting.soil_temperature.low.critical": "",
  "conditions.descriptions.harvesting.soil_moisture.high.optimal": "(Sol très sec au point d'observation) Risque minime de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.harvesting.soil_moisture.high.good": "(Sol plus sec) Il y a un faible risque de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.harvesting.soil_moisture.high.moderate": "(sol sec) Il existe un certain risque de compactage du sol, de dommages au sol et d'enlisement du tracteur.",
  "conditions.descriptions.harvesting.soil_moisture.high.poor": "(Sol humide) Risque élevé de compactage du sol, d'endommagement du sol et d'enlisement du tracteur",
  "conditions.descriptions.harvesting.soil_moisture.high.critical": "(Sol très humide à la capacité du champ) Risque le plus élevé de tassement du sol, de dommages au sol et de blocage du tracteur.",
  "conditions.descriptions.harvesting.soil_moisture.low.optimal": "(Sol très sec au point d'observation) Risque minime de tassement du sol, d'endommagement du sol et de blocage du tracteur.",
  "conditions.descriptions.harvesting.soil_moisture.low.good": "",
  "conditions.descriptions.harvesting.soil_moisture.low.moderate": "",
  "conditions.descriptions.harvesting.soil_moisture.low.poor": "",
  "conditions.descriptions.harvesting.soil_moisture.low.critical": "",
  "conditions.descriptions.harvesting.wind.high.optimal": "(Calme) Il n'y a pas de risque de dérive des grains et de perte de rendement.",
  "conditions.descriptions.harvesting.wind.high.good": "(Légère brise) Risque minime de dérive des grains et de perte de rendement.",
  "conditions.descriptions.harvesting.wind.high.moderate": "(Brise légère) Le risque de dérive des grains et de perte de rendement est minime.",
  "conditions.descriptions.harvesting.wind.high.poor": "(Brise modérée) Le risque de déviation des grains et de perte de rendement est minime.",
  "conditions.descriptions.harvesting.wind.high.critical": "(Vent suffisamment fort pour soulever un grain de blé) Risque important de dérive des grains et de perte de rendement",
  "conditions.descriptions.harvesting.wind.low.optimal": "",
  "conditions.descriptions.harvesting.wind.low.good": "",
  "conditions.descriptions.harvesting.wind.low.moderate": "",
  "conditions.descriptions.harvesting.wind.low.poor": "",
  "conditions.descriptions.harvesting.wind.low.critical": "",
  "conditions.descriptions.sowing.temperature.high.optimal": "(Températures optimales) La croissance et le développement des plantes seront les plus rapides.",
  "conditions.descriptions.sowing.temperature.high.good": "(Températures proches de l'optimum) La croissance et le développement de la plante seront adéquats.",
  "conditions.descriptions.sowing.temperature.high.moderate": "(Températures modérément élevées) La croissance et le développement de la plante sont lents.",
  "conditions.descriptions.sowing.temperature.high.poor": "(Températures très élevées) La croissance et le développement de la plante seront retardés.",
  "conditions.descriptions.sowing.temperature.high.critical": "(Températures létales pour la culture) Les semis se flétrissent et meurent.",
  "conditions.descriptions.sowing.temperature.low.optimal": "(Températures optimales) La croissance et le développement des plantes sont les plus rapides.",
  "conditions.descriptions.sowing.temperature.low.good": "(Températures proches des températures optimales) La croissance et le développement des plantes sont suffisants.",
  "conditions.descriptions.sowing.temperature.low.moderate": "(Températures modérément basses) La croissance et le développement de la plante seront lents.",
  "conditions.descriptions.sowing.temperature.low.poor": "(Températures très basses) La croissance et le développement des plantes seront très lents, voire nuls, et il existe un risque important de dégâts dus au gel.",
  "conditions.descriptions.sowing.temperature.low.critical": "(Températures mortelles pour la culture) Les semis se flétrissent et meurent.",
  "conditions.descriptions.sowing.precipitation.high.optimal": "(Pluie minime ou inexistante) Il y a peu ou pas d'obstruction pour le tracteur et l'équipement.",
  "conditions.descriptions.sowing.precipitation.high.good": "(Pluie légère) Le tracteur et l'équipement sont peu gênés.",
  "conditions.descriptions.sowing.precipitation.high.moderate": "(Pluie légère) Il y a une légère obstruction au tracteur et à l'équipement.",
  "conditions.descriptions.sowing.precipitation.high.poor": "(Pluie légère) Il y a un léger obstacle pour le tracteur et l'équipement.",
  "conditions.descriptions.sowing.precipitation.high.critical": "(Pluie légère au-dessus du seuil) Il y a une gêne pour le tracteur et l'équipement et un risque de maculage du sol et de perte de traction.",
  "conditions.descriptions.sowing.precipitation.low.optimal": "(Pluie minime à nulle) Il y a une gêne minime à nulle pour le tracteur et l'équipement.",
  "conditions.descriptions.sowing.precipitation.low.good": "",
  "conditions.descriptions.sowing.precipitation.low.moderate": "",
  "conditions.descriptions.sowing.precipitation.low.poor": "",
  "conditions.descriptions.sowing.precipitation.low.critical": "",
  "conditions.descriptions.sowing.soil_temperature.high.optimal": "(Températures optimales) Les graines germent et se développent le plus rapidement.",
  "conditions.descriptions.sowing.soil_temperature.high.good": "(Températures proches de l'optimum) La germination et le développement des semences sont adéquats.",
  "conditions.descriptions.sowing.soil_temperature.high.moderate": "(Températures modérément élevées) La germination et le développement des semences sont lents.",
  "conditions.descriptions.sowing.soil_temperature.high.poor": "(Températures très élevées) La germination et le développement des graines sont très lents, voire nuls, et les graines risquent de mourir.",
  "conditions.descriptions.sowing.soil_temperature.high.critical": "(Températures extrêmement élevées) La germination et le développement des graines sont inexistants et les graines risquent de mourir.",
  "conditions.descriptions.sowing.soil_temperature.low.optimal": "(Températures optimales) La germination et le développement des graines sont les plus rapides.",
  "conditions.descriptions.sowing.soil_temperature.low.good": "(Températures proches de l'optimum) La germination et le développement des semences sont suffisants.",
  "conditions.descriptions.sowing.soil_temperature.low.moderate": "(Températures modérément basses) La germination et le développement des graines sont lents.",
  "conditions.descriptions.sowing.soil_temperature.low.poor": "(Températures très basses) La germination et le développement des semences sont très lents, voire nuls.",
  "conditions.descriptions.sowing.soil_temperature.low.critical": "(Températures extrêmement basses) Il n'y a pas de germination et de développement des graines.",
  "conditions.descriptions.sowing.soil_moisture.high.optimal": "(Sol à la moitié de sa capacité) - Le sol est suffisamment humide pour permettre une germination optimale des graines et suffisamment sec pour éviter le compactage par le tracteur.",
  "conditions.descriptions.sowing.soil_moisture.high.good": "(Sol presque à la moitié de sa capacité) - Le potentiel de germination des semences est optimal, mais le risque de compactage du sol par le tracteur augmente.",
  "conditions.descriptions.sowing.soil_moisture.high.moderate": "(Sol humide) Le potentiel de germination des semences est suffisant, mais le risque de tassement du sol par le tracteur est important.",
  "conditions.descriptions.sowing.soil_moisture.high.poor": "(Sol humide très proche de la capacité du champ) Le potentiel de germination des semences est réduit pour certaines cultures et le risque de tassement du sol par le tracteur est élevé.",
  "conditions.descriptions.sowing.soil_moisture.high.critical": "(Sol humide à la capacité du champ) Le potentiel de germination des semences est réduit pour certaines cultures et le risque de compactage du sol par le tracteur est très élevé.",
  "conditions.descriptions.sowing.soil_moisture.low.optimal": "(Sol à la moitié de sa capacité) - Le sol est suffisamment humide pour permettre une germination optimale des semences et suffisamment sec pour éviter le compactage par le tracteur.",
  "conditions.descriptions.sowing.soil_moisture.low.good": "(Sol presque à la moitié de sa capacité) - Le potentiel de germination des semences est suffisant.",
  "conditions.descriptions.sowing.soil_moisture.low.moderate": "(Sol sec) - Le potentiel de germination des semences est faible",
  "conditions.descriptions.sowing.soil_moisture.low.poor": "(sol très sec, proche du point de vue) Le potentiel de germination des semences est très faible.",
  "conditions.descriptions.sowing.soil_moisture.low.critical": "(Sol très sec, au point de flétrissement) Le potentiel de germination des semences est le plus faible.",
  "conditions.descriptions.sowing.wind.high.optimal": "(Calme) Il n'y a pas de risque de déplacement des graines et de non-conformité lors du semis.",
  "conditions.descriptions.sowing.wind.high.good": "(Brise légère) Le risque de déplacement des graines et de non-conformité lors du semis est minime.",
  "conditions.descriptions.sowing.wind.high.moderate": "(Brise légère) Le risque de déplacement des graines et d'irrégularités lors du semis est minime.",
  "conditions.descriptions.sowing.wind.high.poor": "(Brise modérée) Le risque de déplacement des graines et d'irrégularités lors des semis est minime.",
  "conditions.descriptions.sowing.wind.high.critical": "(Vent assez fort pour soulever un grain de blé) Risque important de déplacement des graines et d'irrégularités lors des semis.",
  "conditions.descriptions.sowing.wind.low.optimal": "(Vent calme) Il n'y a pas de risque de déplacement des graines et d'irrégularités lors des semis.",
  "conditions.descriptions.sowing.wind.low.good": "",
  "conditions.descriptions.sowing.wind.low.moderate": "",
  "conditions.descriptions.sowing.wind.low.poor": "",
  "conditions.descriptions.sowing.wind.low.critical": "",
  "conditions.descriptions.mowing.temperature.high.optimal": "",
  "conditions.descriptions.mowing.temperature.high.good": "",
  "conditions.descriptions.mowing.temperature.high.moderate": "",
  "conditions.descriptions.mowing.temperature.high.poor": "",
  "conditions.descriptions.mowing.temperature.high.critical": "",
  "conditions.descriptions.mowing.temperature.low.optimal": "",
  "conditions.descriptions.mowing.temperature.low.good": "",
  "conditions.descriptions.mowing.temperature.low.moderate": "",
  "conditions.descriptions.mowing.temperature.low.poor": "",
  "conditions.descriptions.mowing.temperature.low.critical": "",
  "conditions.descriptions.mowing.precipitation.high.optimal": "(Peu ou pas de pluie) Le tracteur et l'équipement sont peu ou pas gênés.",
  "conditions.descriptions.mowing.precipitation.high.good": "(Légère pluie) Le tracteur et le matériel sont légèrement gênés.",
  "conditions.descriptions.mowing.precipitation.high.moderate": "(Pluie légère) Il y a un petit obstacle pour le tracteur et l'équipement.",
  "conditions.descriptions.mowing.precipitation.high.poor": "(Pluie légère) Il y a un petit obstacle pour le tracteur et l'équipement",
  "conditions.descriptions.mowing.precipitation.high.critical": "(Pluie légère au-dessus du seuil) Il y a une gêne pour le tracteur et le matériel et un risque de maculage du sol et de perte de traction.",
  "conditions.descriptions.mowing.precipitation.low.optimal": "(Peu ou pas de pluie) Il y a peu ou pas de gêne pour le tracteur et l'équipement.",
  "conditions.descriptions.mowing.precipitation.low.good": "",
  "conditions.descriptions.mowing.precipitation.low.moderate": "",
  "conditions.descriptions.mowing.precipitation.low.poor": "",
  "conditions.descriptions.mowing.precipitation.low.critical": "",
  "conditions.descriptions.mowing.soil_temperature.high.optimal": "",
  "conditions.descriptions.mowing.soil_temperature.high.good": "",
  "conditions.descriptions.mowing.soil_temperature.high.moderate": "",
  "conditions.descriptions.mowing.soil_temperature.high.poor": "",
  "conditions.descriptions.mowing.soil_temperature.high.critical": "",
  "conditions.descriptions.mowing.soil_temperature.low.optimal": "",
  "conditions.descriptions.mowing.soil_temperature.low.good": "",
  "conditions.descriptions.mowing.soil_temperature.low.moderate": "",
  "conditions.descriptions.mowing.soil_temperature.low.poor": "",
  "conditions.descriptions.mowing.soil_temperature.low.critical": "",
  "conditions.descriptions.mowing.soil_moisture.high.optimal": "(Sol très sec au point d'observation) Risque minime de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.mowing.soil_moisture.high.good": "(Sol plus sec) Il y a un faible risque de compactage du sol, de dommages au sol et d'enlisement du tracteur.",
  "conditions.descriptions.mowing.soil_moisture.high.moderate": "(sol sec) Risque de tassement du sol, de dommages au sol et d'enlisement du tracteur.",
  "conditions.descriptions.mowing.soil_moisture.high.poor": "(Sol humide) Risque élevé de tassement du sol, de dommages au sol et d'enlisement du tracteur",
  "conditions.descriptions.mowing.soil_moisture.high.critical": "(Sol très humide à la capacité du champ) Risque le plus élevé de compactage du sol, de dommages au sol et de blocage du tracteur",
  "conditions.descriptions.mowing.soil_moisture.low.optimal": "(Sol très sec au point d'observation) Risque minime de tassement du sol, d'endommagement du sol et de blocage du tracteur.",
  "conditions.descriptions.mowing.soil_moisture.low.good": "",
  "conditions.descriptions.mowing.soil_moisture.low.moderate": "",
  "conditions.descriptions.mowing.soil_moisture.low.poor": "",
  "conditions.descriptions.mowing.soil_moisture.low.critical": "",
  "conditions.descriptions.mowing.wind.high.optimal": "(Calme) Il n'y a pas de risque de dérive de la paille et des résidus.",
  "conditions.descriptions.mowing.wind.high.good": "(Air léger) Le risque d'arrachage de la paille et des résidus est minime.",
  "conditions.descriptions.mowing.wind.high.moderate": "(brise légère) Risque minime d'arrachage de la paille et des résidus.",
  "conditions.descriptions.mowing.wind.high.poor": "(Brise modérée) - Il y a un certain risque que la paille et les résidus partent à la dérive.",
  "conditions.descriptions.mowing.wind.high.critical": "(Brise fraîche) Les feuilles commencent à se balancer et il y a un risque élevé que la paille et les résidus flottent.",
  "conditions.descriptions.mowing.wind.low.optimal": "(Vent calme) Il n'y a pas de risque de dérive de la paille et des résidus.",
  "conditions.descriptions.mowing.wind.low.good": "",
  "conditions.descriptions.mowing.wind.low.moderate": "",
  "conditions.descriptions.mowing.wind.low.poor": "",
  "conditions.descriptions.mowing.wind.low.critical": "",
  "conditions.descriptions.spraying.fine.temperature.high.optimal": "",
  "conditions.descriptions.spraying.fine.temperature.high.good": "",
  "conditions.descriptions.spraying.fine.temperature.high.moderate": "",
  "conditions.descriptions.spraying.fine.temperature.high.poor": "",
  "conditions.descriptions.spraying.fine.temperature.high.critical": "",
  "conditions.descriptions.spraying.fine.temperature.low.optimal": "",
  "conditions.descriptions.spraying.fine.temperature.low.good": "",
  "conditions.descriptions.spraying.fine.temperature.low.moderate": "",
  "conditions.descriptions.spraying.fine.temperature.low.poor": "",
  "conditions.descriptions.spraying.fine.temperature.low.critical": "",
  "conditions.descriptions.spraying.fine.precipitation.high.optimal": "(Pas de pluie) Le tracteur et l'équipement ne sont pas gênés et il n'y a pas de risque d'écoulement du produit.",
  "conditions.descriptions.spraying.fine.precipitation.high.good": "(Peu de pluie) Le tracteur et l'équipement sont peu gênés et le risque d'écoulement du produit est minime.",
  "conditions.descriptions.spraying.fine.precipitation.high.moderate": "(Peu de pluie) Il y a peu d'obstacles pour le tracteur et l'équipement et peu de risque d'écoulement du produit.",
  "conditions.descriptions.spraying.fine.precipitation.high.poor": "(Pluie légère) Il y a une obstruction minimale au tracteur et à l'équipement et un risque minimal de ruissellement du produit.",
  "conditions.descriptions.spraying.fine.precipitation.high.critical": "(Pluie légère au-dessus du seuil) Le tracteur et l'équipement sont gênés, le sol risque d'être compacté et souillé, et le produit risque de s'écouler.",
  "conditions.descriptions.spraying.fine.precipitation.low.optimal": "(Pas de pluie) Il n'y a pas d'obstacles pour le tracteur et l'équipement et pas de risque de ruissellement du produit.",
  "conditions.descriptions.spraying.fine.precipitation.low.good": "",
  "conditions.descriptions.spraying.fine.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.fine.precipitation.low.poor": "",
  "conditions.descriptions.spraying.fine.precipitation.low.critical": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.fine.soil_moisture.high.optimal": "(Sol très sec) Risque minime de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spraying.fine.soil_moisture.high.good": "(Sol plus sec) Il y a un faible risque de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spraying.fine.soil_moisture.high.moderate": "(Sol sec) Il existe un certain risque de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spraying.fine.soil_moisture.high.poor": "(Sol humide) Risque élevé de compactage du sol, d'endommagement du sol et d'enlisement du tracteur",
  "conditions.descriptions.spraying.fine.soil_moisture.high.critical": "(Sol très humide à la capacité du champ) Risque élevé de tassement du sol, de dommages au sol et de blocage du tracteur",
  "conditions.descriptions.spraying.fine.soil_moisture.low.optimal": "(Sol très sec) Risque minime de tassement du sol, d'endommagement du sol et de blocage du tracteur.",
  "conditions.descriptions.spraying.fine.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.fine.wind.high.optimal": "(Air léger au-dessus du seuil) Très faible risque d'inversion de température et très faible risque de dérive de pulvérisation.",
  "conditions.descriptions.spraying.fine.wind.high.good": "(Air léger au-dessus du seuil) -> Très faible risque d'inversion de température et faible risque de dérive de pulvérisation.",
  "conditions.descriptions.spraying.fine.wind.high.moderate": "Moyenne - Brise légère -> Risque très faible d'inversion de température et risque de dérive de la pulvérisation.",
  "conditions.descriptions.spraying.fine.wind.high.poor": "(Brise légère) Risque élevé de dérive des embruns",
  "conditions.descriptions.spraying.fine.wind.high.critical": "(Brise légère et plus) Risque très élevé de dérive de pulvérisation.",
  "conditions.descriptions.spraying.fine.wind.low.optimal": "(Air léger au-dessus du seuil) Il y a un très faible risque d'inversion de température et un très faible risque de dérive des embruns.",
  "conditions.descriptions.spraying.fine.wind.low.good": "(Air léger) -> Il y a un certain risque d'inversion de température et un risque de dérive des embruns.",
  "conditions.descriptions.spraying.fine.wind.low.moderate": "(Air léger) -> Il y a un certain risque d'inversion de température et un risque de dérive des embruns.",
  "conditions.descriptions.spraying.fine.wind.low.poor": "(Air léger) -> Il y a un certain risque d'inversion de température et un risque de dérive des embruns.",
  "conditions.descriptions.spraying.fine.wind.low.critical": "(Air calme) Il y a un risque élevé d'inversion de température et un risque élevé de dérive des embruns.",
  "conditions.descriptions.spraying.medium.temperature.high.optimal": "",
  "conditions.descriptions.spraying.medium.temperature.high.good": "",
  "conditions.descriptions.spraying.medium.temperature.high.moderate": "",
  "conditions.descriptions.spraying.medium.temperature.high.poor": "",
  "conditions.descriptions.spraying.medium.temperature.high.critical": "",
  "conditions.descriptions.spraying.medium.temperature.low.optimal": "",
  "conditions.descriptions.spraying.medium.temperature.low.good": "",
  "conditions.descriptions.spraying.medium.temperature.low.moderate": "",
  "conditions.descriptions.spraying.medium.temperature.low.poor": "",
  "conditions.descriptions.spraying.medium.temperature.low.critical": "",
  "conditions.descriptions.spraying.medium.precipitation.high.optimal": "(Pas de pluie) Il n'y a pas d'obstruction pour le tracteur et l'équipement et pas de risque de ruissellement du produit.",
  "conditions.descriptions.spraying.medium.precipitation.high.good": "(Peu de pluie) Il y a un minimum d'obstruction pour le tracteur et l'équipement et un minimum de risque de ruissellement du produit.",
  "conditions.descriptions.spraying.medium.precipitation.high.moderate": "(Petite pluie) Il y a une obstruction minimale au tracteur et à l'équipement et un risque minimal de ruissellement du produit.",
  "conditions.descriptions.spraying.medium.precipitation.high.poor": "(Pluie légère) Il y a une obstruction minimale au tracteur et à l'équipement et un risque minimal de ruissellement du produit.",
  "conditions.descriptions.spraying.medium.precipitation.high.critical": "(Pluie légère au-dessus du seuil) Le tracteur et l'équipement sont gênés, le sol risque d'être compacté et maculé, et le produit risque de s'écouler.",
  "conditions.descriptions.spraying.medium.precipitation.low.optimal": "(Pas de pluie) Il n'y a pas d'obstacles pour le tracteur et l'équipement et pas de risque de ruissellement du produit.",
  "conditions.descriptions.spraying.medium.precipitation.low.good": "",
  "conditions.descriptions.spraying.medium.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.medium.precipitation.low.poor": "",
  "conditions.descriptions.spraying.medium.precipitation.low.critical": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.medium.soil_moisture.high.optimal": "(Sol très sec) Risque minime de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spraying.medium.soil_moisture.high.good": "(Sol plus sec) Il y a un faible risque de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spraying.medium.soil_moisture.high.moderate": "(Sol sec) Il existe un certain risque de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spraying.medium.soil_moisture.high.poor": "(Sol humide) Risque élevé de compactage du sol, d'endommagement du sol et d'enlisement du tracteur",
  "conditions.descriptions.spraying.medium.soil_moisture.high.critical": "(Sol très humide à la capacité du champ) Risque élevé de tassement du sol, de dommages au sol et de blocage du tracteur",
  "conditions.descriptions.spraying.medium.soil_moisture.low.optimal": "(Sol très sec) Risque minime de tassement du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spraying.medium.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.medium.wind.high.optimal": "(Air léger) Très faible risque d'inversion de température et faible risque de dérive de pulvérisation",
  "conditions.descriptions.spraying.medium.wind.high.good": "(Air léger) Très faible risque d'inversion de température et faible risque de dérive de pulvérisation.",
  "conditions.descriptions.spraying.medium.wind.high.moderate": "(Brise légère) Risque très faible d'inversion de température et certain risque de dérive des embruns.",
  "conditions.descriptions.spraying.medium.wind.high.poor": "(Brise légère) Risque élevé de dérive des embruns.",
  "conditions.descriptions.spraying.medium.wind.high.critical": "(Brise légère) Risque très élevé de dérive des embruns.",
  "conditions.descriptions.spraying.medium.wind.low.optimal": "(Air léger) Risque très faible d'inversion de température et faible risque de dérive des embruns.",
  "conditions.descriptions.spraying.medium.wind.low.good": "(Air calme à léger) Il y a un risque d'inversion de température mais un faible risque de dérive des embruns.",
  "conditions.descriptions.spraying.medium.wind.low.moderate": "",
  "conditions.descriptions.spraying.medium.wind.low.poor": "",
  "conditions.descriptions.spraying.medium.wind.low.critical": "",
  "conditions.descriptions.spraying.coarse.temperature.high.optimal": "",
  "conditions.descriptions.spraying.coarse.temperature.high.good": "",
  "conditions.descriptions.spraying.coarse.temperature.high.moderate": "",
  "conditions.descriptions.spraying.coarse.temperature.high.poor": "",
  "conditions.descriptions.spraying.coarse.temperature.high.critical": "",
  "conditions.descriptions.spraying.coarse.temperature.low.optimal": "",
  "conditions.descriptions.spraying.coarse.temperature.low.good": "",
  "conditions.descriptions.spraying.coarse.temperature.low.moderate": "",
  "conditions.descriptions.spraying.coarse.temperature.low.poor": "",
  "conditions.descriptions.spraying.coarse.temperature.low.critical": "",
  "conditions.descriptions.spraying.coarse.precipitation.high.optimal": "(Pas de pluie) Il n'y a pas d'obstruction pour le tracteur et l'équipement et pas de risque de ruissellement du produit.",
  "conditions.descriptions.spraying.coarse.precipitation.high.good": "(Peu de pluie) Il y a un minimum d'obstruction pour le tracteur et l'équipement et un minimum de risque de ruissellement du produit.",
  "conditions.descriptions.spraying.coarse.precipitation.high.moderate": "(Petite pluie) Il y a un minimum d'obstruction au tracteur et à l'équipement et un minimum de risque d'écoulement du produit.",
  "conditions.descriptions.spraying.coarse.precipitation.high.poor": "(Pluie légère) Il y a une obstruction minimale au tracteur et à l'équipement et un risque minimal de ruissellement du produit.",
  "conditions.descriptions.spraying.coarse.precipitation.high.critical": "(Pluie légère au-dessus du seuil) Le tracteur et l'équipement sont gênés, le sol risque d'être compacté et maculé, et le produit risque de s'écouler.",
  "conditions.descriptions.spraying.coarse.precipitation.low.optimal": "(Pas de pluie) Il n'y a pas d'obstacles pour le tracteur et l'équipement et pas de risque de ruissellement du produit.",
  "conditions.descriptions.spraying.coarse.precipitation.low.good": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.poor": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.critical": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.optimal": "(Sol très sec) Risque minime de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.good": "(Sol plus sec) Il y a un faible risque de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.moderate": "(Sol sec) Il existe un certain risque de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.poor": "(Sol humide) Risque élevé de compactage du sol, d'endommagement du sol et d'enlisement du tracteur",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.critical": "(Sol très humide à la capacité du champ) Risque élevé de tassement du sol, de dommages au sol et de blocage du tracteur",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.optimal": "(Sol très sec) Risque minime de compactage du sol, d'endommagement du sol et de blocage du tracteur.",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.coarse.wind.high.optimal": "(Air léger) Risque très faible d'inversion de température et risque très faible ou nul de dérive de pulvérisation.",
  "conditions.descriptions.spraying.coarse.wind.high.good": "(Air léger) Très faible risque d'inversion de température et faible risque de dérive de pulvérisation.",
  "conditions.descriptions.spraying.coarse.wind.high.moderate": "(Brise légère) Très faible risque d'inversion de température et faible risque de dérive des embruns.",
  "conditions.descriptions.spraying.coarse.wind.high.poor": "(Brise légère) Un certain risque de dérive des embruns",
  "conditions.descriptions.spraying.coarse.wind.high.critical": "(Brise modérée) Risque élevé de dérive de pulvérisation",
  "conditions.descriptions.spraying.coarse.wind.low.optimal": "(Air léger) Le risque d'inversion de température est très faible et le risque de dérive des embruns est très faible ou nul.",
  "conditions.descriptions.spraying.coarse.wind.low.good": "(Air calme à léger) Il y a un risque d'inversion de température mais un risque très faible de dérive des embruns.",
  "conditions.descriptions.spraying.coarse.wind.low.moderate": "",
  "conditions.descriptions.spraying.coarse.wind.low.poor": "",
  "conditions.descriptions.spraying.coarse.wind.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.optimal": "(Pluie minime à nulle) Il y a peu ou pas d'obstruction pour le tracteur et l'équipement.",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.good": "(Pluie légère) Le tracteur et l'équipement sont légèrement gênés.",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.moderate": "(Pluie légère) Il y a un petit obstacle pour le tracteur et l'équipement",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.poor": "(Pluie légère) Il y a un léger obstacle pour le tracteur et l'équipement.",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.critical": "(Pluie légère au-dessus du seuil) Le tracteur et l'équipement sont gênés et il y a un risque de maculage du sol et de perte de traction.",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.optimal": "(Pluie minime à nulle) Il y a peu ou pas d'obstacle pour le tracteur et l'équipement.",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.optimal": "(Sol très sec) Risque minime de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.good": "(Sol plus sec) Faible risque de tassement du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.moderate": "(Sol sec) Il existe un certain risque de compactage du sol, de dommages au sol et d'enlisement du tracteur.",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.poor": "(Sol humide) Risque élevé de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.critical": "(Sol très humide à la capacité du champ) Risque le plus élevé de compactage du sol, de dommages au sol et de blocage du tracteur.",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.optimal": "(Sol très sec) Risque minime de tassement du sol, d'endommagement du sol et de blocage du tracteur.",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.optimal": "(calme) Il n'y a pas de risque de dérive du produit et l'efficacité de l'application est maximale.",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.good": "(Air léger) Le risque de dérive du produit est minime et l'efficacité de l'application est élevée.",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.moderate": "(Brise légère) Il y a un risque de dérive du produit et l'efficacité de l'application peut être réduite.",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.poor": "(Brise modérée) Le risque de dérive du produit est élevé et l'efficacité de l'application est réduite.",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.critical": "(Brise fraîche) Le risque de dérive du produit est très élevé et l'efficacité de l'application sera fortement réduite.",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.optimal": "(Vent calme) Il n'y a pas de risque de dérive du produit et l'efficacité de l'application est maximale.",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.optimal": "(Peu ou pas de pluie) Il y a peu ou pas d'obstruction pour le tracteur et l'équipement et le risque de dérive du produit est minime.",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.good": "(Pluie légère) Le tracteur et l'équipement sont légèrement gênés et il y a un léger risque de fuite du produit.",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.moderate": "(Pluie légère) Il y a un petit obstacle au tracteur et à l'équipement et un petit risque de fuite du produit.",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.poor": "(Pluie légère) Il y a un petit obstacle pour le tracteur et l'équipement et un petit risque de lessivage des produits.",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.critical": "(Pluie légère au-dessus du seuil) Il y a une gêne pour le tracteur et l'équipement, un risque de maculage du sol et de perte de traction et un risque élevé de lessivage du produit.",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.optimal": "(Peu ou pas de pluie) Gêne minime à nulle pour le tracteur et le matériel et risque minime de lessivage des produits.",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.optimal": "(Sol très sec) Risque minime de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.good": "(Sol plus sec) Il y a un faible risque de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.moderate": "(Sol sec) Il existe un certain risque de compactage du sol, de dommages au sol et d'enlisement du tracteur.",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.poor": "(Sol humide) Risque élevé de compactage du sol, d'endommagement du sol et de blocage du tracteur.",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.critical": "(Sol très humide à la capacité du champ) Risque élevé de compactage du sol, d'endommagement du sol et de blocage du tracteur.",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.optimal": "(Sol très sec) Risque minime de tassement du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.optimal": "(Calme) Il n'y a pas de risque de dérive du produit et l'efficacité de l'application est maximale.",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.good": "(Air léger) Le risque de dérive du produit est minime et l'efficacité de l'application est élevée.",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.moderate": "(Brise légère) Il y a un risque de dérive du produit et l'efficacité de l'application peut être réduite.",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.poor": "(Brise légère) Le risque de dérive du produit est élevé et l'efficacité de l'application est réduite.",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.critical": "(Brise modérée ou plus) Le risque de dérive du produit est très élevé et l'efficacité de l'application sera fortement réduite.",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.optimal": "(Vent calme) Il n'y a pas de risque de dérive du produit et l'efficacité de l'application est maximale.",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.optimal": "(Peu ou pas de pluie) Il y a peu ou pas d'obstruction pour le tracteur et l'équipement et le risque de dérive du produit est minime.",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.good": "(Pluie légère) Le tracteur et l'équipement sont légèrement gênés et il y a un léger risque de fuite du produit.",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.moderate": "(Pluie légère) Il y a un petit obstacle au tracteur et à l'équipement et un petit risque de fuite du produit.",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.poor": "(Pluie légère) Il y a un petit obstacle pour le tracteur et l'équipement et un petit risque de lixiviation des produits.",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.critical": "(Pluie légère au-dessus du seuil) Il y a une gêne pour le tracteur et l'équipement, un risque de maculage du sol et de perte de traction et un risque élevé de lessivage du produit.",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.optimal": "(Peu ou pas de pluie) Gêne minime à nulle pour le tracteur et le matériel et risque minime de lessivage des produits.",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.optimal": "(Sol très sec) Risque minime de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.good": "(Sol plus sec) Il y a un faible risque de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.moderate": "(Sol sec) Il existe un certain risque de compactage du sol, de dommages au sol et d'enlisement du tracteur.",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.poor": "(Sol humide) Risque élevé de compactage du sol, d'endommagement du sol et de blocage du tracteur.",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.critical": "(Sol très humide à la capacité du champ) Risque élevé de compactage du sol, d'endommagement du sol et de blocage du tracteur.",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.optimal": "(Sol très sec) Risque minime de compactage du sol, d'endommagement du sol et de blocage du tracteur.",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.critical": "",
  "conditions.descriptions.tilling.temperature.high.optimal": "",
  "conditions.descriptions.tilling.temperature.high.good": "",
  "conditions.descriptions.tilling.temperature.high.moderate": "",
  "conditions.descriptions.tilling.temperature.high.poor": "",
  "conditions.descriptions.tilling.temperature.high.critical": "",
  "conditions.descriptions.tilling.temperature.low.optimal": "",
  "conditions.descriptions.tilling.temperature.low.good": "",
  "conditions.descriptions.tilling.temperature.low.moderate": "",
  "conditions.descriptions.tilling.temperature.low.poor": "",
  "conditions.descriptions.tilling.temperature.low.critical": "",
  "conditions.descriptions.tilling.precipitation.high.optimal": "(Peu ou pas de pluie) Il y a peu ou pas d'obstruction pour le tracteur et l'équipement.",
  "conditions.descriptions.tilling.precipitation.high.good": "(Pluie légère) Le tracteur et l'équipement sont peu gênés.",
  "conditions.descriptions.tilling.precipitation.high.moderate": "(Légère pluie) Le tracteur et l'équipement sont légèrement gênés.",
  "conditions.descriptions.tilling.precipitation.high.poor": "(Pluie légère) Il y a un léger obstacle pour le tracteur et l'équipement.",
  "conditions.descriptions.tilling.precipitation.high.critical": "(Pluie légère au-dessus du seuil) Le tracteur et l'équipement sont gênés et il y a un risque de maculage du sol et de perte de traction.",
  "conditions.descriptions.tilling.precipitation.low.optimal": "(Pluie minime à nulle) Le tracteur et l'équipement sont peu ou pas gênés.",
  "conditions.descriptions.tilling.precipitation.low.good": "",
  "conditions.descriptions.tilling.precipitation.low.moderate": "",
  "conditions.descriptions.tilling.precipitation.low.poor": "",
  "conditions.descriptions.tilling.precipitation.low.critical": "",
  "conditions.descriptions.tilling.soil_temperature.high.optimal": "",
  "conditions.descriptions.tilling.soil_temperature.high.good": "",
  "conditions.descriptions.tilling.soil_temperature.high.moderate": "",
  "conditions.descriptions.tilling.soil_temperature.high.poor": "",
  "conditions.descriptions.tilling.soil_temperature.high.critical": "",
  "conditions.descriptions.tilling.soil_temperature.low.optimal": "",
  "conditions.descriptions.tilling.soil_temperature.low.good": "",
  "conditions.descriptions.tilling.soil_temperature.low.moderate": "",
  "conditions.descriptions.tilling.soil_temperature.low.poor": "",
  "conditions.descriptions.tilling.soil_temperature.low.critical": "",
  "conditions.descriptions.tilling.soil_moisture.high.optimal": "(Humidité du sol à la moitié de la capacité) Le travail du sol est sûr dans la plupart des types de sol et permet d'obtenir une taille de motte appropriée, une consommation de carburant réduite et un faible risque de maculage et de compactage du sol.",
  "conditions.descriptions.tilling.soil_moisture.high.good": "(Humidité du sol presque égale à la moitié de sa capacité) Le travail du sol est facile, avec une faible consommation de carburant et un risque très faible de maculage et de compactage du sol.",
  "conditions.descriptions.tilling.soil_moisture.high.moderate": "(Sol humide) Le travail du sol est acceptable, bien qu'il y ait un certain risque de maculage et de compactage du sol.",
  "conditions.descriptions.tilling.soil_moisture.high.poor": "(Sol humide proche de la capacité du champ) -> Le travail du sol sera inefficace, avec un risque élevé de maculage et de compactage du sol.",
  "conditions.descriptions.tilling.soil_moisture.high.critical": "(Sol humide à la capacité du champ ou au-dessus) -> Le travail du sol n'est pas conseillé, avec le risque le plus élevé de maculage et de compactage du sol.",
  "conditions.descriptions.tilling.soil_moisture.low.optimal": "(Humidité du sol à la moitié de la capacité) Le travail du sol est sans danger dans la plupart des types de sol et permet d'obtenir une taille de motte appropriée, une consommation de carburant réduite et un faible risque de maculage et de compactage du sol.",
  "conditions.descriptions.tilling.soil_moisture.low.good": "(Humidité du sol presque égale à la moitié de sa capacité) Le travail du sol est facile et consomme peu de carburant.",
  "conditions.descriptions.tilling.soil_moisture.low.moderate": "(Sol sec) Le broyage est acceptable avec une consommation moyenne de carburant.",
  "conditions.descriptions.tilling.soil_moisture.low.poor": "(Sol sec près du point d'observation) Le travail du sol est difficile, la consommation de carburant est élevée et la pulvérisation en fines particules ou en grosses mottes est probable.",
  "conditions.descriptions.tilling.soil_moisture.low.critical": "(Sol sec au point d'observation) Le travail du sol sera très dur, la consommation de carburant très élevée et la pulvérisation en fines particules ou en grosses mottes très probable.",
  "conditions.descriptions.tilling.wind.high.optimal": "(Calme) Il n'y a pas de risque d'érosion éolienne du sol.",
  "conditions.descriptions.tilling.wind.high.good": "(Brise légère ou faible) Le risque d'érosion éolienne du sol est minime.",
  "conditions.descriptions.tilling.wind.high.moderate": "(Brise modérée) Le risque d'érosion éolienne du sol est minime.",
  "conditions.descriptions.tilling.wind.high.poor": "(Brise forte) Risque important d'érosion éolienne du sol.",
  "conditions.descriptions.tilling.wind.high.critical": "(rafales supérieures à la moyenne) Il y a une forte probabilité d'érosion éolienne du sol.",
  "conditions.descriptions.tilling.wind.low.optimal": "(Vent calme) Il n'y a pas de risque d'érosion éolienne des sols.",
  "conditions.descriptions.tilling.wind.low.good": "",
  "conditions.descriptions.tilling.wind.low.moderate": "",
  "conditions.descriptions.tilling.wind.low.poor": "",
  "conditions.descriptions.tilling.wind.low.critical": "",
  "conditions.descriptions.pressing.temperature.high.optimal": "",
  "conditions.descriptions.pressing.temperature.high.good": "",
  "conditions.descriptions.pressing.temperature.high.moderate": "",
  "conditions.descriptions.pressing.temperature.high.poor": "",
  "conditions.descriptions.pressing.temperature.high.critical": "",
  "conditions.descriptions.pressing.temperature.low.optimal": "",
  "conditions.descriptions.pressing.temperature.low.good": "",
  "conditions.descriptions.pressing.temperature.low.moderate": "",
  "conditions.descriptions.pressing.temperature.low.poor": "",
  "conditions.descriptions.pressing.temperature.low.critical": "",
  "conditions.descriptions.pressing.precipitation.high.optimal": "(Peu ou pas de pluie) Le tracteur et l'équipement sont peu ou pas gênés.",
  "conditions.descriptions.pressing.precipitation.high.good": "(Pluie légère) Il y a un petit obstacle pour le tracteur et l'équipement.",
  "conditions.descriptions.pressing.precipitation.high.moderate": "(Légère pluie) Il y a un petit obstacle pour le tracteur et l'équipement.",
  "conditions.descriptions.pressing.precipitation.high.poor": "(Pluie fine) Il y a un petit obstacle pour le tracteur et l'équipement.",
  "conditions.descriptions.pressing.precipitation.high.critical": "(pluie légère au-dessus du seuil) Le tracteur et le matériel sont gênés et il y a un risque de maculage du sol et de perte de traction.",
  "conditions.descriptions.pressing.precipitation.low.optimal": "(Peu ou pas de pluie) Il y a peu ou pas de gêne pour le tracteur et l'équipement.",
  "conditions.descriptions.pressing.precipitation.low.good": "",
  "conditions.descriptions.pressing.precipitation.low.moderate": "",
  "conditions.descriptions.pressing.precipitation.low.poor": "",
  "conditions.descriptions.pressing.precipitation.low.critical": "",
  "conditions.descriptions.pressing.soil_temperature.high.optimal": "",
  "conditions.descriptions.pressing.soil_temperature.high.good": "",
  "conditions.descriptions.pressing.soil_temperature.high.moderate": "",
  "conditions.descriptions.pressing.soil_temperature.high.poor": "",
  "conditions.descriptions.pressing.soil_temperature.high.critical": "",
  "conditions.descriptions.pressing.soil_temperature.low.optimal": "",
  "conditions.descriptions.pressing.soil_temperature.low.good": "",
  "conditions.descriptions.pressing.soil_temperature.low.moderate": "",
  "conditions.descriptions.pressing.soil_temperature.low.poor": "",
  "conditions.descriptions.pressing.soil_temperature.low.critical": "",
  "conditions.descriptions.pressing.soil_moisture.high.optimal": "(Sol très sec au point d'observation) Risque minime de compactage du sol, d'endommagement du sol et d'enlisement du tracteur.",
  "conditions.descriptions.pressing.soil_moisture.high.good": "(Sol plus sec) Il y a un faible risque de compactage du sol, de dommages au sol et d'enlisement du tracteur.",
  "conditions.descriptions.pressing.soil_moisture.high.moderate": "(sol sec) Risque de tassement du sol, de dommages au sol et d'enlisement du tracteur.",
  "conditions.descriptions.pressing.soil_moisture.high.poor": "(Sol humide) Risque élevé de tassement du sol, de dommages au sol et d'enlisement du tracteur",
  "conditions.descriptions.pressing.soil_moisture.high.critical": "(Sol très humide à la capacité du champ) Risque le plus élevé de compactage du sol, de dommages au sol et de blocage du tracteur",
  "conditions.descriptions.pressing.soil_moisture.low.optimal": "(Sol très sec au point d'observation) Risque minime de tassement du sol, d'endommagement du sol et de blocage du tracteur.",
  "conditions.descriptions.pressing.soil_moisture.low.good": "",
  "conditions.descriptions.pressing.soil_moisture.low.moderate": "",
  "conditions.descriptions.pressing.soil_moisture.low.poor": "",
  "conditions.descriptions.pressing.soil_moisture.low.critical": "",
  "conditions.descriptions.pressing.wind.high.optimal": "(Calme) Il n'y a pas de risque de dérive de la paille et des résidus.",
  "conditions.descriptions.pressing.wind.high.good": "(Air léger) Le risque d'arrachage de la paille et des résidus est minime.",
  "conditions.descriptions.pressing.wind.high.moderate": "(brise légère) Le risque de voir la paille et les résidus s'envoler est minime.",
  "conditions.descriptions.pressing.wind.high.poor": "(brise modérée) - Il y a un certain risque de dérive de la paille et des résidus.",
  "conditions.descriptions.pressing.wind.high.critical": "(Brise fraîche) Les feuilles commencent à se balancer et il y a un risque élevé que de la paille et des résidus flottent.",
  "conditions.descriptions.pressing.wind.low.optimal": "(Vent calme) Il n'y a pas de risque de dérive de la paille et des résidus.",
  "conditions.descriptions.pressing.wind.low.good": "",
  "conditions.descriptions.pressing.wind.low.moderate": "",
  "conditions.descriptions.pressing.wind.low.poor": "",
  "conditions.descriptions.pressing.wind.low.critical": "",
};
