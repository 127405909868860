import styled from "@emotion/styled";
import { useSessionStorageValue } from "@react-hookz/web";
import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, PropsWithChildren } from "react";
import { FiCalendar, FiMap, FiUsers } from "react-icons/fi";
import { TbTractor } from "react-icons/tb";
import { useUsers } from "../../hooks/api/karl-farm/useUsers";
import {
  useEmail,
  useEnterpriseId,
  useUserId,
  useUsername,
} from "../../hooks/useAuthGuard";
import { sessionStorageKeys } from "../../utils/storageKeys";
import { LanguageSelector } from "../LanguageSelector";
import { UserAvatar } from "../UserAvatar";
import { EnterpriseOverride } from "./EnterpriseOverride";

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const SideNavigation = styled.div`
  height: 100%;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  background: rgba(0, 0, 0, 0.05);
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;

  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
`;

const IconLink = styled(motion(Link))`
  display: flex;
  padding: 10px;
  width: 48px;
  height: 48px;
  margin: 0 10px;
  border-radius: 10px;

  justify-content: center;
  align-items: center;
`;

const LogoImage = styled(Image)`
  border-radius: 100%;
`;

const Spacer = styled.div`
  flex: 1;
`;

const Ruler = styled.hr`
  margin: 6px 0;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
`;

type WithSideNavigationProps = PropsWithChildren;
export const WithSideNavigation: FC<WithSideNavigationProps> = ({
  children,
}) => {
  const router = useRouter();

  const getBackgroundColor = (startOfPath: string) => {
    return router.pathname.startsWith(startOfPath)
      ? "rgba(0, 0, 0, 0.1)"
      : "rgba(0, 0, 0, 0)";
  };

  const { data: users } = useUsers();
  const userId = useUserId();
  const currentUser = users?.data.users?.find((user) => {
    return user.Attributes.sub === userId;
  });

  const username = useUsername();
  const enterpriseId = useEnterpriseId();
  const loggedIn = username && enterpriseId;

  const userEmail = useEmail();
  const hasEnterpriseOverrideRights = userEmail?.endsWith("@karl.farm");

  const { value: adminOverrideEnterpriseId } = useSessionStorageValue<string>(
    sessionStorageKeys.adminOverrideEnterpriseId
  );

  const highlightSidebar =
    typeof adminOverrideEnterpriseId === "string" &&
    adminOverrideEnterpriseId !== "";

  return (
    <Container>
      <SideNavigation
        css={{
          color: highlightSidebar ? "white" : undefined,
          backgroundColor: highlightSidebar ? "#38A169" : undefined,
        }}
      >
        <IconLink href={loggedIn ? "#" : "/"}>
          <LogoImage
            src="/square-logo.png"
            alt="Karl logo"
            width="32"
            height="32"
            priority
          />
        </IconLink>
        <Ruler />

        {loggedIn && (
          <>
            <IconLink
              href={"/calendar"}
              initial={{ background: getBackgroundColor("/calendar") }}
              animate={{ background: getBackgroundColor("/calendar") }}
            >
              <FiCalendar size={24} />
            </IconLink>
            <IconLink
              href={"/map"}
              initial={{ background: getBackgroundColor("/map") }}
              animate={{ background: getBackgroundColor("/map") }}
            >
              <FiMap size={24} />
            </IconLink>
            <IconLink
              href={"/users"}
              initial={{ background: getBackgroundColor("/users") }}
              animate={{ background: getBackgroundColor("/users") }}
            >
              <FiUsers size={24} />
            </IconLink>
            <IconLink
              href={"/machines"}
              initial={{ background: getBackgroundColor("/machines") }}
              animate={{ background: getBackgroundColor("/machines") }}
            >
              <TbTractor size={26} />
            </IconLink>
          </>
        )}
        <Spacer />
        {hasEnterpriseOverrideRights && <EnterpriseOverride />}
        <LanguageSelector
          popoverProps={{
            position: "right",
          }}
        />
        {username ? (
          <Link href={"/profile"}>
            <UserAvatar user={currentUser} hideTooltip />
          </Link>
        ) : null}
      </SideNavigation>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};
