import { MantineColor } from "@mantine/core";

export const getPsuedoRandomMantineColor = (seed: string): MantineColor => {
  const mantineColors: MantineColor[] = [
    "red",
    "pink",
    "grape",
    "violet",
    "indigo",
    "blue",
    "cyan",
    "teal",
    "green",
    "lime",
    "yellow",
    "orange",
  ];

  const fullNameHash = seed
    .split("")
    .reduce((acc, curr, i) => acc + (i + 1) * curr.charCodeAt(0), 0);

  return mantineColors[fullNameHash % mantineColors.length];
};
