import { useSystemOfMeasurement } from "../hooks/storage/storageHooks";
import { useLocale } from "../hooks/useLocale";
import { da } from "./da";
import { en } from "./en";
import { es } from "./es";
import { fr } from "./fr";

export const useTranslation = () => {
  const { locale } = useLocale();
  const [systemOfMeasurement] = useSystemOfMeasurement();

  const keyToTranslationFile = {
    da,
    en,
    es,
    fr,
  };

  const translate = (key: keyof typeof en): string => {
    switch (systemOfMeasurement) {
      case "metric":
        return keyToTranslationFile[locale][key];
      case "imperial":
        const imperialKey = `${key}__imperial` as const;
        if (imperialKey in keyToTranslationFile[locale]) {
          const typedImperialKey = imperialKey as keyof typeof en;
          return keyToTranslationFile[locale][typedImperialKey];
        } else {
          // if imperial translation doesn't exist, fall back to metric
          return keyToTranslationFile[locale][key];
        }
    }
  };

  return {
    t: translate,
  };
};
