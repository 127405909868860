import { useLocalStorageValue } from "@react-hookz/web";
import { localStorageKeys } from "../../utils/storageKeys";

export const systemsOfMeasurement = [
  "metric", // °C, m/s, mm, cm
  "imperial", // °F, mph, in
] as const;
export type SystemOfMeasurement = (typeof systemsOfMeasurement)[number];
export const useSystemOfMeasurement = (): [
  SystemOfMeasurement,
  (value: SystemOfMeasurement) => void
] => {
  const { value: systemOfMeasurement, set: setSystemOfMeasurement } =
    useLocalStorageValue<SystemOfMeasurement>(
      localStorageKeys.systemOfMeasurement
    );
  return [systemOfMeasurement || "metric", setSystemOfMeasurement];
};

export const fieldColorings = ["simple", "detailed"] as const;
export type FieldColoring = (typeof fieldColorings)[number];
export const useFieldColoring = (): [
  FieldColoring,
  (value: FieldColoring) => void
] => {
  const { value: fieldColoring, set: setFieldColoring } =
    useLocalStorageValue<FieldColoring>(localStorageKeys.fieldColoring, {
      defaultValue: "simple",
      initializeWithValue: false,
    });
  return [fieldColoring || "simple", setFieldColoring];
};

export const useIrrigationColoring = (): [
  boolean,
  (value: boolean) => void
] => {
  const { value: irrigationColoring, set: setIrrigationColoring } =
    useLocalStorageValue<boolean>(localStorageKeys.irrigationColoring, {
      defaultValue: false,
      initializeWithValue: false,
    });
  return [irrigationColoring || false, setIrrigationColoring];
};
