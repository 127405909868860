import type { en } from "./en";

export const es: typeof en = {
  "page.map.button.mapStyle.map": "Mapa",
  "page.map.button.mapStyle.satellite": "Satélite",
  "page.map.notification.message.karlDoesNotWorkInThisAreaYet": "Los límites de campo automáticos no son compatibles en esta área. Por favor, dibújelo manualmente.",
  "page.map.notification.title.fieldShouldAppearInAMoment": "El campo ha sido añadido.",
  "page.map.notification.message.fieldShouldAppearInAMoment": "Debería aparecer en el mapa en unos segundos.",
  "page.map.fieldLoadingStatus.text": "Procesando datos del campo...",
  "page.map.overlay.button.clear": "Borrar",
  "page.map.overlay.button.undo": "Deshacer",
  "page.map.overlay.button.continue": "Continuar",
  "page.map.overlay.button.automatic.text": "Automático",
  "page.map.overlay.button.automatic.tooltip": "En algunas áreas, KARL puede delinear automáticamente los límites de los campos. Simplemente haga clic en un campo y vea el contorno. Si el contorno es incorrecto, o si el área no es compatible, puede dibujarlo manualmente.",
  "page.map.overlay.button.manual.text": "Manual",
  "page.map.overlay.button.manual.tooltip": "Dibuje los límites de su campo haciendo clic en el mapa hasta que el campo esté resaltado correctamente, luego seleccione el botón Continuar en la parte inferior de la pantalla.",

  "page.users.heading": "Usuarios",
  "page.users.iconButton.addUser.ariaLabel": "Añadir usuario",
  "page.users.userId": "ID de usuario",
  "page.users.phone": "Número de teléfono",
  "page.users.email": "Correo electrónico",
  "page.users.emailVerified": "Email verificado",
  "page.users.emailVerified.yes": "Sí",
  "page.users.emailVerified.no": "No",
  "page.users.employment": "Relación laboral",
  "page.users.employment.external": "Externo",
  "page.users.employment.internal": "Interna",
  "page.users.addUserDrawer.heading": "Añadir usuario",
  "page.users.addUserDrawer.input.firstName": "Nombre y apellidos",
  "page.users.addUserDrawer.input.lastName": "Apellidos",
  "page.users.addUserDrawer.input.email": "Dirección de correo electrónico",
  "page.users.addUserDrawer.checkbox.userCategory.label": "Relación laboral",
  "page.users.addUserDrawer.checkbox.userCategory.text": "Externo",
  "page.users.addUserDrawer.input.phone": "Número de teléfono",
  "page.users.addUserDrawer.button.submit": "Añada su nombre",

  "page.machines.heading": "Máquinas y implementos",
  "page.machines.iconButton.addMachine.ariaLabel": "Añadir máquina o implemento",
  "page.machines.machineId": "ID de la máquina",
  "page.machines.brand": "Marca",
  "page.machines.model": "Modelo de máquina",
  "page.machines.yearProduced": "Año de fabricación",
  "page.machines.description": "Descripción",
  "page.machines.category": "Categoría",
  "page.machines.type": "Tipo de",
  "page.machines.type.machine": "Máquina",
  "page.machines.type.equipment": "Implemento",
  "page.machines.addMachineDrawer.heading": "Añadir máquina o implemento",
  "page.machines.addMachineDrawer.input.brand": "Marca",
  "page.machines.addMachineDrawer.input.model": "Modelo de máquina",
  "page.machines.addMachineDrawer.input.yearProduced": "Año de fabricación",
  "page.machines.addMachineDrawer.input.description": "Descripción",
  "page.machines.addMachineDrawer.select.type": "Tipo de",
  "page.machines.addMachineDrawer.select.type.machine": "Máquina",
  "page.machines.addMachineDrawer.select.type.equipment": "Implemento",
  "page.machines.addMachineDrawer.input.category": "Categoría",
  "page.machines.addMachineDrawer.button.submit": "Añadir a",

  "page.profile.heading.generalInformation": "Información general",
  "page.profile.heading.settings": "Ajustes y preferencias",
  "page.profile.heading.legal": "Información legal",
  "page.profile.heading.feedback": "Comentarios",

  "page.profile.legal.termsAndConditions": "Términos y condiciones",
  "page.profile.legal.privacyPolicy": "Política de privacidad",

  "page.profile.feedback.description": "Siempre estamos tratando de mejorar, y nos encantaría escuchar sus comentarios.",
  "page.profile.feedback.button.submit": "Dar retroalimentación",

  "page.profile.enterpriseId": "ID de empresa",
  "page.profile.userId": "ID de usuario",
  "page.profile.username": "Nombre de usuario",
  "page.profile.userEmail": "Correo electrónico del usuario",
  "page.profile.language": "Idioma",
  "page.profile.label.systemOfMeasurement": "Sistema de medición",
  "page.profile.systemOfMeasurement.metric": "Métrico",
  "page.profile.systemOfMeasurement.imperial": "Imperial",
  "page.profile.label.fieldColoring": "Colorear campos",
  "page.profile.fieldColoring.simple": "Simple",
  "page.profile.fieldColoring.detailed": "Detallado",
  "page.profile.logOut": "Cerrar sesión",

  "page.calendar.week": "Semana de la semana",
  "page.calendar.today": "Hoy",
  "page.calendar.days.1": "1 día",
  "page.calendar.days.3": "3 días",
  "page.calendar.days.7": "1 semana",
  "page.calendar.iconButton.addTask.ariaLabel": "Añadir tarea",
  "page.calendar.button.filters": "Filtros",
  "page.calendar.filters.label.fieldGroups": "Grupos de campo",
  "page.calendar.filters.placeholder.fieldGroups": "Filtrar por grupos de campos",
  "page.calendar.filters.label.fields": "Campos",
  "page.calendar.filters.label.tasks": "Tareas",
  "page.calendar.filters.label.users": "Usuarios",
  "page.calendar.filters.label.machines": "Máquinas y implementos",
  "page.calendar.filters.button.clearFilters": "Borrar filtros",
  "page.calendar.showFieldConditions": "Mostrar condiciones de campo",
  "page.calendar.colorTasksPerAssociatedUsers.text": "Colorear tareas por empleados asignados",
  "page.calendar.startCalendarOnSelectedDate.text": "Iniciar calendario en fecha seleccionada",

  "page.index.heading": "Bienvenido a KARL",
  "page.index.mobileWarning.Heading": "¡Ups, KARL aún no es compatible con móviles!",
  "page.index.mobileWarning.Paragraph": "Hasta entonces, puedes disfrutar de KARL en tu ordenador o tableta. Regístrate en nuestro boletín para recibir una notificación cuando nuestra aplicación móvil esté lista 🚀",
  "page.index.login": "Empiece a trabajar con nosotros",

  "page.createEnterprise.heading": "Crear una empresa",
  "page.createEnterprise.description": "Por favor, introduzca la siguiente información para empezar.",
  "page.createEnterprise.personalFirstNameInput.label": "Su nombre",
  "page.createEnterprise.personalLastNameInput.label": "Apellidos",
  "page.createEnterprise.companyNameInput.label": "Nombre de la empresa",
  "page.createEnterprise.cvrInput.label": "NÚMERO DE REGISTRO DE LA EMPRESA",
  "page.createEnterprise.buttonText": "Adelante",

  "component.AddTaskDrawer.heading": "Añadir tarea",
  "component.AddTaskDrawer.buttons.cancel": "Cancelar",
  "component.AddTaskDrawer.buttons.add": "Añadir tarea",

  "component.ConditionBadge.conditionLevel.optimal": "Óptimo",
  "component.ConditionBadge.conditionLevel.good": "Bueno",
  "component.ConditionBadge.conditionLevel.moderate": "Moderado",
  "component.ConditionBadge.conditionLevel.poor": "Malo",
  "component.ConditionBadge.conditionLevel.critical": "Crítico",

  "component.ConditionBadge.typeLabel.precipitation": "Precipitación",
  "component.ConditionBadge.typeLabel.temperature": "Temperatura",
  "component.ConditionBadge.typeLabel.soilTemperature": "Temperatura del suelo",
  "component.ConditionBadge.typeLabel.soilMoisture": "Humedad del suelo",
  "component.ConditionBadge.typeLabel.clouds": "Nubes",
  "component.ConditionBadge.typeLabel.wind": "Viento",
  "component.ConditionBadge.typeLabel.gusts": "Ráfagas de viento",

  "component.EditTaskDrawer.heading": "Detalles",
  "component.EditTaskDrawer.tabEditHeading": "Editar",
  "component.EditTaskDrawer.conditionsNotSupportedForThisTaskType": "Las condiciones no son compatibles con este tipo de tarea.",
  "component.EditTaskDrawer.tabConditionsHeading": "Condiciones de campo",
  "component.EditTaskDrawer.buttons.delete": "Borrar",
  "component.EditTaskDrawer.buttons.cancel": "Cancelar",
  "component.EditTaskDrawer.buttons.apply": "Aplicar",

  "component.AddFieldDrawerContent.heading.addField": "Añadir campo",
  "component.AddFieldDrawerContent.inputLabel.fieldName": "Nombre del campo",
  "component.AddFieldDrawerContent.inputLabel.secondaryName": "Nombre secundario",
  "component.AddFieldDrawerContent.inputLabel.fieldGroup": "Grupo",
  "component.AddFieldDrawerContent.label.boundary": "Límite",
  "component.AddFieldDrawerContent.label.noBoundaryDrawn": "Sin límite dibujado",
  "component.AddFieldDrawerContent.button.drawFieldManually": "Dibujar campo manualmente",
  "component.AddFieldDrawerContent.button.finish": "Terminar",
  "component.AddFieldDrawerContent.heading.automaticallyDrawnFieldBoundaries": "Límites de campo dibujados automáticamente",
  "component.AddFieldDrawerContent.content.automaticallyDrawnFieldBoyndaries": "Puede hacer clic en su campo en el mapa de la derecha para que su límite se dibuje automáticamente.",
  "component.AddFieldDrawerContent.heading.manuallyDrawnFieldBoundaries": "Límites de campo dibujados manualmente",
  "component.AddFieldDrawerContent.content.manuallyDrawnFieldBoundaries": "Si encuentra que los límites dibujados automáticamente son inexactos o no están disponibles en su área, puede dibujar el límite de su campo manualmente. Para ello, haga clic en el botón de arriba y luego en el mapa para dibujar el límite de su campo. Puede añadir tantos puntos como desee. Para terminar de dibujar, haga clic en el botón de nuevo.",
  "component.AddFieldDrawerContent.button.cancel": "Deshacer",
  "component.AddFieldDrawerContent.button.add": "Añadir",

  "component.FieldsDrawerContent.heading": "Campos",
  "component.FieldsDrawerContent.filterByFieldGroupPlaceholder": "Filtrar por grupo",
  "component.FieldsDrawerContent.areaUnit": "ha",
  "component.FieldsDrawerContent.areaUnit__imperial": "acres",
  "component.FieldsDrawerContent.button.addField": "Añadir campo",
  "component.FieldsDrawerContent.button.cancel": "Cancelar",
  "component.FieldsDrawerContent.toggleIrrigationColoring": "Alternar el coloreado del riego",
  "component.FieldsDrawerContent.toggleFieldColoring": "Alternar el coloreado de los campos",

  "component.FieldDrawerContent.heading": "Campo",
  "component.FieldDrawerContent.tab.weather": "Condiciones meteorológicas",
  "component.FieldDrawerContent.tab.tasks": "Tareas",
  "component.FieldDrawerContent.tab.info": "Información",
  "component.FieldDrawerContent.tab.edit": "Editar",

  "component.FieldDrawerWeatherTabContent.chartName.precipitation": "Precipitación",
  "component.FieldDrawerWeatherTabContent.chartName.airTemperature": "Temperatura del aire",
  "component.FieldDrawerWeatherTabContent.chartName.soilMoisture": "Humedad del suelo",
  "component.FieldDrawerWeatherTabContent.chartName.soilTemperature": "Temperatura del suelo",
  "component.FieldDrawerWeatherTabContent.chartName.windGusts": "Velocidad del viento y de las ráfagas",
  "component.FieldDrawerWeatherTabContent.chartName.cloudCoverage": "Nubosidad",
  "component.FieldDrawerWeatherTabContent.chartName.humidity": "Humedad del aire",
  "component.FieldDrawerWeatherTabContent.series.p1h": "Precipitación (mm/hora)",
  "component.FieldDrawerWeatherTabContent.series.at": "Temperatura del aire (°C)",
  "component.FieldDrawerWeatherTabContent.series.sm0_5": "Humedad del suelo 0-5cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm5_15": "Humedad del suelo 5-15cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm15_30": "Humedad del suelo 15-30cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm30_60": "Humedad del suelo 30-60cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm60_100": "Humedad del suelo 60-100 cm (%)",
  "component.FieldDrawerWeatherTabContent.series.st0": "Temperatura del suelo 0cm (°C)",
  "component.FieldDrawerWeatherTabContent.series.st10": "Temperatura del suelo 10cm (°C)",
  "component.FieldDrawerWeatherTabContent.series.gs": "Ráfaga de viento (m/s)",
  "component.FieldDrawerWeatherTabContent.series.ws": "Viento (m/s)",
  "component.FieldDrawerWeatherTabContent.series.p1h__imperial": "Precipitación (in/hora)",
  "component.FieldDrawerWeatherTabContent.series.at__imperial": "Temperatura del aire (°F)",
  "component.FieldDrawerWeatherTabContent.series.sm0_5__imperial": 'Humedad del suelo 0-1.97" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm5_15__imperial": 'Humedad del suelo 1.97-5.91" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm15_30__imperial": 'Humedad del suelo 5.91-11.81" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm30_60__imperial": 'Humedad del suelo 11.81-23.62" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm60_100__imperial": 'Humedad del suelo 23.62-39.37" (%)',
  "component.FieldDrawerWeatherTabContent.series.st0__imperial": 'Temperatura del suelo 0" (°F)',
  "component.FieldDrawerWeatherTabContent.series.st10__imperial": 'Temperatura del suelo 3.94" (°F)',
  "component.FieldDrawerWeatherTabContent.series.gs__imperial": "Ráfagas (mph)",
  "component.FieldDrawerWeatherTabContent.series.ws__imperial": "Viento (mph)",
  "component.FieldDrawerWeatherTabContent.series.c": "Nubosidad (%)",
  "component.FieldDrawerWeatherTabContent.series.h": "Humedad del aire (%)",

  "component.FieldDrawerTasksTabContent.hideCompletedTasks": "Ocultar tareas completadas",
  "component.FieldDrawerTasksTabContent.noTasksFound": "No se han encontrado datos para este campo.",

  "component.FieldDrawerInfoTabContent.label.cropType": "Tipo de cultivo",
  "component.FieldDrawerInfoTabContent.label.expectedSowingDate": "Fecha de siembra prevista",
  "component.FieldDrawerInfoTabContent.label.expectedHarvestDate": "Fecha de cosecha prevista",
  "component.FieldDrawerInfoTabContent.label.areaHa": "Superficie (ha)",
  "component.FieldDrawerInfoTabContent.label.areaHa__imperial": "Superficie (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.Marknr": "Número de campo",
  "component.FieldDrawerInfoTabContent.customProperty.Afgroede": "Cultivo",
  "component.FieldDrawerInfoTabContent.customProperty.Markblok": "Bloque de campo",
  "component.FieldDrawerInfoTabContent.customProperty.GBanmeldt": "GB declarado",
  "component.FieldDrawerInfoTabContent.customProperty.Afgkode": "Código del cultivo",
  "component.FieldDrawerInfoTabContent.customProperty.GB": "GB",
  "component.FieldDrawerInfoTabContent.customProperty.Journalnr": "Número de diario",
  "component.FieldDrawerInfoTabContent.customProperty.IMK_areal": "Superficie IMK",
  "component.FieldDrawerInfoTabContent.customProperty.CVR": "CVR",
  "component.FieldDrawerInfoTabContent.customProperty.area": "Superficie (m2)",
  "component.FieldDrawerInfoTabContent.customProperty.area_acres": "Superficie (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.area_ha": "Superficie (ha)",
  "component.FieldDrawerInfoTabContent.customProperty.area_ha__imperial": "Superficie (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.id": "ID",
  "component.FieldDrawerInfoTabContent.accordion.soil.heading": "Información del suelo",
  "component.FieldDrawerInfoTabContent.accordion.soil.disclaimer.heading": "Precisión de la información del suelo",
  "component.FieldDrawerInfoTabContent.accordion.soil.disclaimer.label": "La información del suelo es una característica experimental y puede mostrar métricas inexactas. Los valores son promedios estimados de la capa superior del suelo de 30 cm.",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.soilType": "Tipo de suelo",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.ph": "pH",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.organicCarbonContent": "Contenido de carbono orgánico",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.nitrogenContent": "Contenido de nitrógeno",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.sand": "Arena",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.silt": "Limo",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.clay": "Arcilla",
  "component.FieldDrawerInfoTabContent.accordion.soil.heading.ph": "pH",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.heading": "Riego",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.button.disableAdvancedIrrigationAnalysis": "Desactivar análisis avanzado de riego",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.disclaimer.heading": "Análisis avanzado de riego",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.disclaimer.label": "El análisis avanzado de riego es una característica experimental y puede mostrar métricas inexactas.",
  "component.FieldDrawerInfoTabContent.buttons.delete": "Borrar",

  "component.EnableIrrigationAnalysisButton.button.enableAdvancedIrrigationAnalysis": "Activar análisis avanzado de riego",
  "component.EnableIrrigationAnalysisButton.modal.heading": "¿Estás seguro?",
  "component.EnableIrrigationAnalysisButton.modal.description": "Por favor, confirme que desea activar el análisis avanzado de riego.",
  "component.EnableIrrigationAnalysisButton.modal.button.cancel": "Cancelar",
  "component.EnableIrrigationAnalysisButton.modal.button.confirm": "Confirmar",

  "component.FieldIrrigationDrawerContent.text.irrigationMetricsNotEnabled": "Las métricas de riego no están habilitadas para este campo.",
  "component.FieldIrrigationDrawerContent.text.noDataAvailableForTheChosenTimePeriod": "No hay datos disponibles para el período de tiempo elegido.",
  "component.FieldIrrigationDrawerContent.chartName.waterDeficit": "Déficit de agua",
  "component.FieldIrrigationDrawerContent.chartName.evapotranspiration": "Evapotranspiración",
  "component.FieldIrrigationDrawerContent.chartName.soilMoisture": "Humedad del suelo",
  "component.FieldIrrigationDrawerContent.series.waterDeficit": "Déficit de agua",
  "component.FieldIrrigationDrawerContent.series.evapotranspiration": "Evapotranspiración",
  "component.FieldIrrigationDrawerContent.series.soilMoisture": "Humedad del suelo",
  "component.FieldIrrigationDrawerContent.series.saturationPoint": "Punto de saturación",
  "component.FieldIrrigationDrawerContent.series.fieldCapacity": "Capacidad de campo",
  "component.FieldIrrigationDrawerContent.series.refillPoint": "Punto de recarga",
  "component.FieldIrrigationDrawerContent.series.wiltingPoint": "Punto de marchitez",
  "component.FieldIrrigationDrawerContent.select.options.day": "Día",
  "component.FieldIrrigationDrawerContent.select.options.week": "Semana",
  "component.FieldIrrigationDrawerContent.select.options.month": "Mes",
  "component.FieldIrrigationDrawerContent.select.options.year": "Año",
  "component.FieldIrrigationDrawerContent.select.options.custom": "Personalizado",

  "component.Map.evapotranspiration": "Evapotranspiración",
  "component.Map.soilMoisture": "Humedad del suelo",

  "component.FieldDrawerEditTabContent.label.fieldName": "Nombre del campo",
  "component.FieldDrawerEditTabContent.label.secondaryName": "Nombre secundario",
  "component.FieldDrawerEditTabContent.label.fieldGroup": "Grupo",
  "component.FieldDrawerEditTabContent.label.cropType": "Tipo de cultivo",
  "component.FieldDrawerEditTabContent.label.expectedSowingDate": "Fecha de siembra prevista",
  "component.FieldDrawerEditTabContent.label.expectedHarvestDate": "Fecha de cosecha prevista",
  "component.FieldDrawerEditTabContent.buttons.apply": "Aplicar",
  "component.FieldDrawerEditTabContent.buttons.cancel": "Cancelar",

  "component.TaskForm.formLabels.title": "Título",
  "component.TaskForm.formLabels.task": "Tarea",
  "component.TaskForm.formLabels.crop": "Cultivo",
  "component.TaskForm.formLabels.tillingDepth": "Profundidad de trabajo",
  "component.TaskForm.formLabels.dropSize": "Diámetro de caída",
  "component.TaskForm.formLabels.start": "Hora de inicio",
  "component.TaskForm.formLabels.duration": "Duración",
  "component.TaskForm.formLabels.fields": "Campos",
  "component.TaskForm.formLabels.users": "Usuarios",
  "component.TaskForm.formLabels.machinesAndEquipment": "Máquinas y aperos",
  "component.TaskForm.formLabels.notes": "Notas",

  "data.task.harvesting": "Cosecha",
  "data.task.seeding": "Siembra",
  "data.task.tilling": "Labranza",
  "data.task.spraying": "Pulverización",
  "data.task.spreading": "Esparcimiento",
  "data.task.mowing": "Siega",
  "data.task.pressing": "Empaque de heno",
  "data.task.other": "Otro",
  "data.tillingDepth.shallow": "Bajo",
  "data.tillingDepth.medium": "Medio",
  "data.tillingDepth.deep": "Profundo",
  "data.dropSize.fine": "Fino",
  "data.dropSize.medium": "Medio",
  "data.dropSize.coarse": "Gruesa",
  "data.crop.barley": "Cebada",
  "data.crop.wheat": "Trigo",
  "data.crop.maize": "Maíz",
  "data.crop.rye": "Centeno",
  "data.crop.oat": "Avena",
  "data.crop.sorghum": "Sorgo",
  "data.crop.triticale": "Triticale",
  "data.crop.rapeseed": "Colza",
  "data.crop.grass_and_clover": "Hierba y trébol",
  "data.crop.sunflower": "Girasol",
  "data.crop.potato": "Patata",
  "data.crop.beetroot": "Remolacha",
  "data.crop.carrot": "Zanahoria",
  "data.crop.broad_bean": "Habas",
  "data.crop.pea": "Guisantes",
  "data.crop.common_bean": "Habas",
  "data.crop.soybean": "Soja",
  "data.crop.cotton": "Algodón",
  "data.crop.rice": "Arroz",
  "data.crop.sugarcane": "Caña de azúcar",

  "data.field.soilType.Sand": "Arena",
  "data.field.soilType.Loamy sand": "Arena limosa",
  "data.field.soilType.Sandy loam": "Limo arenoso",
  "data.field.soilType.Loam": "Limo",
  "data.field.soilType.Silty loam": "Limo arcilloso",
  "data.field.soilType.Silt": "Limo",
  "data.field.soilType.Clay loam": "Limo arcilloso",
  "data.field.soilType.Sandy clay loam": "Limo arcilloso arenoso",
  "data.field.soilType.Silty clay loam": "Limo arcilloso limoso",
  "data.field.soilType.Sandy clay": "Arcilla arenosa",
  "data.field.soilType.Silty clay": "Arcilla limosa",
  "data.field.soilType.Clay": "Arcilla",

  "conditions.descriptions.harvesting.temperature.high.optimal": "",
  "conditions.descriptions.harvesting.temperature.high.good": "",
  "conditions.descriptions.harvesting.temperature.high.moderate": "",
  "conditions.descriptions.harvesting.temperature.high.poor": "",
  "conditions.descriptions.harvesting.temperature.high.critical": "",
  "conditions.descriptions.harvesting.temperature.low.optimal": "",
  "conditions.descriptions.harvesting.temperature.low.good": "",
  "conditions.descriptions.harvesting.temperature.low.moderate": "",
  "conditions.descriptions.harvesting.temperature.low.poor": "",
  "conditions.descriptions.harvesting.temperature.low.critical": "",
  "conditions.descriptions.harvesting.precipitation.high.optimal": "(Lluvia mínima o nula) Hay una obstrucción  mínima o nula para el tractor y el equipo",
  "conditions.descriptions.harvesting.precipitation.high.good": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo",
  "conditions.descriptions.harvesting.precipitation.high.moderate": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo",
  "conditions.descriptions.harvesting.precipitation.high.poor": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo",
  "conditions.descriptions.harvesting.precipitation.high.critical": "(Lluvia ligera por encima del límite) Hay una obstrucción para el tractor y el equipo y riesgo de embarrado del suelo y pérdida de tracción",
  "conditions.descriptions.harvesting.precipitation.low.optimal": "(Lluvia mínima o nula) Hay una obstrucción mínima o nula para el tractor y el equipo",
  "conditions.descriptions.harvesting.precipitation.low.good": "",
  "conditions.descriptions.harvesting.precipitation.low.moderate": "",
  "conditions.descriptions.harvesting.precipitation.low.poor": "",
  "conditions.descriptions.harvesting.precipitation.low.critical": "",
  "conditions.descriptions.harvesting.soil_temperature.high.optimal": "",
  "conditions.descriptions.harvesting.soil_temperature.high.good": "",
  "conditions.descriptions.harvesting.soil_temperature.high.moderate": "",
  "conditions.descriptions.harvesting.soil_temperature.high.poor": "",
  "conditions.descriptions.harvesting.soil_temperature.high.critical": "",
  "conditions.descriptions.harvesting.soil_temperature.low.optimal": "",
  "conditions.descriptions.harvesting.soil_temperature.low.good": "",
  "conditions.descriptions.harvesting.soil_temperature.low.moderate": "",
  "conditions.descriptions.harvesting.soil_temperature.low.poor": "",
  "conditions.descriptions.harvesting.soil_temperature.low.critical": "",
  "conditions.descriptions.harvesting.soil_moisture.high.optimal": "(Suelo muy seco en el punto de marchitez) Existe un riesgo mínimo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.harvesting.soil_moisture.high.good": "(Suelo más seco) Existe un riesgo bajo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.harvesting.soil_moisture.high.moderate": "(Suelo seco) Cierto riesgo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.harvesting.soil_moisture.high.poor": "(Suelo húmedo) Alto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.harvesting.soil_moisture.high.critical": "(Suelo muy húmedo en la capacidad de campo) Mayor riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.harvesting.soil_moisture.low.optimal": "(Suelo muy seco en el punto de marchitez) Riesgo mínimo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.harvesting.soil_moisture.low.good": "",
  "conditions.descriptions.harvesting.soil_moisture.low.moderate": "",
  "conditions.descriptions.harvesting.soil_moisture.low.poor": "",
  "conditions.descriptions.harvesting.soil_moisture.low.critical": "",
  "conditions.descriptions.harvesting.wind.high.optimal": "(Calmo) No hay riesgo de deriva del grano y pérdida de rendimiento",
  "conditions.descriptions.harvesting.wind.high.good": "(Brisa ligera) Existe un riesgo mínimo de deriva del grano y pérdida de rendimiento",
  "conditions.descriptions.harvesting.wind.high.moderate": "(Brisa ligera) Existe un riesgo mínimo de deriva del grano y pérdida de rendimiento",
  "conditions.descriptions.harvesting.wind.high.poor": "(Brisa moderada) Existe un riesgo mínimo de deriva del grano y pérdida de rendimiento",
  "conditions.descriptions.harvesting.wind.high.critical": "(Viento lo suficientemente fuerte como para levantar un grano de trigo) Riesgo significativo de deriva del grano y pérdida de rendimiento",
  "conditions.descriptions.harvesting.wind.low.optimal": "",
  "conditions.descriptions.harvesting.wind.low.good": "",
  "conditions.descriptions.harvesting.wind.low.moderate": "",
  "conditions.descriptions.harvesting.wind.low.poor": "",
  "conditions.descriptions.harvesting.wind.low.critical": "",
  "conditions.descriptions.sowing.temperature.high.optimal": "(Temperaturas óptimas) El crecimiento y el desarrollo de la planta serán más rápidos",
  "conditions.descriptions.sowing.temperature.high.good": "(Temperaturas cercanas a las óptimas) El crecimiento y desarrollo de la planta serán adecuados",
  "conditions.descriptions.sowing.temperature.high.moderate": "(Temperaturas moderadamente altas) El crecimiento y desarrollo de la planta serán lentos",
  "conditions.descriptions.sowing.temperature.high.poor": "(Temperaturas muy altas) El crecimiento y desarrollo de la planta se atrofiará",
  "conditions.descriptions.sowing.temperature.high.critical": "(Temperaturas letales para el cultivo) Las plántulas se marchitarán y morirán",
  "conditions.descriptions.sowing.temperature.low.optimal": "(Temperaturas óptimas) El crecimiento y desarrollo de las plantas será el más rápido",
  "conditions.descriptions.sowing.temperature.low.good": "(Temperaturas próximas a las óptimas) El crecimiento y el desarrollo de las plantas serán suficientes",
  "conditions.descriptions.sowing.temperature.low.moderate": "(Temperaturas moderadamente bajas) El crecimiento y el desarrollo de las plantas serán lentos",
  "conditions.descriptions.sowing.temperature.low.poor": "(Temperaturas muy bajas) El crecimiento y desarrollo de las plantas será muy lento o nulo y existe un riesgo importante de daños por heladas.",
  "conditions.descriptions.sowing.temperature.low.critical": "(Temperaturas letales para el cultivo) Las plántulas se marchitarán y morirán",
  "conditions.descriptions.sowing.precipitation.high.optimal": "(Lluvia mínima o nula) La obstrucción al tractor y al equipo es mínima o nula.",
  "conditions.descriptions.sowing.precipitation.high.good": "(Lluvia ligera) Hay poca obstrucción para el tractor y el equipo",
  "conditions.descriptions.sowing.precipitation.high.moderate": "(Lluvia ligera) Hay una ligera obstrucción para el tractor y el equipo",
  "conditions.descriptions.sowing.precipitation.high.poor": "(Lluvia ligera) Hay una ligera obstrucción para el tractor y el equipo",
  "conditions.descriptions.sowing.precipitation.high.critical": "(Lluvia ligera por encima del límite) Hay una obstrucción para el tractor y el equipo y riesgo de embarrado del suelo y pérdida de tracción",
  "conditions.descriptions.sowing.precipitation.low.optimal": "(Lluvia mínima o nula) La obstrucción para el tractor y el equipo es mínima o nula.",
  "conditions.descriptions.sowing.precipitation.low.good": "",
  "conditions.descriptions.sowing.precipitation.low.moderate": "",
  "conditions.descriptions.sowing.precipitation.low.poor": "",
  "conditions.descriptions.sowing.precipitation.low.critical": "",
  "conditions.descriptions.sowing.soil_temperature.high.optimal": "(Temperaturas óptimas) Las semillas germinan y se desarrollan más rápidamente.",
  "conditions.descriptions.sowing.soil_temperature.high.good": "(Temperaturas cercanas a las óptimas) La germinación y el desarrollo de las semillas serán adecuados",
  "conditions.descriptions.sowing.soil_temperature.high.moderate": "(Temperaturas moderadamente altas) La germinación y el desarrollo de las semillas serán lentos",
  "conditions.descriptions.sowing.soil_temperature.high.poor": "(Temperaturas muy altas) La germinación y el desarrollo de las semillas serán muy lentos o nulos y existe el riesgo de que mueran.",
  "conditions.descriptions.sowing.soil_temperature.high.critical": "(Temperaturas extremadamente altas) No hay germinación ni desarrollo de las semillas y es probable que mueran.",
  "conditions.descriptions.sowing.soil_temperature.low.optimal": "(Temperaturas óptimas) La germinación y el desarrollo de las semillas serán más rápidos.",
  "conditions.descriptions.sowing.soil_temperature.low.good": "(Temperaturas cercanas a las óptimas) La germinación y el desarrollo de las semillas serán suficientes.",
  "conditions.descriptions.sowing.soil_temperature.low.moderate": "(Temperaturas moderadamente bajas) La germinación y el desarrollo de las semillas serán lentos",
  "conditions.descriptions.sowing.soil_temperature.low.poor": "(Temperaturas muy bajas) La germinación y el desarrollo de las semillas serán muy lentos o nulos.",
  "conditions.descriptions.sowing.soil_temperature.low.critical": "(Temperaturas extremadamente bajas) No hay germinación ni desarrollo de las semillas",
  "conditions.descriptions.sowing.soil_moisture.high.optimal": "(Suelo a media capacidad) - El suelo está lo suficientemente húmedo para una germinación óptima de las semillas y lo suficientemente seco para evitar la compactación por el tractor.",
  "conditions.descriptions.sowing.soil_moisture.high.good": "(Suelo casi a la mitad de su capacidad) - El potencial de germinación de las semillas es óptimo, pero existe un riesgo creciente de compactación del suelo por el tractor",
  "conditions.descriptions.sowing.soil_moisture.high.moderate": "(Suelo húmedo) El potencial de germinación de las semillas es suficiente, pero también existe un riesgo importante de compactación del suelo por el tractor",
  "conditions.descriptions.sowing.soil_moisture.high.poor": "(Suelo húmedo muy próximo a la capacidad de campo) El potencial de germinación de las semillas es reducido en algunos cultivos y existe un riesgo elevado de compactación del suelo por el tractor.",
  "conditions.descriptions.sowing.soil_moisture.high.critical": "(Suelo húmedo a capacidad de campo) El potencial de germinación de las semillas se reduce en algunos cultivos y existe un riesgo muy alto de compactación del suelo por el tractor.",
  "conditions.descriptions.sowing.soil_moisture.low.optimal": "(Suelo a la mitad de su capacidad) - El suelo está lo suficientemente húmedo para una germinación óptima de las semillas y lo suficientemente seco para evitar la compactación por el tractor",
  "conditions.descriptions.sowing.soil_moisture.low.good": "(Suelo casi a la mitad de su capacidad) - El potencial de germinación de las semillas es suficiente",
  "conditions.descriptions.sowing.soil_moisture.low.moderate": "(Suelo seco) - El potencial de germinación de las semillas es bajo",
  "conditions.descriptions.sowing.soil_moisture.low.poor": "(Suelo muy seco, cerca del punto de marchitez) El potencial de germinación de las semillas es muy bajo",
  "conditions.descriptions.sowing.soil_moisture.low.critical": "(Suelo muy seco, en el punto de marchitez) El potencial de germinación de las semillas es mínimo",
  "conditions.descriptions.sowing.wind.high.optimal": "(Calmo) No hay riesgo de desplazamiento de semillas ni de irregularidades en la siembra",
  "conditions.descriptions.sowing.wind.high.good": "(Brisa ligera) Hay un riesgo mínimo de desplazamiento de semillas y de irregularidades en la siembra",
  "conditions.descriptions.sowing.wind.high.moderate": "(Brisa ligera) Existe un riesgo mínimo de desplazamiento de las semillas y de irregularidades en la siembra",
  "conditions.descriptions.sowing.wind.high.poor": "(Brisa moderada) Existe un riesgo mínimo de desplazamiento de las semillas y de irregularidades en la siembra",
  "conditions.descriptions.sowing.wind.high.critical": "(Viento lo suficientemente fuerte como para levantar un grano de trigo) Existe un riesgo importante de desplazamiento de las semillas y de irregularidades en la siembra",
  "conditions.descriptions.sowing.wind.low.optimal": "(Calmo) No hay riesgo de que se desplacen las semillas ni de que se produzcan irregularidades en la siembra.",
  "conditions.descriptions.sowing.wind.low.good": "",
  "conditions.descriptions.sowing.wind.low.moderate": "",
  "conditions.descriptions.sowing.wind.low.poor": "",
  "conditions.descriptions.sowing.wind.low.critical": "",
  "conditions.descriptions.mowing.temperature.high.optimal": "",
  "conditions.descriptions.mowing.temperature.high.good": "",
  "conditions.descriptions.mowing.temperature.high.moderate": "",
  "conditions.descriptions.mowing.temperature.high.poor": "",
  "conditions.descriptions.mowing.temperature.high.critical": "",
  "conditions.descriptions.mowing.temperature.low.optimal": "",
  "conditions.descriptions.mowing.temperature.low.good": "",
  "conditions.descriptions.mowing.temperature.low.moderate": "",
  "conditions.descriptions.mowing.temperature.low.poor": "",
  "conditions.descriptions.mowing.temperature.low.critical": "",
  "conditions.descriptions.mowing.precipitation.high.optimal": "(Lluvia escasa o nula) El tractor y el equipo no se ven obstaculizados o lo hacen de forma mínima.",
  "conditions.descriptions.mowing.precipitation.high.good": "(Lluvia ligera) El tractor y el equipo se ven ligeramente obstaculizados.",
  "conditions.descriptions.mowing.precipitation.high.moderate": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo",
  "conditions.descriptions.mowing.precipitation.high.poor": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo",
  "conditions.descriptions.mowing.precipitation.high.critical": "(Lluvia ligera por encima del límite) Hay una obstrucción para el tractor y el equipo y riesgo de embarrado del suelo y pérdida de tracción",
  "conditions.descriptions.mowing.precipitation.low.optimal": "(Lluvia escasa o nula) Hay una obstrucción mínima o nula para el tractor y el equipo",
  "conditions.descriptions.mowing.precipitation.low.good": "",
  "conditions.descriptions.mowing.precipitation.low.moderate": "",
  "conditions.descriptions.mowing.precipitation.low.poor": "",
  "conditions.descriptions.mowing.precipitation.low.critical": "",
  "conditions.descriptions.mowing.soil_temperature.high.optimal": "",
  "conditions.descriptions.mowing.soil_temperature.high.good": "",
  "conditions.descriptions.mowing.soil_temperature.high.moderate": "",
  "conditions.descriptions.mowing.soil_temperature.high.poor": "",
  "conditions.descriptions.mowing.soil_temperature.high.critical": "",
  "conditions.descriptions.mowing.soil_temperature.low.optimal": "",
  "conditions.descriptions.mowing.soil_temperature.low.good": "",
  "conditions.descriptions.mowing.soil_temperature.low.moderate": "",
  "conditions.descriptions.mowing.soil_temperature.low.poor": "",
  "conditions.descriptions.mowing.soil_temperature.low.critical": "",
  "conditions.descriptions.mowing.soil_moisture.high.optimal": "(Suelo muy seco en el punto de marchitez) Existe un riesgo mínimo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.mowing.soil_moisture.high.good": "(Suelo más seco) Existe un riesgo bajo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.mowing.soil_moisture.high.moderate": "(Suelo seco) Cierto riesgo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.mowing.soil_moisture.high.poor": "(Suelo húmedo) Alto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.mowing.soil_moisture.high.critical": "(Suelo muy húmedo en la capacidad de campo) Mayor riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.mowing.soil_moisture.low.optimal": "(Suelo muy seco en el punto de marchitez) Riesgo mínimo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.mowing.soil_moisture.low.good": "",
  "conditions.descriptions.mowing.soil_moisture.low.moderate": "",
  "conditions.descriptions.mowing.soil_moisture.low.poor": "",
  "conditions.descriptions.mowing.soil_moisture.low.critical": "",
  "conditions.descriptions.mowing.wind.high.optimal": "(Calmo) No hay riesgo de que la paja y los residuos se desplacen.",
  "conditions.descriptions.mowing.wind.high.good": "(Aire ligero) El riesgo de que la paja y los residuos se desprendan es mínimo.",
  "conditions.descriptions.mowing.wind.high.moderate": "(Brisa ligera) El riesgo de que la paja y los residuos floten es mínimo.",
  "conditions.descriptions.mowing.wind.high.poor": "(Brisa moderada) Existe cierto riesgo de que la paja y los residuos queden a la deriva",
  "conditions.descriptions.mowing.wind.high.critical": "(Brisa fresca) Las hojas empiezan a oscilar y existe un riesgo elevado de que la paja y los residuos queden flotando.",
  "conditions.descriptions.mowing.wind.low.optimal": "(Calmo) No hay riesgo de que la paja y los residuos queden flotando.",
  "conditions.descriptions.mowing.wind.low.good": "",
  "conditions.descriptions.mowing.wind.low.moderate": "",
  "conditions.descriptions.mowing.wind.low.poor": "",
  "conditions.descriptions.mowing.wind.low.critical": "",
  "conditions.descriptions.spraying.fine.temperature.high.optimal": "",
  "conditions.descriptions.spraying.fine.temperature.high.good": "",
  "conditions.descriptions.spraying.fine.temperature.high.moderate": "",
  "conditions.descriptions.spraying.fine.temperature.high.poor": "",
  "conditions.descriptions.spraying.fine.temperature.high.critical": "",
  "conditions.descriptions.spraying.fine.temperature.low.optimal": "",
  "conditions.descriptions.spraying.fine.temperature.low.good": "",
  "conditions.descriptions.spraying.fine.temperature.low.moderate": "",
  "conditions.descriptions.spraying.fine.temperature.low.poor": "",
  "conditions.descriptions.spraying.fine.temperature.low.critical": "",
  "conditions.descriptions.spraying.fine.precipitation.high.optimal": "(Sin lluvia) No hay obstrucción para el tractor y el equipo y no hay riesgo de escorrentía del producto.",
  "conditions.descriptions.spraying.fine.precipitation.high.good": "(Poca lluvia) Hay una obstrucción mínima para el tractor y el equipo y un riesgo mínimo de escorrentía del producto.",
  "conditions.descriptions.spraying.fine.precipitation.high.moderate": "(Poca lluvia) Hay una obstrucción mínima para el tractor y el equipo y un riesgo mínimo de escorrentía del producto.",
  "conditions.descriptions.spraying.fine.precipitation.high.poor": "(Lluvia ligera) La obstrucción al tractor y al equipo es mínima y el riesgo de escorrentía del producto es mínimo.",
  "conditions.descriptions.spraying.fine.precipitation.high.critical": "(Lluvia ligera por encima del límite) Hay obstrucción para el tractor y el equipo con riesgo de compactación del suelo y riesgo de escorrentía del producto.",
  "conditions.descriptions.spraying.fine.precipitation.low.optimal": "(Sin lluvia) No hay obstrucción para el tractor ni el equipo y no hay riesgo de escorrentía del producto.",
  "conditions.descriptions.spraying.fine.precipitation.low.good": "",
  "conditions.descriptions.spraying.fine.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.fine.precipitation.low.poor": "",
  "conditions.descriptions.spraying.fine.precipitation.low.critical": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.fine.soil_moisture.high.optimal": "(Suelo muy seco) Existe un riesgo mínimo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.spraying.fine.soil_moisture.high.good": "(Suelo más seco) Existe un riesgo bajo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.fine.soil_moisture.high.moderate": "(Suelo seco) Existe cierto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.fine.soil_moisture.high.poor": "(Suelo húmedo) Alto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.fine.soil_moisture.high.critical": "(Suelo muy húmedo a capacidad de campo) Máximo riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.fine.soil_moisture.low.optimal": "(Suelo muy seco) Riesgo mínimo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.spraying.fine.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.fine.wind.high.optimal": "(Aire ligero por encima del límite) Riesgo muy bajo de inversión térmica y riesgo muy bajo de deriva de la pulverización",
  "conditions.descriptions.spraying.fine.wind.high.good": "(Aire ligero por encima del límite) Muy bajo riesgo de inversión térmica y bajo riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.fine.wind.high.moderate": "(Brisa ligera) Existe un riesgo muy bajo de inversión de temperatura y cierto riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.fine.wind.high.poor": "(Brisa ligera) Alto riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.fine.wind.high.critical": "(Brisa ligera y superior) Existe un riesgo muy alto de deriva de la pulverización",
  "conditions.descriptions.spraying.fine.wind.low.optimal": "(Aire ligero por encima del límite) Existe un riesgo muy bajo de inversión de temperatura y un riesgo muy bajo de deriva de la pulverización.",
  "conditions.descriptions.spraying.fine.wind.low.good": "(Aire ligero) Existe cierto riesgo de inversión térmica y riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.fine.wind.low.moderate": "(Aire ligero) Existe cierto riesgo de inversión térmica y riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.fine.wind.low.poor": "(Aire ligero) Existe cierto riesgo de inversión térmica y riesgo de deriva de la pulverización.",
  "conditions.descriptions.spraying.fine.wind.low.critical": "(Calm air) Existe un alto riesgo de inversión térmica y un alto riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.medium.temperature.high.optimal": "",
  "conditions.descriptions.spraying.medium.temperature.high.good": "",
  "conditions.descriptions.spraying.medium.temperature.high.moderate": "",
  "conditions.descriptions.spraying.medium.temperature.high.poor": "",
  "conditions.descriptions.spraying.medium.temperature.high.critical": "",
  "conditions.descriptions.spraying.medium.temperature.low.optimal": "",
  "conditions.descriptions.spraying.medium.temperature.low.good": "",
  "conditions.descriptions.spraying.medium.temperature.low.moderate": "",
  "conditions.descriptions.spraying.medium.temperature.low.poor": "",
  "conditions.descriptions.spraying.medium.temperature.low.critical": "",
  "conditions.descriptions.spraying.medium.precipitation.high.optimal": "(Sin lluvia) No hay obstrucción para el tractor y el equipo y no hay riesgo de escorrentía del producto.",
  "conditions.descriptions.spraying.medium.precipitation.high.good": "(Poca lluvia) Hay una obstrucción mínima para el tractor y el equipo y un riesgo mínimo de escorrentía del producto.",
  "conditions.descriptions.spraying.medium.precipitation.high.moderate": "(Poca lluvia) La obstrucción al tractor y al equipo es mínima y el riesgo de escorrentía del producto es mínimo.",
  "conditions.descriptions.spraying.medium.precipitation.high.poor": "(Lluvia ligera) La obstrucción al tractor y al equipo es mínima y el riesgo de escorrentía del producto es mínimo.",
  "conditions.descriptions.spraying.medium.precipitation.high.critical": "(Lluvia ligera por encima del límite) Hay obstrucción para el tractor y el equipo con riesgo de compactación del suelo y riesgo de escorrentía del producto.",
  "conditions.descriptions.spraying.medium.precipitation.low.optimal": "(Sin lluvia) No hay obstrucción para el tractor ni el equipo y no hay riesgo de escorrentía del producto.",
  "conditions.descriptions.spraying.medium.precipitation.low.good": "",
  "conditions.descriptions.spraying.medium.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.medium.precipitation.low.poor": "",
  "conditions.descriptions.spraying.medium.precipitation.low.critical": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.medium.soil_moisture.high.optimal": "(Suelo muy seco) Existe un riesgo mínimo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.spraying.medium.soil_moisture.high.good": "(Suelo más seco) Existe un riesgo bajo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.medium.soil_moisture.high.moderate": "(Suelo seco) Existe cierto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.medium.soil_moisture.high.poor": "(Suelo húmedo) Alto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.medium.soil_moisture.high.critical": "(Suelo muy húmedo a capacidad de campo) Máximo riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.medium.soil_moisture.low.optimal": "(Suelo muy seco) Existe un riesgo mínimo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.medium.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.medium.wind.high.optimal": "(Aire ligero) Muy bajo riesgo de inversión térmica y bajo riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.medium.wind.high.good": "(Aire ligero) Existe un riesgo muy bajo de inversión de temperatura y un riesgo bajo de deriva de la pulverización.",
  "conditions.descriptions.spraying.medium.wind.high.moderate": "(Brisa ligera) Existe un riesgo muy bajo de inversión térmica y cierto riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.medium.wind.high.poor": "(Brisa ligera) Existe un riesgo alto de deriva de la pulverización",
  "conditions.descriptions.spraying.medium.wind.high.critical": "(Brisa ligera) Existe un riesgo muy alto de deriva de la pulverización",
  "conditions.descriptions.spraying.medium.wind.low.optimal": "(Aire ligero) Existe un riesgo muy bajo de inversión de temperatura y un riesgo bajo de deriva de la pulverización.",
  "conditions.descriptions.spraying.medium.wind.low.good": "(Calmo a aire ligero) Hay riesgo de inversión térmica pero bajo riesgo de deriva de la pulverización.",
  "conditions.descriptions.spraying.medium.wind.low.moderate": "",
  "conditions.descriptions.spraying.medium.wind.low.poor": "",
  "conditions.descriptions.spraying.medium.wind.low.critical": "",
  "conditions.descriptions.spraying.coarse.temperature.high.optimal": "",
  "conditions.descriptions.spraying.coarse.temperature.high.good": "",
  "conditions.descriptions.spraying.coarse.temperature.high.moderate": "",
  "conditions.descriptions.spraying.coarse.temperature.high.poor": "",
  "conditions.descriptions.spraying.coarse.temperature.high.critical": "",
  "conditions.descriptions.spraying.coarse.temperature.low.optimal": "",
  "conditions.descriptions.spraying.coarse.temperature.low.good": "",
  "conditions.descriptions.spraying.coarse.temperature.low.moderate": "",
  "conditions.descriptions.spraying.coarse.temperature.low.poor": "",
  "conditions.descriptions.spraying.coarse.temperature.low.critical": "",
  "conditions.descriptions.spraying.coarse.precipitation.high.optimal": "(Sin lluvia) No hay obstrucción para el tractor y el equipo y no hay riesgo de escorrentía del producto.",
  "conditions.descriptions.spraying.coarse.precipitation.high.good": "(Poca lluvia) Hay una obstrucción mínima para el tractor y el equipo y un riesgo mínimo de escorrentía del producto.",
  "conditions.descriptions.spraying.coarse.precipitation.high.moderate": "(Poca lluvia) La obstrucción del tractor y del equipo es mínima y el riesgo de escorrentía del producto es mínimo.",
  "conditions.descriptions.spraying.coarse.precipitation.high.poor": "(Lluvia ligera) La obstrucción al tractor y al equipo es mínima y el riesgo de escorrentía del producto es mínimo.",
  "conditions.descriptions.spraying.coarse.precipitation.high.critical": "(Lluvia ligera por encima del límite) Hay obstrucción para el tractor y el equipo con riesgo de compactación del suelo y riesgo de escorrentía del producto.",
  "conditions.descriptions.spraying.coarse.precipitation.low.optimal": "(Sin lluvia) No hay obstrucción para el tractor ni el equipo y no hay riesgo de escorrentía del producto.",
  "conditions.descriptions.spraying.coarse.precipitation.low.good": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.poor": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.critical": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.optimal": "(Suelo muy seco) Existe un riesgo mínimo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.good": "(Suelo más seco) Existe un riesgo bajo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.moderate": "(Suelo seco) Existe cierto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.poor": "(Suelo húmedo) Alto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.critical": "(Suelo muy húmedo a capacidad de campo) Máximo riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.optimal": "(Suelo muy seco) Existe un riesgo mínimo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.coarse.wind.high.optimal": "(Aire ligero) Hay muy poco riesgo de inversión térmica y muy poco o ningún riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.coarse.wind.high.good": "(Aire ligero) Muy bajo riesgo de inversión térmica y bajo riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.coarse.wind.high.moderate": "(Brisa ligera) Hay muy poco riesgo de inversión térmica y poco riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.coarse.wind.high.poor": "(Brisa ligera) Cierto riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.coarse.wind.high.critical": "(Brisa moderada) Alto riesgo de deriva de la pulverización",
  "conditions.descriptions.spraying.coarse.wind.low.optimal": "(Aire ligero) Hay muy poco riesgo de inversión térmica y muy poco o ningún riesgo de deriva de la pulverización.",
  "conditions.descriptions.spraying.coarse.wind.low.good": "(Calmo a aire ligero) Existe riesgo de inversión térmica pero muy bajo riesgo de deriva de la pulverización.",
  "conditions.descriptions.spraying.coarse.wind.low.moderate": "",
  "conditions.descriptions.spraying.coarse.wind.low.poor": "",
  "conditions.descriptions.spraying.coarse.wind.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.optimal": "(Lluvia mínima o nula) Hay una obstrucción mínima o nula para el tractor y el equipo",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.good": "(Lluvia ligera) El tractor y el equipo están ligeramente obstruidos.",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.moderate": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.poor": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.critical": "(Lluvia ligera por encima del límite) Existe una obstrucción para el tractor y el equipo y riesgo de embarrado del suelo y pérdida de tracción",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.optimal": "(Lluvia mínima o nula) Hay una obstrucción mínima o nula para el tractor y el equipo",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.optimal": "(Suelo muy seco) Existe un riesgo mínimo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.good": "(Suelo más seco) El riesgo de compactación del suelo, daños al suelo y atasco del tractor es bajo.",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.moderate": "(Suelo seco) Cierto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.poor": "(Suelo húmedo) Alto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.critical": "(Suelo muy húmedo a capacidad de campo) Mayor riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.optimal": "(Suelo muy seco) Riesgo mínimo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.optimal": "(Calmo) No hay riesgo de deriva del producto y se maximiza la eficacia de la aplicación",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.good": "(Aire ligero) El riesgo de deriva del producto es mínimo y la eficacia de la aplicación es alta.",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.moderate": "(Brisa ligera) Existe riesgo de deriva del producto y la eficacia de la aplicación puede verse reducida.",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.poor": "(Brisa moderada) Existe un alto riesgo de deriva del producto y la eficacia de la aplicación se verá reducida.",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.critical": "(Brisa fresca) Existe un riesgo muy alto de deriva del producto y la eficacia de la aplicación se verá muy reducida.",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.optimal": "(Calmo) No hay riesgo de deriva del producto y la eficacia de la aplicación es máxima.",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.optimal": "(Lluvia escasa o nula) La obstrucción al tractor y al equipo es mínima o nula y el riesgo de lixiviación del producto es mínimo.",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.good": "(Lluvia ligera) Hay una ligera obstrucción al tractor y al equipo y un ligero riesgo de lixiviación del producto",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.moderate": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo y un pequeño riesgo de lixiviación del producto.",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.poor": "(Lluvia ligera) Existe una pequeña obstrucción para el tractor y el equipo y un pequeño riesgo de lixiviación del producto",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.critical": "(Lluvia ligera por encima del límite) Existe una obstrucción para el tractor y el equipo, un riesgo de embadurnamiento del suelo y pérdida de tracción y un alto riesgo de lixiviación del producto",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.optimal": "(Lluvia escasa o nula) Las obstrucciónes para el tractor y el equipo son mínimos y el riesgo de lixiviación de los productos es mínimo.",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.optimal": "(Suelo muy seco) Existe un riesgo mínimo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.good": "(Suelo más seco) Existe un riesgo bajo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.moderate": "(Suelo seco) Cierto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.poor": "(Suelo húmedo) Alto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.critical": "(Suelo muy húmedo a capacidad de campo) Mayor riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.optimal": "(Suelo muy seco) Riesgo mínimo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.optimal": "(Calmo) No hay riesgo de deriva del producto y se maximiza la eficacia de la aplicación",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.good": "(Aire ligero) Existe un riesgo mínimo de deriva del producto y la eficacia de la aplicación es alta",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.moderate": "(Brisa ligera) Existe riesgo de deriva del producto y la eficacia de la aplicación puede verse reducida",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.poor": "(Brisa ligera) Existe un riesgo elevado de deriva del producto y la eficacia de la aplicación es reducida",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.critical": "(Brisa moderada o más) Existe un riesgo muy alto de deriva del producto y la eficacia de la aplicación se verá muy reducida",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.optimal": "(Calmo) No hay riesgo de deriva del producto y la eficacia de la aplicación es máxima.",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.optimal": "(Lluvia escasa o nula) La obstrucción al tractor y al equipo es mínima o nula y el riesgo de deriva del producto es mínimo.",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.good": "(Lluvia ligera) Hay una ligera obstrucción al tractor y al equipo y un ligero riesgo de fuga del producto",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.moderate": "(Lluvia ligera) Hay una ligera obstrucción al tractor y al equipo equipo y un pequeño riesgo de fuga del producto.",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.poor": "(Lluvia ligera) Existe una pequeña obstrucción para el tractor y el equipo y un pequeño riesgo de filtración del producto",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.critical": "(Lluvia ligera por encima del límite) Existe una obstrucción para el tractor y el equipo, un riesgo de embadurnamiento del suelo y pérdida de tracción y un alto riesgo de lixiviación del producto",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.optimal": "(Lluvia escasa o nula) Las obstrucciónes para el tractor y el equipo son mínimos y el riesgo de lixiviación de los productos es mínimo.",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.optimal": "(Suelo muy seco) Existe un riesgo mínimo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.good": "(Suelo más seco) Existe un riesgo bajo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.moderate": "(Suelo seco) Cierto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.poor": "(Suelo húmedo) Alto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.critical": "(Suelo muy húmedo a capacidad de campo) Mayor riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.optimal": "(Suelo muy seco) Riesgo mínimo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.critical": "",
  "conditions.descriptions.tilling.temperature.high.optimal": "",
  "conditions.descriptions.tilling.temperature.high.good": "",
  "conditions.descriptions.tilling.temperature.high.moderate": "",
  "conditions.descriptions.tilling.temperature.high.poor": "",
  "conditions.descriptions.tilling.temperature.high.critical": "",
  "conditions.descriptions.tilling.temperature.low.optimal": "",
  "conditions.descriptions.tilling.temperature.low.good": "",
  "conditions.descriptions.tilling.temperature.low.moderate": "",
  "conditions.descriptions.tilling.temperature.low.poor": "",
  "conditions.descriptions.tilling.temperature.low.critical": "",
  "conditions.descriptions.tilling.precipitation.high.optimal": "(Lluvia mínima o nula) Hay una obstrucción mínima o nula para el tractor y el equipo",
  "conditions.descriptions.tilling.precipitation.high.good": "(Lluvia ligera) Hay poca obstrucción para el tractor y el equipo",
  "conditions.descriptions.tilling.precipitation.high.moderate": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo",
  "conditions.descriptions.tilling.precipitation.high.poor": "(Lluvia ligera) Hay una ligera obstrucción para el tractor y el equipo",
  "conditions.descriptions.tilling.precipitation.high.critical": "(Lluvia ligera por encima del límite) Hay una obstrucción para el tractor y el equipo y riesgo de embadurnamiento del suelo y pérdida de tracción",
  "conditions.descriptions.tilling.precipitation.low.optimal": "(Lluvia mínima o nula) Existe una obstrucción mínima o nulo para el tractor y el equipo",
  "conditions.descriptions.tilling.precipitation.low.good": "",
  "conditions.descriptions.tilling.precipitation.low.moderate": "",
  "conditions.descriptions.tilling.precipitation.low.poor": "",
  "conditions.descriptions.tilling.precipitation.low.critical": "",
  "conditions.descriptions.tilling.soil_temperature.high.optimal": "",
  "conditions.descriptions.tilling.soil_temperature.high.good": "",
  "conditions.descriptions.tilling.soil_temperature.high.moderate": "",
  "conditions.descriptions.tilling.soil_temperature.high.poor": "",
  "conditions.descriptions.tilling.soil_temperature.high.critical": "",
  "conditions.descriptions.tilling.soil_temperature.low.optimal": "",
  "conditions.descriptions.tilling.soil_temperature.low.good": "",
  "conditions.descriptions.tilling.soil_temperature.low.moderate": "",
  "conditions.descriptions.tilling.soil_temperature.low.poor": "",
  "conditions.descriptions.tilling.soil_temperature.low.critical": "",
  "conditions.descriptions.tilling.soil_moisture.high.optimal": "(Humedad del suelo a la mitad de su capacidad) El laboreo es seguro en la mayoría de los tipos de suelo y dará como resultado un tamaño de terrón adecuado, un consumo de combustible reducido y un riesgo bajo de embadurnamiento y compactación del suelo.",
  "conditions.descriptions.tilling.soil_moisture.high.good": "(Humedad del suelo casi a la mitad de su capacidad) El laboreo es fácil, con un bajo consumo de combustible y un riesgo muy bajo de embadurnamiento y compactación del suelo.",
  "conditions.descriptions.tilling.soil_moisture.high.moderate": "(Suelo húmedo) El laboreo es aceptable, aunque existe cierto riesgo de emborronamiento y compactación del suelo.",
  "conditions.descriptions.tilling.soil_moisture.high.poor": "(Suelo húmedo cercano a la capacidad de campo) El laboreo será ineficaz con un alto riesgo de emborronamiento y compactación del suelo.",
  "conditions.descriptions.tilling.soil_moisture.high.critical": "(Humedad del suelo igual o superior a la capacidad de campo) El laboreo no es aconsejable, con el mayor riesgo de emborronamiento y compactación del suelo",
  "conditions.descriptions.tilling.soil_moisture.low.optimal": "(Humedad del suelo a la mitad de su capacidad) El laboreo es seguro en la mayoría de los tipos de suelo y dará lugar a un tamaño adecuado de los terrones, un menor consumo de combustible y un bajo riesgo de embadurnamiento y compactación del suelo.",
  "conditions.descriptions.tilling.soil_moisture.low.good": "(Humedad del suelo casi a la mitad de su capacidad) El laboreo es fácil con un bajo consumo de combustible",
  "conditions.descriptions.tilling.soil_moisture.low.moderate": "(Suelo seco) El fresado es aceptable con un consumo medio de combustible",
  "conditions.descriptions.tilling.soil_moisture.low.poor": "(Suelo seco cerca del punto de marchitez) El laboreo será duro con un elevado consumo de combustible y es probable la pulverización en partículas finas o terrones grandes.",
  "conditions.descriptions.tilling.soil_moisture.low.critical": "(Suelo seco en el punto de marchitez) El laboreo será muy duro con el mayor consumo de combustible y es muy probable la pulverización en partículas finas o terrones grandes.",
  "conditions.descriptions.tilling.wind.high.optimal": "(Calmo) No hay riesgo de erosión eólica del suelo",
  "conditions.descriptions.tilling.wind.high.good": "(Brisa ligera o ligera) Existe un riesgo mínimo de erosión del suelo por el viento",
  "conditions.descriptions.tilling.wind.high.moderate": "(Brisa moderada) Existe un riesgo mínimo de erosión del suelo por el viento",
  "conditions.descriptions.tilling.wind.high.poor": "(Brisa fuerte) Existe un riesgo significativo de erosión del suelo por el viento",
  "conditions.descriptions.tilling.wind.high.critical": "(Racha superior a moderada) Existe una alta probabilidad de erosión eólica del suelo",
  "conditions.descriptions.tilling.wind.low.optimal": "(Calmo) No hay riesgo de erosión eólica del suelo",
  "conditions.descriptions.tilling.wind.low.good": "",
  "conditions.descriptions.tilling.wind.low.moderate": "",
  "conditions.descriptions.tilling.wind.low.poor": "",
  "conditions.descriptions.tilling.wind.low.critical": "",
  "conditions.descriptions.pressing.temperature.high.optimal": "",
  "conditions.descriptions.pressing.temperature.high.good": "",
  "conditions.descriptions.pressing.temperature.high.moderate": "",
  "conditions.descriptions.pressing.temperature.high.poor": "",
  "conditions.descriptions.pressing.temperature.high.critical": "",
  "conditions.descriptions.pressing.temperature.low.optimal": "",
  "conditions.descriptions.pressing.temperature.low.good": "",
  "conditions.descriptions.pressing.temperature.low.moderate": "",
  "conditions.descriptions.pressing.temperature.low.poor": "",
  "conditions.descriptions.pressing.temperature.low.critical": "",
  "conditions.descriptions.pressing.precipitation.high.optimal": "(Lluvia escasa o nula) Hay una obstrucción mínima o nula para el tractor y el equipo",
  "conditions.descriptions.pressing.precipitation.high.good": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo",
  "conditions.descriptions.pressing.precipitation.high.moderate": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo",
  "conditions.descriptions.pressing.precipitation.high.poor": "(Lluvia ligera) Hay una pequeña obstrucción para el tractor y el equipo",
  "conditions.descriptions.pressing.precipitation.high.critical": "(Lluvia ligera por encima del límite) Hay una obstrucción para el tractor y el equipo y riesgo de embarrado del suelo y pérdida de tracción",
  "conditions.descriptions.pressing.precipitation.low.optimal": "(Lluvia escasa o nula) La obstrucción para el tractor y el equipo es mínimo o nulo.",
  "conditions.descriptions.pressing.precipitation.low.good": "",
  "conditions.descriptions.pressing.precipitation.low.moderate": "",
  "conditions.descriptions.pressing.precipitation.low.poor": "",
  "conditions.descriptions.pressing.precipitation.low.critical": "",
  "conditions.descriptions.pressing.soil_temperature.high.optimal": "",
  "conditions.descriptions.pressing.soil_temperature.high.good": "",
  "conditions.descriptions.pressing.soil_temperature.high.moderate": "",
  "conditions.descriptions.pressing.soil_temperature.high.poor": "",
  "conditions.descriptions.pressing.soil_temperature.high.critical": "",
  "conditions.descriptions.pressing.soil_temperature.low.optimal": "",
  "conditions.descriptions.pressing.soil_temperature.low.good": "",
  "conditions.descriptions.pressing.soil_temperature.low.moderate": "",
  "conditions.descriptions.pressing.soil_temperature.low.poor": "",
  "conditions.descriptions.pressing.soil_temperature.low.critical": "",
  "conditions.descriptions.pressing.soil_moisture.high.optimal": "(Suelo muy seco en el punto de marchitez) Existe un riesgo mínimo de compactación del suelo, daños en el suelo y atasco del tractor.",
  "conditions.descriptions.pressing.soil_moisture.high.good": "(Suelo más seco) Existe un riesgo bajo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.pressing.soil_moisture.high.moderate": "(Suelo seco) Cierto riesgo de compactación del suelo, daños al suelo y atasco del tractor",
  "conditions.descriptions.pressing.soil_moisture.high.poor": "(Suelo húmedo) Alto riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.pressing.soil_moisture.high.critical": "(Suelo muy húmedo en la capacidad de campo) Mayor riesgo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.pressing.soil_moisture.low.optimal": "(Suelo muy seco en el punto de marchitez) Riesgo mínimo de compactación del suelo, daños en el suelo y atasco del tractor",
  "conditions.descriptions.pressing.soil_moisture.low.good": "",
  "conditions.descriptions.pressing.soil_moisture.low.moderate": "",
  "conditions.descriptions.pressing.soil_moisture.low.poor": "",
  "conditions.descriptions.pressing.soil_moisture.low.critical": "",
  "conditions.descriptions.pressing.wind.high.optimal": "(Calmo) No hay riesgo de que la paja y los residuos se desplacen.",
  "conditions.descriptions.pressing.wind.high.good": "(Aire ligero) El riesgo de que la paja y los residuos se desprendan es mínimo.",
  "conditions.descriptions.pressing.wind.high.moderate": "(Brisa ligera) El riesgo de que la paja y los residuos floten es mínimo.",
  "conditions.descriptions.pressing.wind.high.poor": "(Brisa moderada) -Existe cierto riesgo de que la paja y los residuos queden a la deriva",
  "conditions.descriptions.pressing.wind.high.critical": "(Brisa fresca) Las hojas empiezan a oscilar y existe un riesgo elevado de que la paja y los residuos queden flotando.",
  "conditions.descriptions.pressing.wind.low.optimal": "(Calmo) No hay riesgo de que la paja y los residuos queden flotando.",
  "conditions.descriptions.pressing.wind.low.good": "",
  "conditions.descriptions.pressing.wind.low.moderate": "",
  "conditions.descriptions.pressing.wind.low.poor": "",
  "conditions.descriptions.pressing.wind.low.critical": "",
};
