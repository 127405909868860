import type { en } from "./en";

export const da: typeof en = {
  "page.map.button.mapStyle.map": "Kort",
  "page.map.button.mapStyle.satellite": "Satellit",
  "page.map.notification.message.karlDoesNotWorkInThisAreaYet": "Automatisk tegnede markgrænser er endnu ikke understøttet i dette område. Tegn den manuelt.",
  "page.map.notification.title.fieldShouldAppearInAMoment": "Marken er tilføjet.",
  "page.map.notification.message.fieldShouldAppearInAMoment": "Den skulle dukke op på kortet om et par sekunder.",
  "page.map.fieldLoadingStatus.text": "Behandler markdata...",
  "page.map.overlay.button.clear": "Ryd",
  "page.map.overlay.button.undo": "Fortryd",
  "page.map.overlay.button.continue": "Fortsæt",
  "page.map.overlay.button.automatic.text": "Automatisk",
  "page.map.overlay.button.automatic.tooltip": "I nogle områder kan KARL automatisk tegne markgrænser. Klik blot på en mark og se grænsen. Hvis grænsen er forkert, eller hvis området ikke understøttes, kan du tegne den manuelt.",
  "page.map.overlay.button.manual.text": "Manuel",
  "page.map.overlay.button.manual.tooltip": "Tegn dine markgrænser ved at klikke på kortet, indtil feltet er korrekt fremhævet, og vælg derefter knappen Fortsæt nederst på skærmen.",

  "page.users.heading": "Brugere",
  "page.users.iconButton.addUser.ariaLabel": "Tilføj bruger",
  "page.users.userId": "Bruger ID",
  "page.users.phone": "Telefon",
  "page.users.email": "Email",
  "page.users.emailVerified": "Email verificeret",
  "page.users.emailVerified.yes": "Ja",
  "page.users.emailVerified.no": "Nej",
  "page.users.employment": "Ansættelsesforhold",
  "page.users.employment.external": "Ekstern",
  "page.users.employment.internal": "Intern",
  "page.users.addUserDrawer.heading": "Tilføj bruger",
  "page.users.addUserDrawer.input.firstName": "Fornavn",
  "page.users.addUserDrawer.input.lastName": "Efternavn",
  "page.users.addUserDrawer.input.email": "Email",
  "page.users.addUserDrawer.checkbox.userCategory.label": "Ansættelsesforhold",
  "page.users.addUserDrawer.checkbox.userCategory.text": "Ekstern",
  "page.users.addUserDrawer.input.phone": "Telefon",
  "page.users.addUserDrawer.button.submit": "Tilføj",

  "page.machines.heading": "Maskiner & Redskaber",
  "page.machines.iconButton.addMachine.ariaLabel": "Tilføj maskine eller redskab",
  "page.machines.machineId": "Maskine ID",
  "page.machines.brand": "Mærke",
  "page.machines.model": "Model",
  "page.machines.yearProduced": "Årgang",
  "page.machines.description": "Beskrivelse",
  "page.machines.category": "Kategori",
  "page.machines.type": "Type",
  "page.machines.type.machine": "Maskine",
  "page.machines.type.equipment": "Redskab",
  "page.machines.addMachineDrawer.heading": "Tilføj maskine eller redskab",
  "page.machines.addMachineDrawer.input.brand": "Mærke",
  "page.machines.addMachineDrawer.input.model": "Model",
  "page.machines.addMachineDrawer.input.yearProduced": "Årgang",
  "page.machines.addMachineDrawer.input.description": "Beskrivelse",
  "page.machines.addMachineDrawer.select.type": "Type",
  "page.machines.addMachineDrawer.select.type.machine": "Maskine",
  "page.machines.addMachineDrawer.select.type.equipment": "Redskab",
  "page.machines.addMachineDrawer.input.category": "Kategori",
  "page.machines.addMachineDrawer.button.submit": "Tilføj",

  "page.profile.heading.generalInformation": "Generel information",
  "page.profile.heading.settings": "Indstillinger",
  "page.profile.heading.legal": "Juridisk",
  "page.profile.heading.feedback": "Feedback",

  "page.profile.legal.termsAndConditions": "Vilkår og betingelser",
  "page.profile.legal.privacyPolicy": "Privatlivspolitik",

  "page.profile.feedback.description": "Vi forsøger altid at forbedre os, og vi vil elske at høre din feedback.",
  "page.profile.feedback.button.submit": "Giv feedback",

  "page.profile.enterpriseId": "Virksomhed ID",
  "page.profile.userId": "Bruger ID",
  "page.profile.username": "Brugernavn",
  "page.profile.userEmail": "Bruger email",
  "page.profile.language": "Sprog",
  "page.profile.label.systemOfMeasurement": "Målesystem",
  "page.profile.systemOfMeasurement.metric": "Metrisk",
  "page.profile.systemOfMeasurement.imperial": "Imperial",
  "page.profile.label.fieldColoring": "Farvelægning af marker",
  "page.profile.fieldColoring.simple": "Simpel",
  "page.profile.fieldColoring.detailed": "Detaljeret",
  "page.profile.logOut": "Log ud",

  "page.calendar.week": "Uge",
  "page.calendar.today": "Idag",
  "page.calendar.days.1": "1 dag",
  "page.calendar.days.3": "3 dage",
  "page.calendar.days.7": "1 uge",
  "page.calendar.iconButton.addTask.ariaLabel": "Tileføj opgave",
  "page.calendar.button.filters": "Filtre",
  "page.calendar.filters.label.fieldGroups": "Markgrupper",
  "page.calendar.filters.placeholder.fieldGroups": "Filtrer efter markgrupper",
  "page.calendar.filters.label.fields": "Fields",
  "page.calendar.filters.label.tasks": "Opgaver",
  "page.calendar.filters.label.users": "Users",
  "page.calendar.filters.label.machines": "Machines and equipment",
  "page.calendar.filters.button.clearFilters": "Ryd filtre",
  "page.calendar.showFieldConditions": "Vis markforhold",
  "page.calendar.colorTasksPerAssociatedUsers.text": "Farvelæg opgaver efter tilknyttede medarbejdere",
  "page.calendar.startCalendarOnSelectedDate.text": "Start kalender på valgt dato",

  "page.index.heading": "Velkommen til KARL!",
  "page.index.mobileWarning.Heading": "Ups, KARL er endnu ikke understøttet på mobil!",
  "page.index.mobileWarning.Paragraph": "Indtil da kan du nyde KARL på din computer eller tablet. Tilmeld dig vores nyhedsbrev for at få besked, når vores mobilapp er klar 🚀",
  "page.index.login": "Kom i gang",

  "page.createEnterprise.heading": "Opret virksomhed",
  "page.createEnterprise.description": "Indtast venligst følgende oplysninger for at komme igang.",
  "page.createEnterprise.personalFirstNameInput.label": "Dit fornavn",
  "page.createEnterprise.personalLastNameInput.label": "Dit efternavn",
  "page.createEnterprise.companyNameInput.label": "Virksomhedsnavn",
  "page.createEnterprise.cvrInput.label": "CVR",
  "page.createEnterprise.buttonText": "Go!",

  "component.AddTaskDrawer.heading": "Tilføj opgave",
  "component.AddTaskDrawer.buttons.cancel": "Fortryd",
  "component.AddTaskDrawer.buttons.add": "Tilføj",

  "component.ConditionBadge.conditionLevel.optimal": "Optimal",
  "component.ConditionBadge.conditionLevel.good": "God",
  "component.ConditionBadge.conditionLevel.moderate": "Moderat",
  "component.ConditionBadge.conditionLevel.poor": "Dårlig",
  "component.ConditionBadge.conditionLevel.critical": "Kritisk",

  "component.ConditionBadge.typeLabel.precipitation": "Nedbør",
  "component.ConditionBadge.typeLabel.temperature": "Temperatur",
  "component.ConditionBadge.typeLabel.soilTemperature": "Jordtemperatur",
  "component.ConditionBadge.typeLabel.soilMoisture": "Jordfugtighed",
  "component.ConditionBadge.typeLabel.clouds": "Skyer",
  "component.ConditionBadge.typeLabel.wind": "Vind",
  "component.ConditionBadge.typeLabel.gusts": "Vindstød",

  "component.EditTaskDrawer.heading": "Detaljer",
  "component.EditTaskDrawer.tabEditHeading": "Rediger",
  "component.EditTaskDrawer.conditionsNotSupportedForThisTaskType": "Markforhold er ikke understøttet for denne opgave",
  "component.EditTaskDrawer.tabConditionsHeading": "Markforhold",
  "component.EditTaskDrawer.buttons.delete": "Slet",
  "component.EditTaskDrawer.buttons.cancel": "Fortryd",
  "component.EditTaskDrawer.buttons.apply": "Anvend",

  "component.AddFieldDrawerContent.heading.addField": "Tilføj mark",
  "component.AddFieldDrawerContent.inputLabel.fieldName": "Marknavn",
  "component.AddFieldDrawerContent.inputLabel.secondaryName": "Sekundært navn",
  "component.AddFieldDrawerContent.inputLabel.fieldGroup": "Gruppe",
  "component.AddFieldDrawerContent.label.boundary": "Markgrænse",
  "component.AddFieldDrawerContent.label.noBoundaryDrawn": "Ingen markgrænse tegnet",
  "component.AddFieldDrawerContent.button.drawFieldManually": "Tegn markgrænse manuelt",
  "component.AddFieldDrawerContent.button.finish": "Afslut",
  "component.AddFieldDrawerContent.heading.automaticallyDrawnFieldBoundaries": "Automatisk tegnede markgrænser",
  "component.AddFieldDrawerContent.content.automaticallyDrawnFieldBoyndaries": "Du kan blot klikke på din mark på kortet til højre for at få dens grænse automatisk tegnet.",
  "component.AddFieldDrawerContent.heading.manuallyDrawnFieldBoundaries": "Manuelt tegnede markgrænser",
  "component.AddFieldDrawerContent.content.manuallyDrawnFieldBoundaries": "Hvis du finder de automatisk tegnede grænser unøjagtige eller ikke tilgængelige i dit område, kan du tegne markgrænsen manuelt. For at gøre dette skal du klikke på knappen ovenfor og derefter klikke på kortet for at tegne markgrænsen. Du kan tilføje så mange punkter, som du vil. For at afslutte tegningen skal du klikke på knappen igen.",
  "component.AddFieldDrawerContent.button.cancel": "Fortryd",
  "component.AddFieldDrawerContent.button.add": "Tilføj",

  "component.FieldsDrawerContent.heading": "Marker",
  "component.FieldsDrawerContent.filterByFieldGroupPlaceholder": "Filtrer efter gruppe",
  "component.FieldsDrawerContent.areaUnit": "ha",
  "component.FieldsDrawerContent.areaUnit__imperial": "acres",
  "component.FieldsDrawerContent.button.addField": "Tilføj mark",
  "component.FieldsDrawerContent.button.cancel": "Fortryd",
  "component.FieldsDrawerContent.toggleIrrigationColoring": "Farvelæg vanding",
  "component.FieldsDrawerContent.toggleFieldColoring": "Farvelæg marker",

  "component.FieldDrawerContent.heading": "Mark",
  "component.FieldDrawerContent.tab.weather": "Vejr",
  "component.FieldDrawerContent.tab.tasks": "Opgaver",
  "component.FieldDrawerContent.tab.info": "Info",
  "component.FieldDrawerContent.tab.edit": "Rediger",

  "component.FieldDrawerWeatherTabContent.chartName.precipitation": "Nedbør",
  "component.FieldDrawerWeatherTabContent.chartName.airTemperature": "Lufttemperatur",
  "component.FieldDrawerWeatherTabContent.chartName.soilMoisture": "Jordfugtighed",
  "component.FieldDrawerWeatherTabContent.chartName.soilTemperature": "Jordtemperatur",
  "component.FieldDrawerWeatherTabContent.chartName.windGusts": "Vind og vindstød",
  "component.FieldDrawerWeatherTabContent.chartName.cloudCoverage": "Skydække",
  "component.FieldDrawerWeatherTabContent.chartName.humidity": "Luftfugtighed",
  "component.FieldDrawerWeatherTabContent.series.p1h": "Nedbør (mm/time)",
  "component.FieldDrawerWeatherTabContent.series.at": "Lufttemperatur (°C)",
  "component.FieldDrawerWeatherTabContent.series.sm0_5": "Jordfugtighed 0-5cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm5_15": "Jordfugtighed 5-15cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm15_30": "Jordfugtighed 15-30cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm30_60": "Jordfugtighed 30-60cm (%)",
  "component.FieldDrawerWeatherTabContent.series.sm60_100": "Jordfugtighed 60-100cm (%)",
  "component.FieldDrawerWeatherTabContent.series.st0": "Jordtemperatur 0cm (°C)",
  "component.FieldDrawerWeatherTabContent.series.st10": "Jordtemperatur 10cm (°C)",
  "component.FieldDrawerWeatherTabContent.series.gs": "Vindstød (m/s)",
  "component.FieldDrawerWeatherTabContent.series.ws": "Vind (m/s)",
  "component.FieldDrawerWeatherTabContent.series.p1h__imperial": "Nedbør (in/time)",
  "component.FieldDrawerWeatherTabContent.series.at__imperial": "Lufttemperatur (°F)",
  "component.FieldDrawerWeatherTabContent.series.sm0_5__imperial": 'Jordfugtighed 0-1.97" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm5_15__imperial": 'Jordfugtighed 1.97-5.91" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm15_30__imperial": 'Jordfugtighed 5.91-11.81" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm30_60__imperial": 'Jordfugtighed 11.81-23.62" (%)',
  "component.FieldDrawerWeatherTabContent.series.sm60_100__imperial": 'Jordfugtighed 23.62-39.37" (%)',
  "component.FieldDrawerWeatherTabContent.series.st0__imperial": 'Jordtemperatur 0" (°F)',
  "component.FieldDrawerWeatherTabContent.series.st10__imperial": 'Jordtemperatur 3.94" (°F)',
  "component.FieldDrawerWeatherTabContent.series.gs__imperial": "Vindstød (mph)",
  "component.FieldDrawerWeatherTabContent.series.ws__imperial": "Vind (mph)",
  "component.FieldDrawerWeatherTabContent.series.c": "Skydække (%)",
  "component.FieldDrawerWeatherTabContent.series.h": "Luftfugtighed (%)",

  "component.FieldDrawerTasksTabContent.hideCompletedTasks": "Skjul færdige opgaver",
  "component.FieldDrawerTasksTabContent.noTasksFound": "Ingen opgaver fundet for denne mark.",

  "component.FieldDrawerInfoTabContent.label.cropType": "Afgrøde",
  "component.FieldDrawerInfoTabContent.label.expectedSowingDate": "Forventet sådato",
  "component.FieldDrawerInfoTabContent.label.expectedHarvestDate": "Forventet høstdato",
  "component.FieldDrawerInfoTabContent.label.areaHa": "Areal (ha)",
  "component.FieldDrawerInfoTabContent.label.areaHa__imperial": "Areal (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.Marknr": "Marknummer",
  "component.FieldDrawerInfoTabContent.customProperty.Afgroede": "Afgrøde",
  "component.FieldDrawerInfoTabContent.customProperty.Markblok": "Markblok",
  "component.FieldDrawerInfoTabContent.customProperty.GBanmeldt": "GB anmeldt",
  "component.FieldDrawerInfoTabContent.customProperty.Afgkode": "Afgrødekode",
  "component.FieldDrawerInfoTabContent.customProperty.GB": "GB",
  "component.FieldDrawerInfoTabContent.customProperty.Journalnr": "Journalnummer",
  "component.FieldDrawerInfoTabContent.customProperty.IMK_areal": "IMK areal",
  "component.FieldDrawerInfoTabContent.customProperty.CVR": "CVR",
  "component.FieldDrawerInfoTabContent.customProperty.area": "Areal (m2)",
  "component.FieldDrawerInfoTabContent.customProperty.area_acres": "Areal (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.area_ha": "Areal (ha)",
  "component.FieldDrawerInfoTabContent.customProperty.area_ha__imperial": "Areal (acres)",
  "component.FieldDrawerInfoTabContent.customProperty.id": "ID",
  "component.FieldDrawerInfoTabContent.accordion.soil.heading": "Jordinfo",
  "component.FieldDrawerInfoTabContent.accordion.soil.disclaimer.heading": "Jordinfo nøjagtighed",
  "component.FieldDrawerInfoTabContent.accordion.soil.disclaimer.label": "Jordinfo er en eksperimentel funktion og kan vise unøjagtige målinger. Værdierne er estimerede gennemsnit af de øverste 30 cm jordlag.",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.soilType": "Jordtype",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.ph": "pH",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.organicCarbonContent": "Organisk kulstofindhold",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.nitrogenContent": "Kvælstofindhold",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.sand": "Sand",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.silt": "Silt",
  "component.FieldDrawerInfoTabContent.accordion.soil.label.clay": "Ler",
  "component.FieldDrawerInfoTabContent.accordion.soil.heading.ph": "pH",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.heading": "Vanding",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.button.disableAdvancedIrrigationAnalysis": "Deaktiver avanceret vandingsanalyse",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.disclaimer.heading": "Avanceret vandingsanalyse",
  "component.FieldDrawerInfoTabContent.accordion.irrigation.disclaimer.label": "Avanceret vandingsanalyse er en eksperimentel funktion og kan vise unøjagtige målinger.",
  "component.FieldDrawerInfoTabContent.buttons.delete": "Slet",

  "component.EnableIrrigationAnalysisButton.button.enableAdvancedIrrigationAnalysis": "Aktiver avanceret vandingsanalyse",
  "component.EnableIrrigationAnalysisButton.modal.heading": "Er du sikker?",
  "component.EnableIrrigationAnalysisButton.modal.description": "Bekræft venligst, at du vil aktivere avanceret vandingsanalyse.",
  "component.EnableIrrigationAnalysisButton.modal.button.cancel": "Annuller",
  "component.EnableIrrigationAnalysisButton.modal.button.confirm": "Bekræft",

  "component.FieldIrrigationDrawerContent.text.irrigationMetricsNotEnabled": "Vandingsanalyse er ikke aktiveret for denne mark.",
  "component.FieldIrrigationDrawerContent.text.noDataAvailableForTheChosenTimePeriod": "Ingen data tilgængelig for det valgte tidsrum.",
  "component.FieldIrrigationDrawerContent.chartName.waterDeficit": "Vandunderskud",
  "component.FieldIrrigationDrawerContent.chartName.evapotranspiration": "Fordampning",
  "component.FieldIrrigationDrawerContent.chartName.soilMoisture": "Jordfugtighed",
  "component.FieldIrrigationDrawerContent.series.waterDeficit": "Vandunderskud",
  "component.FieldIrrigationDrawerContent.series.evapotranspiration": "Fordampning",
  "component.FieldIrrigationDrawerContent.series.soilMoisture": "Jordfugtighed",
  "component.FieldIrrigationDrawerContent.series.saturationPoint": "Mætningspunkt",
  "component.FieldIrrigationDrawerContent.series.fieldCapacity": "Markkapacitet",
  "component.FieldIrrigationDrawerContent.series.refillPoint": "Genopfyldningspunkt",
  "component.FieldIrrigationDrawerContent.series.wiltingPoint": "Visnepunkt",
  "component.FieldIrrigationDrawerContent.select.options.day": "Dag",
  "component.FieldIrrigationDrawerContent.select.options.week": "Uge",
  "component.FieldIrrigationDrawerContent.select.options.month": "Måned",
  "component.FieldIrrigationDrawerContent.select.options.year": "År",
  "component.FieldIrrigationDrawerContent.select.options.custom": "Brugerdefineret",

  "component.Map.evapotranspiration": "Fordampning",
  "component.Map.soilMoisture": "Jordfugtighed",

  "component.FieldDrawerEditTabContent.label.fieldName": "Marknavn",
  "component.FieldDrawerEditTabContent.label.secondaryName": "Sekundært navn",
  "component.FieldDrawerEditTabContent.label.fieldGroup": "Gruppe",
  "component.FieldDrawerEditTabContent.label.cropType": "Afgrøde",
  "component.FieldDrawerEditTabContent.label.expectedSowingDate": "Forventet sådato",
  "component.FieldDrawerEditTabContent.label.expectedHarvestDate": "Forventet høstdato",
  "component.FieldDrawerEditTabContent.buttons.apply": "Anvend",
  "component.FieldDrawerEditTabContent.buttons.cancel": "Annuller",

  "component.TaskForm.formLabels.title": "Titel",
  "component.TaskForm.formLabels.task": "Opgave",
  "component.TaskForm.formLabels.crop": "Afgrøde",
  "component.TaskForm.formLabels.tillingDepth": "Bearbejdningsdybde",
  "component.TaskForm.formLabels.dropSize": "Dråbediameter",
  "component.TaskForm.formLabels.start": "Start",
  "component.TaskForm.formLabels.duration": "Varighed",
  "component.TaskForm.formLabels.fields": "Marker",
  "component.TaskForm.formLabels.users": "Brugere",
  "component.TaskForm.formLabels.machinesAndEquipment": "Maskiner og redskaber",
  "component.TaskForm.formLabels.notes": "Noter",

  "data.task.harvesting": "Høst",
  "data.task.seeding": "Såning",
  "data.task.tilling": "Jordbearbejdning",
  "data.task.spraying": "Sprøjtning",
  "data.task.spreading": "Spredning",
  "data.task.mowing": "Slåning",
  "data.task.pressing": "Presning",
  "data.task.other": "Andet",
  "data.tillingDepth.shallow": "Lavt",
  "data.tillingDepth.medium": "Medium",
  "data.tillingDepth.deep": "Dyb",
  "data.dropSize.fine": "Fin",
  "data.dropSize.medium": "Medium",
  "data.dropSize.coarse": "Grov",
  "data.crop.barley": "Byg",
  "data.crop.wheat": "Hvede",
  "data.crop.maize": "Majs",
  "data.crop.rye": "Rug",
  "data.crop.oat": "Havre",
  "data.crop.sorghum": "Sorghum",
  "data.crop.triticale": "Triticale",
  "data.crop.rapeseed": "Rapsfrø",
  "data.crop.grass_and_clover": "Græs og kløver",
  "data.crop.sunflower": "Solsikke",
  "data.crop.potato": "Kartoffel",
  "data.crop.beetroot": "Rødbeder",
  "data.crop.carrot": "Gulerod",
  "data.crop.broad_bean": "Bønne",
  "data.crop.pea": "Ærter",
  "data.crop.common_bean": "Bønne",
  "data.crop.soybean": "Sojabønne",
  "data.crop.cotton": "Bomuld",
  "data.crop.rice": "Ris",
  "data.crop.sugarcane": "Sukkerrør",

  "data.field.soilType.Sand": "Sand",
  "data.field.soilType.Loamy sand": "Leret sand",
  "data.field.soilType.Sandy loam": "Sandet lerjord",
  "data.field.soilType.Loam": "Lerjord",
  "data.field.soilType.Silty loam": "Leret siltjord",
  "data.field.soilType.Silt": "Silt",
  "data.field.soilType.Clay loam": "Leret lerjord",
  "data.field.soilType.Sandy clay loam": "Sandet lerjord",
  "data.field.soilType.Silty clay loam": "Leret siltjord",
  "data.field.soilType.Sandy clay": "Sandet ler",
  "data.field.soilType.Silty clay": "Leret silt",
  "data.field.soilType.Clay": "Ler",

  "conditions.descriptions.harvesting.temperature.high.optimal": "",
  "conditions.descriptions.harvesting.temperature.high.good": "",
  "conditions.descriptions.harvesting.temperature.high.moderate": "",
  "conditions.descriptions.harvesting.temperature.high.poor": "",
  "conditions.descriptions.harvesting.temperature.high.critical": "",
  "conditions.descriptions.harvesting.temperature.low.optimal": "",
  "conditions.descriptions.harvesting.temperature.low.good": "",
  "conditions.descriptions.harvesting.temperature.low.moderate": "",
  "conditions.descriptions.harvesting.temperature.low.poor": "",
  "conditions.descriptions.harvesting.temperature.low.critical": "",
  "conditions.descriptions.harvesting.precipitation.high.optimal": "(Minimal til ingen regn) Der er minimal til ingen hindring for traktoren og udstyret",
  "conditions.descriptions.harvesting.precipitation.high.good": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.harvesting.precipitation.high.moderate": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.harvesting.precipitation.high.poor": "(Let regn) Der er en lille hindring for traktor og udstyr",
  "conditions.descriptions.harvesting.precipitation.high.critical": "(Let regn over tærsklen) Der er hindring for traktor og udstyr samt risiko for udsmøring af jorden og tab af trækkraft",
  "conditions.descriptions.harvesting.precipitation.low.optimal": "(Minimal til ingen regn) Der er minimal til ingen hindring for traktoren og udstyret",
  "conditions.descriptions.harvesting.precipitation.low.good": "",
  "conditions.descriptions.harvesting.precipitation.low.moderate": "",
  "conditions.descriptions.harvesting.precipitation.low.poor": "",
  "conditions.descriptions.harvesting.precipitation.low.critical": "",
  "conditions.descriptions.harvesting.soil_temperature.high.optimal": "",
  "conditions.descriptions.harvesting.soil_temperature.high.good": "",
  "conditions.descriptions.harvesting.soil_temperature.high.moderate": "",
  "conditions.descriptions.harvesting.soil_temperature.high.poor": "",
  "conditions.descriptions.harvesting.soil_temperature.high.critical": "",
  "conditions.descriptions.harvesting.soil_temperature.low.optimal": "",
  "conditions.descriptions.harvesting.soil_temperature.low.good": "",
  "conditions.descriptions.harvesting.soil_temperature.low.moderate": "",
  "conditions.descriptions.harvesting.soil_temperature.low.poor": "",
  "conditions.descriptions.harvesting.soil_temperature.low.critical": "",
  "conditions.descriptions.harvesting.soil_moisture.high.optimal": "(Meget tør jord på visningspunktet) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.harvesting.soil_moisture.high.good": "(Tørrere jord) Der er lav risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.harvesting.soil_moisture.high.moderate": "(Tør jord) Der er en vis risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.harvesting.soil_moisture.high.poor": "(Fugtig jord) Der er stor risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.harvesting.soil_moisture.high.critical": "(Meget fugtig jord ved markkapacitet) Der er størst risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.harvesting.soil_moisture.low.optimal": "(Meget tør jord ved visningspunktet) Der er minimal risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.harvesting.soil_moisture.low.good": "",
  "conditions.descriptions.harvesting.soil_moisture.low.moderate": "",
  "conditions.descriptions.harvesting.soil_moisture.low.poor": "",
  "conditions.descriptions.harvesting.soil_moisture.low.critical": "",
  "conditions.descriptions.harvesting.wind.high.optimal": "(Rolig) Der er ingen risiko for korndrift og udbyttetab",
  "conditions.descriptions.harvesting.wind.high.good": "(Let brise) Der er minimal risiko for afvigelse af korn og tab af udbytte",
  "conditions.descriptions.harvesting.wind.high.moderate": "(Let brise) Der er minimal risiko for afvigelse af korn og tab af udbytte",
  "conditions.descriptions.harvesting.wind.high.poor": "(Moderat brise) Der er minimal risiko for afvigelse af korn og tab af udbytte",
  "conditions.descriptions.harvesting.wind.high.critical": "(Vind kraftig nok til at løfte en hvedekerne) Der er betydelig risiko for kornafdrift og udbyttetab",
  "conditions.descriptions.harvesting.wind.low.optimal": "",
  "conditions.descriptions.harvesting.wind.low.good": "",
  "conditions.descriptions.harvesting.wind.low.moderate": "",
  "conditions.descriptions.harvesting.wind.low.poor": "",
  "conditions.descriptions.harvesting.wind.low.critical": "",
  "conditions.descriptions.sowing.temperature.high.optimal": "(Optimale temperaturer) Plantens vækst og udvikling vil være hurtigst",
  "conditions.descriptions.sowing.temperature.high.good": "(Tæt på optimale temperaturer) Plantens vækst og udvikling vil være tilstrækkelig",
  "conditions.descriptions.sowing.temperature.high.moderate": "(Moderat høje temperaturer) Plantens vækst og udvikling vil være langsom",
  "conditions.descriptions.sowing.temperature.high.poor": "(Meget høje temperaturer) Plantens vækst og udvikling vil blive hæmmet",
  "conditions.descriptions.sowing.temperature.high.critical": "(Dødelige temperaturer for afgrøden) Frøplanterne vil visne og dø",
  "conditions.descriptions.sowing.temperature.low.optimal": "(Optimale temperaturer) Plantens vækst og udvikling vil være den hurtigste",
  "conditions.descriptions.sowing.temperature.low.good": "(Tæt på optimale temperaturer) Plantens vækst og udvikling vil være tilstrækkelig",
  "conditions.descriptions.sowing.temperature.low.moderate": "(Moderat lave temperaturer) Plantens vækst og udvikling vil være langsom",
  "conditions.descriptions.sowing.temperature.low.poor": "(Meget lave temperaturer) Plantens vækst og udvikling vil være meget langsom til nul, og der er en betydelig risiko for frostskader",
  "conditions.descriptions.sowing.temperature.low.critical": "(Dødelige temperaturer for afgrøden) Frøplanterne vil visne og dø",
  "conditions.descriptions.sowing.precipitation.high.optimal": "(Minimal til ingen regn) Der er minimal til ingen hindring for traktor og udstyr",
  "conditions.descriptions.sowing.precipitation.high.good": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.sowing.precipitation.high.moderate": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.sowing.precipitation.high.poor": "(Let regn) Der er en lille hindring for traktor og udstyr",
  "conditions.descriptions.sowing.precipitation.high.critical": "(Let regn over tærsklen) Der er hindring for traktor og udstyr samt risiko for udsmøring af jorden og tab af trækkraft",
  "conditions.descriptions.sowing.precipitation.low.optimal": "(Minimal til ingen regn) Der er minimal til ingen hindring for traktoren og udstyret",
  "conditions.descriptions.sowing.precipitation.low.good": "",
  "conditions.descriptions.sowing.precipitation.low.moderate": "",
  "conditions.descriptions.sowing.precipitation.low.poor": "",
  "conditions.descriptions.sowing.precipitation.low.critical": "",
  "conditions.descriptions.sowing.soil_temperature.high.optimal": "(Optimale temperaturer) Frøene spirer og udvikler sig hurtigst.",
  "conditions.descriptions.sowing.soil_temperature.high.good": "(Tæt på optimale temperaturer) Frøspiringen og udviklingen vil være tilstrækkelig",
  "conditions.descriptions.sowing.soil_temperature.high.moderate": "(Moderat høje temperaturer) Frøspiringen og -udviklingen vil være langsom",
  "conditions.descriptions.sowing.soil_temperature.high.poor": "(Meget høje temperaturer) Frøets spiring og udvikling vil være meget langsom til nul, og der er risiko for, at frøene dør",
  "conditions.descriptions.sowing.soil_temperature.high.critical": "(Ekstremt høje temperaturer) Der er ingen frøspiring og -udvikling, og frøene vil sandsynligvis dø",
  "conditions.descriptions.sowing.soil_temperature.low.optimal": "(Optimale temperaturer) Frøets spiring og udvikling vil være hurtigst",
  "conditions.descriptions.sowing.soil_temperature.low.good": "(Tæt på optimale temperaturer) Frøspiringen og udviklingen vil være tilstrækkelig",
  "conditions.descriptions.sowing.soil_temperature.low.moderate": "(Moderat lave temperaturer) Frøspiringen og udviklingen vil være langsom",
  "conditions.descriptions.sowing.soil_temperature.low.poor": "(Meget lave temperaturer) Frøspiringen og -udviklingen vil være meget langsom til nul",
  "conditions.descriptions.sowing.soil_temperature.low.critical": "(Ekstremt lave temperaturer) Der er ingen frøspiring og -udvikling",
  "conditions.descriptions.sowing.soil_moisture.high.optimal": "(Jord på halvdelen af kapaciteten) - Jorden er fugtig nok til optimal frøspiring og tør nok til at undgå traktorkomprimering",
  "conditions.descriptions.sowing.soil_moisture.high.good": "(Jord næsten på halvdelen af kapaciteten) - Frøspiringspotentialet er optimalt, men der er en stigende risiko for jordpakning med traktor",
  "conditions.descriptions.sowing.soil_moisture.high.moderate": "(Fugtig jord) Frøspiringspotentialet er tilstrækkeligt, men der er også en betydelig risiko for jordpakning med traktor",
  "conditions.descriptions.sowing.soil_moisture.high.poor": "(Fugtig jord meget tæt på markkapacitet) Frøspiringspotentialet er reduceret i nogle afgrøder, og der er stor risiko for jordpakning med traktor",
  "conditions.descriptions.sowing.soil_moisture.high.critical": "(Fugtig jord ved markkapacitet) Frøspiringspotentialet er reduceret i nogle afgrøder, og der er en meget stor risiko for jordpakning med traktor",
  "conditions.descriptions.sowing.soil_moisture.low.optimal": "(Jord på halvdelen af kapaciteten) - Jorden er fugtig nok til optimal frøspiring og tør nok til at undgå traktorkomprimering",
  "conditions.descriptions.sowing.soil_moisture.low.good": "(Jord næsten halvdelen af kapaciteten) - Frøspiringspotentialet er tilstrækkeligt",
  "conditions.descriptions.sowing.soil_moisture.low.moderate": "(Tør jord) - Frøspiringspotentialet er lavt",
  "conditions.descriptions.sowing.soil_moisture.low.poor": "(Meget tør jord, tæt på visningspunktet) Frøspiringspotentialet er meget lavt",
  "conditions.descriptions.sowing.soil_moisture.low.critical": "(Meget tør jord ved visningspunktet) Frøspiringspotentialet er lavest",
  "conditions.descriptions.sowing.wind.high.optimal": "(Rolig) Der er ingen risiko for frøforskydning og uoverensstemmelse ved såning",
  "conditions.descriptions.sowing.wind.high.good": "(Let brise) Der er minimal risiko for frøforskydning og uregelmæssigheder ved såning",
  "conditions.descriptions.sowing.wind.high.moderate": "(Let brise) Der er minimal risiko for frøforskydning og uregelmæssigheder ved såning",
  "conditions.descriptions.sowing.wind.high.poor": "(Moderat brise) Der er minimal risiko for frøforskydning og uregelmæssigheder ved såning",
  "conditions.descriptions.sowing.wind.high.critical": "(Vind kraftig nok til at løfte en hvedekerne) Der er en betydelig risiko for frøforskydning og uregelmæssigheder ved såning",
  "conditions.descriptions.sowing.wind.low.optimal": "(Rolig vind) Der er ingen risiko for frøforskydning og uregelmæssigheder ved såning",
  "conditions.descriptions.sowing.wind.low.good": "",
  "conditions.descriptions.sowing.wind.low.moderate": "",
  "conditions.descriptions.sowing.wind.low.poor": "",
  "conditions.descriptions.sowing.wind.low.critical": "",
  "conditions.descriptions.mowing.temperature.high.optimal": "",
  "conditions.descriptions.mowing.temperature.high.good": "",
  "conditions.descriptions.mowing.temperature.high.moderate": "",
  "conditions.descriptions.mowing.temperature.high.poor": "",
  "conditions.descriptions.mowing.temperature.high.critical": "",
  "conditions.descriptions.mowing.temperature.low.optimal": "",
  "conditions.descriptions.mowing.temperature.low.good": "",
  "conditions.descriptions.mowing.temperature.low.moderate": "",
  "conditions.descriptions.mowing.temperature.low.poor": "",
  "conditions.descriptions.mowing.temperature.low.critical": "",
  "conditions.descriptions.mowing.precipitation.high.optimal": "(lidt eller ingen regn) Der er minimal eller ingen hindring for traktoren og udstyret",
  "conditions.descriptions.mowing.precipitation.high.good": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.mowing.precipitation.high.moderate": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.mowing.precipitation.high.poor": "(Let regn) Der er en lille hindring for traktor og udstyr",
  "conditions.descriptions.mowing.precipitation.high.critical": "(Let regn over tærsklen) Der er hindring for traktor og udstyr samt risiko for udsmøring af jorden og tab af trækkraft",
  "conditions.descriptions.mowing.precipitation.low.optimal": "(lidt til ingen regn) Der er minimal til ingen hindring for traktor og udstyr",
  "conditions.descriptions.mowing.precipitation.low.good": "",
  "conditions.descriptions.mowing.precipitation.low.moderate": "",
  "conditions.descriptions.mowing.precipitation.low.poor": "",
  "conditions.descriptions.mowing.precipitation.low.critical": "",
  "conditions.descriptions.mowing.soil_temperature.high.optimal": "",
  "conditions.descriptions.mowing.soil_temperature.high.good": "",
  "conditions.descriptions.mowing.soil_temperature.high.moderate": "",
  "conditions.descriptions.mowing.soil_temperature.high.poor": "",
  "conditions.descriptions.mowing.soil_temperature.high.critical": "",
  "conditions.descriptions.mowing.soil_temperature.low.optimal": "",
  "conditions.descriptions.mowing.soil_temperature.low.good": "",
  "conditions.descriptions.mowing.soil_temperature.low.moderate": "",
  "conditions.descriptions.mowing.soil_temperature.low.poor": "",
  "conditions.descriptions.mowing.soil_temperature.low.critical": "",
  "conditions.descriptions.mowing.soil_moisture.high.optimal": "(Meget tør jord på visningspunktet) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.mowing.soil_moisture.high.good": "(Tørrere jord) Der er lav risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.mowing.soil_moisture.high.moderate": "(Tør jord) Der er en vis risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.mowing.soil_moisture.high.poor": "(Fugtig jord) Der er stor risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.mowing.soil_moisture.high.critical": "(Meget fugtig jord ved markkapacitet) Der er størst risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.mowing.soil_moisture.low.optimal": "(Meget tør jord ved visningspunktet) Der er minimal risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.mowing.soil_moisture.low.good": "",
  "conditions.descriptions.mowing.soil_moisture.low.moderate": "",
  "conditions.descriptions.mowing.soil_moisture.low.poor": "",
  "conditions.descriptions.mowing.soil_moisture.low.critical": "",
  "conditions.descriptions.mowing.wind.high.optimal": "(Rolig) Der er ingen risiko for, at halm og restprodukter driver rundt",
  "conditions.descriptions.mowing.wind.high.good": "(Let luft) Der er minimal risiko for afrivning af halm og restprodukter",
  "conditions.descriptions.mowing.wind.high.moderate": "(Let brise) Der er minimal risiko for, at halm og restprodukter flyder afsted",
  "conditions.descriptions.mowing.wind.high.poor": "(Moderat brise) -Der er en vis risiko for at halm og restprodukter driver afsted",
  "conditions.descriptions.mowing.wind.high.critical": "(Frisk brise) Bladene begynder at svinge, og der er stor risiko for, at halm og restprodukter flyder rundt.",
  "conditions.descriptions.mowing.wind.low.optimal": "(Rolig vind) Der er ingen risiko for, at halm og rester flyder rundt",
  "conditions.descriptions.mowing.wind.low.good": "",
  "conditions.descriptions.mowing.wind.low.moderate": "",
  "conditions.descriptions.mowing.wind.low.poor": "",
  "conditions.descriptions.mowing.wind.low.critical": "",
  "conditions.descriptions.spraying.fine.temperature.high.optimal": "",
  "conditions.descriptions.spraying.fine.temperature.high.good": "",
  "conditions.descriptions.spraying.fine.temperature.high.moderate": "",
  "conditions.descriptions.spraying.fine.temperature.high.poor": "",
  "conditions.descriptions.spraying.fine.temperature.high.critical": "",
  "conditions.descriptions.spraying.fine.temperature.low.optimal": "",
  "conditions.descriptions.spraying.fine.temperature.low.good": "",
  "conditions.descriptions.spraying.fine.temperature.low.moderate": "",
  "conditions.descriptions.spraying.fine.temperature.low.poor": "",
  "conditions.descriptions.spraying.fine.temperature.low.critical": "",
  "conditions.descriptions.spraying.fine.precipitation.high.optimal": "(Ingen regn) Der er ingen hindringer for traktor og udstyr og ingen risiko for afstrømning af produktet",
  "conditions.descriptions.spraying.fine.precipitation.high.good": "(Lidt regn) Der er minimal hindring for traktor og udstyr og minimal risiko for afstrømning af produktet",
  "conditions.descriptions.spraying.fine.precipitation.high.moderate": "(Lidt regn) Der er minimal hindring for traktor og udstyr og minimal risiko for afstrømning af produktet",
  "conditions.descriptions.spraying.fine.precipitation.high.poor": "(Lidt regn) Der er minimal hindring for traktor og udstyr og minimal risiko for afstrømning af produkt",
  "conditions.descriptions.spraying.fine.precipitation.high.critical": "(Let regn over tærskelværdien) Der er hindringer for traktor og udstyr med risiko for jordpakning og udtværing og risiko for afstrømning af produkt",
  "conditions.descriptions.spraying.fine.precipitation.low.optimal": "(Ingen regn) Der er ingen hindringer for traktor og udstyr og ingen risiko for produktafstrømning",
  "conditions.descriptions.spraying.fine.precipitation.low.good": "",
  "conditions.descriptions.spraying.fine.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.fine.precipitation.low.poor": "",
  "conditions.descriptions.spraying.fine.precipitation.low.critical": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.fine.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.fine.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.fine.soil_moisture.high.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spraying.fine.soil_moisture.high.good": "(Tørrere jord) Der er lav risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spraying.fine.soil_moisture.high.moderate": "(Tør jord) Der er en vis risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spraying.fine.soil_moisture.high.poor": "(Fugtig jord) Der er stor risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spraying.fine.soil_moisture.high.critical": "(Meget fugtig jord ved markkapacitet) Der er størst risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spraying.fine.soil_moisture.low.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spraying.fine.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.fine.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.fine.wind.high.optimal": "(Let luft over tærskelværdien) Der er meget lille risiko for temperaturinversion og meget lille risiko for afdrift af sprøjtegift",
  "conditions.descriptions.spraying.fine.wind.high.good": "(Let luft over tærskelværdien) -> Meget lav risiko for temperaturinversion og lav risiko for afdrift af sprøjtegift",
  "conditions.descriptions.spraying.fine.wind.high.moderate": "Gennemsnitlig - Let brise -> Der er meget lav risiko for temperaturinversion og en vis risiko for afdrift af sprøjtegift",
  "conditions.descriptions.spraying.fine.wind.high.poor": "(Let brise) Der er stor risiko for sprayafdrift",
  "conditions.descriptions.spraying.fine.wind.high.critical": "(Let brise og derover) Der er en meget høj risiko for afdrift af spray",
  "conditions.descriptions.spraying.fine.wind.low.optimal": "(Let luft over tærskelværdien) Der er meget lav risiko for temperaturinversion og meget lav risiko for afdrift af spray.",
  "conditions.descriptions.spraying.fine.wind.low.good": "(Let luft) -> Der er en vis risiko for temperaturinversion og risiko for afdrift af sprøjtegift",
  "conditions.descriptions.spraying.fine.wind.low.moderate": "(Let luft) -> Der er en vis risiko for temperaturinversion og risiko for afdrift af sprøjtegift",
  "conditions.descriptions.spraying.fine.wind.low.poor": "(Let luft) -> Der er en vis risiko for temperaturinversion og risiko for afdrift af sprøjtegift",
  "conditions.descriptions.spraying.fine.wind.low.critical": "(Rolig luft) Der er stor risiko for temperaturinversion og stor risiko for afdrift af spray",
  "conditions.descriptions.spraying.medium.temperature.high.optimal": "",
  "conditions.descriptions.spraying.medium.temperature.high.good": "",
  "conditions.descriptions.spraying.medium.temperature.high.moderate": "",
  "conditions.descriptions.spraying.medium.temperature.high.poor": "",
  "conditions.descriptions.spraying.medium.temperature.high.critical": "",
  "conditions.descriptions.spraying.medium.temperature.low.optimal": "",
  "conditions.descriptions.spraying.medium.temperature.low.good": "",
  "conditions.descriptions.spraying.medium.temperature.low.moderate": "",
  "conditions.descriptions.spraying.medium.temperature.low.poor": "",
  "conditions.descriptions.spraying.medium.temperature.low.critical": "",
  "conditions.descriptions.spraying.medium.precipitation.high.optimal": "(Ingen regn) Der er ingen hindring for traktor og udstyr og ingen risiko for afstrømning af produktet",
  "conditions.descriptions.spraying.medium.precipitation.high.good": "(Lidt regn) Der er minimal hindring for traktor og udstyr og minimal risiko for afstrømning af produktet",
  "conditions.descriptions.spraying.medium.precipitation.high.moderate": "(Lidt regn) Der er minimal hindring for traktor og udstyr og minimal risiko for afstrømning af produktet",
  "conditions.descriptions.spraying.medium.precipitation.high.poor": "(Lidt regn) Der er minimal hindring for traktor og udstyr og minimal risiko for afstrømning af produkt",
  "conditions.descriptions.spraying.medium.precipitation.high.critical": "(Let regn over tærskelværdien) Der er hindringer for traktor og udstyr med risiko for jordpakning og udtværing og risiko for afstrømning af produkt",
  "conditions.descriptions.spraying.medium.precipitation.low.optimal": "(Ingen regn) Der er ingen hindringer for traktor og udstyr og ingen risiko for produktafstrømning",
  "conditions.descriptions.spraying.medium.precipitation.low.good": "",
  "conditions.descriptions.spraying.medium.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.medium.precipitation.low.poor": "",
  "conditions.descriptions.spraying.medium.precipitation.low.critical": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.medium.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.medium.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.medium.soil_moisture.high.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spraying.medium.soil_moisture.high.good": "(Tørrere jord) Der er lav risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spraying.medium.soil_moisture.high.moderate": "(Tør jord) Der er en vis risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spraying.medium.soil_moisture.high.poor": "(Fugtig jord) Der er stor risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spraying.medium.soil_moisture.high.critical": "(Meget fugtig jord ved markkapacitet) Der er størst risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spraying.medium.soil_moisture.low.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spraying.medium.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.medium.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.medium.wind.high.optimal": "(Let luft) Der er meget lille risiko for temperaturinversion og lille risiko for afdrift af sprøjtegift",
  "conditions.descriptions.spraying.medium.wind.high.good": "(Let luft) Der er meget lav risiko for temperaturinversion og lav risiko for afvigelse fra sprøjtegift.",
  "conditions.descriptions.spraying.medium.wind.high.moderate": "(Let brise) Der er meget lille risiko for temperaturinversion og en vis risiko for afdrift af sprøjtegift",
  "conditions.descriptions.spraying.medium.wind.high.poor": "(Let brise) Der er stor risiko for afdrift af spray",
  "conditions.descriptions.spraying.medium.wind.high.critical": "(Let brise) Der er en meget høj risiko for afdrift af spray",
  "conditions.descriptions.spraying.medium.wind.low.optimal": "(Let luft) Der er meget lav risiko for temperaturinversion og lav risiko for afdrift af spray.",
  "conditions.descriptions.spraying.medium.wind.low.good": "(Rolig til let luft) Der er risiko for temperaturinversion, men lav risiko for afdrift af spray.",
  "conditions.descriptions.spraying.medium.wind.low.moderate": "",
  "conditions.descriptions.spraying.medium.wind.low.poor": "",
  "conditions.descriptions.spraying.medium.wind.low.critical": "",
  "conditions.descriptions.spraying.coarse.temperature.high.optimal": "",
  "conditions.descriptions.spraying.coarse.temperature.high.good": "",
  "conditions.descriptions.spraying.coarse.temperature.high.moderate": "",
  "conditions.descriptions.spraying.coarse.temperature.high.poor": "",
  "conditions.descriptions.spraying.coarse.temperature.high.critical": "",
  "conditions.descriptions.spraying.coarse.temperature.low.optimal": "",
  "conditions.descriptions.spraying.coarse.temperature.low.good": "",
  "conditions.descriptions.spraying.coarse.temperature.low.moderate": "",
  "conditions.descriptions.spraying.coarse.temperature.low.poor": "",
  "conditions.descriptions.spraying.coarse.temperature.low.critical": "",
  "conditions.descriptions.spraying.coarse.precipitation.high.optimal": "(Ingen regn) Der er ingen hindringer for traktor og udstyr og ingen risiko for afstrømning af produktet",
  "conditions.descriptions.spraying.coarse.precipitation.high.good": "(Lidt regn) Der er minimal hindring for traktor og udstyr og minimal risiko for afstrømning af produktet",
  "conditions.descriptions.spraying.coarse.precipitation.high.moderate": "(Lidt regn) Der er minimal hindring for traktor og udstyr og minimal risiko for afstrømning af produktet",
  "conditions.descriptions.spraying.coarse.precipitation.high.poor": "(Lidt regn) Der er minimal hindring for traktor og udstyr og minimal risiko for afstrømning af produkt",
  "conditions.descriptions.spraying.coarse.precipitation.high.critical": "(Let regn over tærskelværdien) Der er hindringer for traktor og udstyr med risiko for jordpakning og udtværing og risiko for afstrømning af produkt",
  "conditions.descriptions.spraying.coarse.precipitation.low.optimal": "(Ingen regn) Der er ingen hindringer for traktor og udstyr og ingen risiko for produktafstrømning",
  "conditions.descriptions.spraying.coarse.precipitation.low.good": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.moderate": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.poor": "",
  "conditions.descriptions.spraying.coarse.precipitation.low.critical": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.optimal": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.good": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.poor": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.high.critical": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.optimal": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.good": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.poor": "",
  "conditions.descriptions.spraying.coarse.soil_temperature.low.critical": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.good": "(Tørrere jord) Der er lav risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.moderate": "(Tør jord) Der er en vis risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.poor": "(Fugtig jord) Der er stor risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spraying.coarse.soil_moisture.high.critical": "(Meget fugtig jord ved markkapacitet) Der er størst risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.good": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.moderate": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.poor": "",
  "conditions.descriptions.spraying.coarse.soil_moisture.low.critical": "",
  "conditions.descriptions.spraying.coarse.wind.high.optimal": "(Let luft) Der er meget lille risiko for temperaturinversion og meget lille eller ingen risiko for afdrift af sprøjtegift",
  "conditions.descriptions.spraying.coarse.wind.high.good": "(Let luft) Der er meget lav risiko for temperaturinversion og lav risiko for afdrift af sprøjtegift",
  "conditions.descriptions.spraying.coarse.wind.high.moderate": "(Let brise) Der er meget lille risiko for temperaturinversion og lille risiko for afvigelse af sprøjtegift",
  "conditions.descriptions.spraying.coarse.wind.high.poor": "(Let brise) Der er en vis risiko for afdrift af spray",
  "conditions.descriptions.spraying.coarse.wind.high.critical": "(Moderat brise) Der er stor risiko for afdrift af spray",
  "conditions.descriptions.spraying.coarse.wind.low.optimal": "(Let luft) Der er meget lille risiko for temperaturinversion og meget lille eller ingen risiko for afdrift af spray.",
  "conditions.descriptions.spraying.coarse.wind.low.good": "(Rolig til let luft) Der er risiko for temperaturinversion, men meget lav risiko for afdrift af spray",
  "conditions.descriptions.spraying.coarse.wind.low.moderate": "",
  "conditions.descriptions.spraying.coarse.wind.low.poor": "",
  "conditions.descriptions.spraying.coarse.wind.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.high.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.temperature.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.optimal": "(Minimal til ingen regn) Der er minimal til ingen hindring for traktoren og udstyret",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.good": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.moderate": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.poor": "(Let regn) Der er en lille hindring for traktor og udstyr",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.high.critical": "(Let regn over tærsklen) Der er hindring for traktor og udstyr samt risiko for udsmøring af jorden og tab af trækkraft",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.optimal": "(Minimal til ingen regn) Der er minimal til ingen hindring for traktoren og udstyret",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.precipitation.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.good": "(Tørrere jord) Der er lav risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.moderate": "(Tør jord) Der er en vis risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.poor": "(Fugtig jord) Der er stor risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.high.critical": "(Meget fugtig jord ved markkapacitet) Der er størst risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.optimal": "(Rolig) Der er ingen risiko for afdrift af produktet, og udbringningseffektiviteten maksimeres",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.good": "(Let luft) Der er minimal risiko for produktdrift, og påføringseffektiviteten er høj",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.moderate": "(Let brise) Der er risiko for produktdrift, og applikationseffektiviteten kan blive reduceret",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.poor": "(Moderat brise) Der er stor risiko for produktdrift, og applikationseffektiviteten vil blive reduceret",
  "conditions.descriptions.spreading.solid_fertilizer.wind.high.critical": "(Frisk brise) Der er meget stor risiko for produktdrift, og påføringseffektiviteten vil blive stærkt reduceret",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.optimal": "(Rolig vind) Der er ingen risiko for produktdrift, og påføringseffektiviteten er maksimeret",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.good": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.moderate": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.poor": "",
  "conditions.descriptions.spreading.solid_fertilizer.wind.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.high.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.temperature.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.optimal": "(Lidt eller ingen regn) Der er minimal eller ingen hindring for traktor og udstyr og minimal risiko for udsivning af produktet",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.good": "(Let regn) Der er en lille hindring for traktor og udstyr og en lille risiko for udsivning af produktet",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.moderate": "(Let regn) Der er en lille hindring for traktor og udstyr og en lille risiko for udsivning af produktet",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.poor": "(Let regn) Der er en lille hindring for traktor og udstyr og en lille risiko for udvaskning af produkter",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.high.critical": "(Let regn over tærskelværdien) Der er hindring for traktor og udstyr, risiko for udsmøring af jorden og tab af trækkraft samt stor risiko for udvaskning af produktet",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.optimal": "(lidt til ingen regn) Der er minimal til ingen hindring for traktor og udstyr og minimal risiko for udvaskning af produkter",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.precipitation.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.good": "(Tørrere jord) Der er lav risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.moderate": "(Tør jord) Der er en vis risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.poor": "(Fugtig jord) Der er stor risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.high.critical": "(Meget fugtig jord ved markkapacitet) Der er størst risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.optimal": "(Rolig) Der er ingen risiko for afdrift af produktet, og udbringningseffektiviteten maksimeres",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.good": "(Let luft) Der er minimal risiko for produktdrift, og påføringseffektiviteten er høj",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.moderate": "(Let brise) Der er risiko for produktdrift, og applikationseffektiviteten kan blive reduceret",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.poor": "(Let brise) Der er stor risiko for produktdrift og reduceret applikationseffektivitet",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.high.critical": "(Moderat brise eller mere) Der er meget stor risiko for produktdrift, og påføringseffektiviteten vil blive stærkt reduceret",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.optimal": "(Rolig vind) Der er ingen risiko for produktdrift, og påføringseffektiviteten er maksimeret",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.good": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.moderate": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.poor": "",
  "conditions.descriptions.spreading.liquid_fertilizer.wind.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.temperature.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.optimal": "(lidt eller ingen regn) Der er minimal eller ingen hindring for traktor og udstyr og minimal risiko for udsivning af produktet",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.good": "(Let regn) Der er en lille hindring for traktor og udstyr og en lille risiko for udsivning af produktet",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.moderate": "(Let regn) Der er en lille hindring for traktor og udstyr og en lille risiko for udsivning af produktet",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.poor": "(Let regn) Der er en lille hindring for traktor og udstyr og en lille risiko for udvaskning af produkter",
  "conditions.descriptions.spreading.slurry_injection.precipitation.high.critical": "(Let regn over tærskelværdien) Der er hindring for traktor og udstyr, risiko for udsmøring af jorden og tab af trækkraft samt stor risiko for udvaskning af produktet",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.optimal": "(lidt til ingen regn) Der er minimal til ingen hindring for traktor og udstyr og minimal risiko for udvaskning af produkter",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.precipitation.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_temperature.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.good": "(Tørrere jord) Der er lav risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.moderate": "(Tør jord) Der er en vis risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.poor": "(Fugtig jord) Der er stor risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.high.critical": "(Meget fugtig jord ved markkapacitet) Der er størst risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.optimal": "(Meget tør jord) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.soil_moisture.low.critical": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.good": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.poor": "",
  "conditions.descriptions.spreading.slurry_injection.wind.high.critical": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.optimal": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.good": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.moderate": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.poor": "",
  "conditions.descriptions.spreading.slurry_injection.wind.low.critical": "",
  "conditions.descriptions.tilling.temperature.high.optimal": "",
  "conditions.descriptions.tilling.temperature.high.good": "",
  "conditions.descriptions.tilling.temperature.high.moderate": "",
  "conditions.descriptions.tilling.temperature.high.poor": "",
  "conditions.descriptions.tilling.temperature.high.critical": "",
  "conditions.descriptions.tilling.temperature.low.optimal": "",
  "conditions.descriptions.tilling.temperature.low.good": "",
  "conditions.descriptions.tilling.temperature.low.moderate": "",
  "conditions.descriptions.tilling.temperature.low.poor": "",
  "conditions.descriptions.tilling.temperature.low.critical": "",
  "conditions.descriptions.tilling.precipitation.high.optimal": "(Minimal til ingen regn) Der er minimal til ingen hindring for traktor og udstyr",
  "conditions.descriptions.tilling.precipitation.high.good": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.tilling.precipitation.high.moderate": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.tilling.precipitation.high.poor": "(Let regn) Der er en lille hindring for traktor og udstyr",
  "conditions.descriptions.tilling.precipitation.high.critical": "(Let regn over tærsklen) Der er hindring for traktor og udstyr samt risiko for udsmøring af jorden og tab af trækkraft",
  "conditions.descriptions.tilling.precipitation.low.optimal": "(Minimal til ingen regn) Der er minimal til ingen hindring for traktoren og udstyret",
  "conditions.descriptions.tilling.precipitation.low.good": "",
  "conditions.descriptions.tilling.precipitation.low.moderate": "",
  "conditions.descriptions.tilling.precipitation.low.poor": "",
  "conditions.descriptions.tilling.precipitation.low.critical": "",
  "conditions.descriptions.tilling.soil_temperature.high.optimal": "",
  "conditions.descriptions.tilling.soil_temperature.high.good": "",
  "conditions.descriptions.tilling.soil_temperature.high.moderate": "",
  "conditions.descriptions.tilling.soil_temperature.high.poor": "",
  "conditions.descriptions.tilling.soil_temperature.high.critical": "",
  "conditions.descriptions.tilling.soil_temperature.low.optimal": "",
  "conditions.descriptions.tilling.soil_temperature.low.good": "",
  "conditions.descriptions.tilling.soil_temperature.low.moderate": "",
  "conditions.descriptions.tilling.soil_temperature.low.poor": "",
  "conditions.descriptions.tilling.soil_temperature.low.critical": "",
  "conditions.descriptions.tilling.soil_moisture.high.optimal": "(Jordfugtighed på halvdelen af kapaciteten) Jordbearbejdning er sikker i de fleste jordtyper og vil resultere i en passende klumpstørrelse, reduceret brændstofforbrug og lav risiko for udtværing og komprimering af jorden",
  "conditions.descriptions.tilling.soil_moisture.high.good": "(Jordfugtighed næsten halvdelen af kapaciteten) Jordbearbejdning er let med lavt brændstofforbrug og meget lav risiko for udtværing og komprimering af jorden",
  "conditions.descriptions.tilling.soil_moisture.high.moderate": "(Fugtig jord) Jordbearbejdning er acceptabel, selv om der er en vis risiko for udtværing og komprimering af jorden",
  "conditions.descriptions.tilling.soil_moisture.high.poor": "(Fugtig jord tæt på markkapacitet) -> Jordbearbejdning vil være ineffektiv med stor risiko for udtværing og komprimering af jorden",
  "conditions.descriptions.tilling.soil_moisture.high.critical": "(Fugtig jord ved eller over markens kapacitet) Jordbearbejdning er ikke tilrådeligt, med den største risiko for udtværing og komprimering af jorden",
  "conditions.descriptions.tilling.soil_moisture.low.optimal": "(Jordfugtighed på halvdelen af kapaciteten) Jordbearbejdning er sikker i de fleste jordtyper og vil resultere i en passende klumpstørrelse, reduceret brændstofforbrug og lav risiko for udtværing og komprimering af jorden",
  "conditions.descriptions.tilling.soil_moisture.low.good": "(Jordfugtighed næsten halvdelen af kapaciteten) Jordbearbejdning er let med lavt brændstofforbrug",
  "conditions.descriptions.tilling.soil_moisture.low.moderate": "(Tør jord) Fræsning er acceptabel med et gennemsnitligt brændstofforbrug",
  "conditions.descriptions.tilling.soil_moisture.low.poor": "(Tør jord tæt på visningspunktet) Jordbearbejdning vil være hård med et højt brændstofforbrug, og der er sandsynlighed for pulverisering til fine partikler eller store klumper",
  "conditions.descriptions.tilling.soil_moisture.low.critical": "(Tør jord ved visningspunktet) Jordbearbejdning vil være meget hård med det højeste brændstofforbrug, og pulverisering til fine partikler eller store klumper er meget sandsynlig",
  "conditions.descriptions.tilling.wind.high.optimal": "(Rolig) Der er ingen risiko for vinderosion af jorden",
  "conditions.descriptions.tilling.wind.high.good": "(Let eller let brise) Der er minimal risiko for vinderosion af jorden",
  "conditions.descriptions.tilling.wind.high.moderate": "(Moderat brise) Der er minimal risiko for vinderosion af jorden",
  "conditions.descriptions.tilling.wind.high.poor": "(Stærk brise) Der er betydelig risiko for vinderosion af jordbunden",
  "conditions.descriptions.tilling.wind.high.critical": "(Over moderat vindstød) Der er stor sandsynlighed for vanderosion af jordbunden",
  "conditions.descriptions.tilling.wind.low.optimal": "(Rolig vind) Der er ingen risiko for vinderosion af jordbunden",
  "conditions.descriptions.tilling.wind.low.good": "",
  "conditions.descriptions.tilling.wind.low.moderate": "",
  "conditions.descriptions.tilling.wind.low.poor": "",
  "conditions.descriptions.tilling.wind.low.critical": "",
  "conditions.descriptions.pressing.temperature.high.optimal": "",
  "conditions.descriptions.pressing.temperature.high.good": "",
  "conditions.descriptions.pressing.temperature.high.moderate": "",
  "conditions.descriptions.pressing.temperature.high.poor": "",
  "conditions.descriptions.pressing.temperature.high.critical": "",
  "conditions.descriptions.pressing.temperature.low.optimal": "",
  "conditions.descriptions.pressing.temperature.low.good": "",
  "conditions.descriptions.pressing.temperature.low.moderate": "",
  "conditions.descriptions.pressing.temperature.low.poor": "",
  "conditions.descriptions.pressing.temperature.low.critical": "",
  "conditions.descriptions.pressing.precipitation.high.optimal": "(lidt eller ingen regn) Der er minimal eller ingen hindring for traktoren og udstyret",
  "conditions.descriptions.pressing.precipitation.high.good": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.pressing.precipitation.high.moderate": "(Let regn) Der er en lille hindring for traktoren og udstyret",
  "conditions.descriptions.pressing.precipitation.high.poor": "(Let regn) Der er en lille hindring for traktor og udstyr",
  "conditions.descriptions.pressing.precipitation.high.critical": "(Let regn over tærsklen) Der er hindring for traktor og udstyr samt risiko for udsmøring af jorden og tab af trækkraft",
  "conditions.descriptions.pressing.precipitation.low.optimal": "(lidt til ingen regn) Der er minimal til ingen hindring for traktor og udstyr",
  "conditions.descriptions.pressing.precipitation.low.good": "",
  "conditions.descriptions.pressing.precipitation.low.moderate": "",
  "conditions.descriptions.pressing.precipitation.low.poor": "",
  "conditions.descriptions.pressing.precipitation.low.critical": "",
  "conditions.descriptions.pressing.soil_temperature.high.optimal": "",
  "conditions.descriptions.pressing.soil_temperature.high.good": "",
  "conditions.descriptions.pressing.soil_temperature.high.moderate": "",
  "conditions.descriptions.pressing.soil_temperature.high.poor": "",
  "conditions.descriptions.pressing.soil_temperature.high.critical": "",
  "conditions.descriptions.pressing.soil_temperature.low.optimal": "",
  "conditions.descriptions.pressing.soil_temperature.low.good": "",
  "conditions.descriptions.pressing.soil_temperature.low.moderate": "",
  "conditions.descriptions.pressing.soil_temperature.low.poor": "",
  "conditions.descriptions.pressing.soil_temperature.low.critical": "",
  "conditions.descriptions.pressing.soil_moisture.high.optimal": "(Meget tør jord på visningspunktet) Der er minimal risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.pressing.soil_moisture.high.good": "(Tørrere jord) Der er lav risiko for jordpakning, jordskader og for at traktoren sidder fast",
  "conditions.descriptions.pressing.soil_moisture.high.moderate": "(Tør jord) Der er en vis risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.pressing.soil_moisture.high.poor": "(Fugtig jord) Der er stor risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.pressing.soil_moisture.high.critical": "(Meget fugtig jord ved markkapacitet) Der er størst risiko for jordpakning, jordskader og fastklemning af traktoren",
  "conditions.descriptions.pressing.soil_moisture.low.optimal": "(Meget tør jord ved visningspunktet) Der er minimal risiko for jordpakning, jordskader og for, at traktoren sidder fast",
  "conditions.descriptions.pressing.soil_moisture.low.good": "",
  "conditions.descriptions.pressing.soil_moisture.low.moderate": "",
  "conditions.descriptions.pressing.soil_moisture.low.poor": "",
  "conditions.descriptions.pressing.soil_moisture.low.critical": "",
  "conditions.descriptions.pressing.wind.high.optimal": "(Rolig) Der er ingen risiko for, at halm og restprodukter driver rundt",
  "conditions.descriptions.pressing.wind.high.good": "(Let luft) Der er minimal risiko for afrivning af halm og restprodukter",
  "conditions.descriptions.pressing.wind.high.moderate": "(Let brise) Der er minimal risiko for, at halm og restprodukter flyder afsted",
  "conditions.descriptions.pressing.wind.high.poor": "(Moderat brise) -Der er en vis risiko for at halm og restprodukter driver afsted",
  "conditions.descriptions.pressing.wind.high.critical": "(Frisk brise) Bladene begynder at svinge, og der er stor risiko for, at halm og restprodukter flyder rundt.",
  "conditions.descriptions.pressing.wind.low.optimal": "(Rolig vind) Der er ingen risiko for, at halm og rester flyder rundt",
  "conditions.descriptions.pressing.wind.low.good": "",
  "conditions.descriptions.pressing.wind.low.moderate": "",
  "conditions.descriptions.pressing.wind.low.poor": "",
  "conditions.descriptions.pressing.wind.low.critical": "",
};
