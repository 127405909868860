import { Button, Flex } from "@chakra-ui/react";
import { Popover, PopoverProps } from "@mantine/core";
import { useRouter } from "next/router";
import { FC, useState } from "react";

type LanguageSelectorProps = {
  popoverProps?: Partial<PopoverProps>;
};
export const LanguageSelector: FC<LanguageSelectorProps> = ({
  popoverProps,
}) => {
  const router = useRouter();
  const [languagePopoverOpened, setLanguagePopoverOpened] = useState(false);
  return (
    <Popover
      opened={languagePopoverOpened}
      onClose={() => {
        setLanguagePopoverOpened(false);
      }}
      withArrow
      {...popoverProps}
    >
      <Popover.Target>
        <Button
          variant="unstyled"
          onClick={() => {
            setLanguagePopoverOpened(!languagePopoverOpened);
          }}
        >
          {router.locale}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        {[
          {
            value: "en",
            label: "English",
          },
          {
            value: "da",
            label: "Dansk",
          },
          {
            value: "es",
            label: "Español",
          },
          {
            value: "fr",
            label: "Français",
          },
        ].map((locale) => (
          <Flex key={locale.value} direction={"column"} color="black">
            <Button
              variant="unstyled"
              onClick={() => {
                if (router.locale) {
                  router.push(router.asPath, undefined, {
                    locale: locale.value,
                  });
                }
                setLanguagePopoverOpened(false);
              }}
            >
              {locale.label}
            </Button>
          </Flex>
        ))}
      </Popover.Dropdown>
    </Popover>
  );
};
