import { IconButton } from "@chakra-ui/react";
import { ComponentPropsWithRef, FC, forwardRef } from "react";

type IconToggleProps = ComponentPropsWithRef<typeof IconButton> & {
  isHighlighted?: boolean;
};
export const IconToggle: FC<IconToggleProps> = forwardRef(
  ({ isHighlighted, "aria-label": ariaLabel, children, ...rest }, ref) => {
    return (
      <IconButton
        ref={ref}
        aria-label={ariaLabel}
        variant="unstyled"
        display="flex"
        background="white"
        boxShadow="md"
        outline={isHighlighted ? "2px solid green" : undefined}
        outlineOffset={"-2px"}
        {...rest}
      >
        {children}
      </IconButton>
    );
  }
);

IconToggle.displayName = "IconToggle";
